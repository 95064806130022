import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {Route, useHistory} from "react-router-dom";
import {isUserAuthenticated} from "../../../redux/actions/authentication";
import { useSelector } from 'react-redux';

const PrivateRoute = ({children, ...rest}) => {
  const history = useHistory();
  const demo = useSelector(state => state.demo);

  useEffect(() => {
    !demo.isDemoMode && !isUserAuthenticated() && history.push("/login");
  }, [history, demo.isDemoMode]);

  return (
    <Route
      path={rest.path}
      exact={rest.exact}
      render={() => children}
    />
  );
};

PrivateRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

export default PrivateRoute;
