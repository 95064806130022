import React from "react";
import "./styles.scss";

const Accordion = ({ title, children, open }) => {
  return (
    <details open={open} className="Accordion">
      <summary className="Accordion title-container">
        {title}
      </summary>
      <div className="Accordion content-container">
        {children}
      </div>
    </details>
  )
};

export default Accordion;
