import {
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_REQUEST_FAILED,
  ACCEPT_INVITE_REQUEST_SUCCEEDED,
  GET_MANAGER_INVITE_REQUEST,
  GET_MANAGER_INVITE_REQUEST_FAILED,
  GET_MANAGER_INVITE_REQUEST_SUCCEEDED,
  INVITE_EMPLOYEE_REQUEST,
  INVITE_EMPLOYEE_REQUEST_FAILED,
  INVITE_EMPLOYEE_REQUEST_SUCCEEDED,
  INVITE_MANAGER_REQUEST,
  INVITE_MANAGER_REQUEST_FAILED,
  INVITE_MANAGER_REQUEST_SUCCEEDED
} from "../constants/invites";

export const inviteEmployeeRequest = () => {
  return {
    type: INVITE_EMPLOYEE_REQUEST
  }
};

export const inviteEmployeeRequestSucceeded = (payload) => {
  return {
    type: INVITE_EMPLOYEE_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const inviteEmployeeRequestFailed = (json) => {
  return {
    type: INVITE_EMPLOYEE_REQUEST_FAILED,
    error: json
  }
};

export const inviteManagerRequest = () => {
  return {
    type: INVITE_MANAGER_REQUEST
  }
};

export const inviteManagerRequestSucceeded = (payload) => {
  return {
    type: INVITE_MANAGER_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const inviteManagerRequestFailed = (json) => {
  return {
    type: INVITE_MANAGER_REQUEST_FAILED,
    error: json
  }
};

export const managerInviteRequest = () => {
  return {
    type: GET_MANAGER_INVITE_REQUEST
  }
};

export const managerInviteRequestSucceeded = (payload) => {
  return {
    type: GET_MANAGER_INVITE_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const managerInviteRequestFailed = (json) => {
  return {
    type: GET_MANAGER_INVITE_REQUEST_FAILED,
    error: json
  }
};

export const acceptInviteRequest = () => {
  return {
    type: ACCEPT_INVITE_REQUEST
  }
};

export const acceptInviteRequestSucceeded = (payload) => {
  return {
    type: ACCEPT_INVITE_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const acceptInviteRequestFailed = (json) => {
  return {
    type: ACCEPT_INVITE_REQUEST_FAILED,
    error: json
  }
};
