import {
  GET_TASKS_EXECUTIONS_REQUEST,
  GET_TASKS_EXECUTIONS_REQUEST_FAILED,
  GET_TASKS_EXECUTIONS_REQUEST_SUCCEEDED,
  GET_TASKS_FUNCTIONALITY_REQUEST,
  GET_TASKS_FUNCTIONALITY_REQUEST_FAILED,
  GET_TASKS_FUNCTIONALITY_REQUEST_SUCCEEDED,
  TASKS_REQUEST,
  TASKS_REQUEST_FAILED,
  TASKS_REQUEST_SUCCEEDED,
  TASK_CREATE_REQUEST,
  TASK_CREATE_REQUEST_FAILED,
  TASK_CREATE_REQUEST_SUCCEEDED
} from "../constants/tasks";

export const tasks = (
  state = {
    isFetching: false,
    data: {},
    error: {}
  }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case TASKS_REQUEST:
    case TASK_CREATE_REQUEST:
      newState.isFetching = true;
      return newState;

    case TASKS_REQUEST_SUCCEEDED:
    case TASK_CREATE_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case TASKS_REQUEST_FAILED:
    case TASK_CREATE_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};

export const tasksExecutions = (
  state = {
    isFetching: false,
    data: {},
    error: {}
  }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_TASKS_EXECUTIONS_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_TASKS_EXECUTIONS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_TASKS_EXECUTIONS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};

export const tasksFunctionality = (
  state = {
    isFetching: false,
    data: {},
    error: {}
  }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_TASKS_FUNCTIONALITY_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_TASKS_FUNCTIONALITY_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_TASKS_FUNCTIONALITY_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
