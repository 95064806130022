import {
  ACTIVITIES_LOG_REQUEST,
  ACTIVITIES_LOG_REQUEST_FAILED,
  ACTIVITIES_LOG_REQUEST_SUCCEEDED
} from "../constants/activitiesLog";

export const activitiesLog = (
  state = {
    data: [],
    isFetching: false
  },
  action
) => {
  let newState = {...state};

  switch (action.type) {
    case ACTIVITIES_LOG_REQUEST:
      newState.isFetching = true;
      return newState;

    case ACTIVITIES_LOG_REQUEST_SUCCEEDED:
      newState.data = action.data;
      newState.isFetching = false;
      return newState;

    case ACTIVITIES_LOG_REQUEST_FAILED:
      newState.isFetching = false;
      return newState;

    default:
      return newState;
  }
}
