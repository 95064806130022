import {ENGAGEMENT_REQUEST, ENGAGEMENT_REQUEST_FAILED, ENGAGEMENT_REQUEST_SUCCEEDED} from "../constants/engagement";

export const engagementRequest = () => {
  return {
    type: ENGAGEMENT_REQUEST
  }
};

export const engagementRequestSucceeded = (json) => {
  return {
    type: ENGAGEMENT_REQUEST_SUCCEEDED,
    data: json
  }
};

export const engagementRequestFailed = (error) => {
  return {
    type: ENGAGEMENT_REQUEST_FAILED,
    data: error
  }
};
