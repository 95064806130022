import React from "react";
import "./CheckboxesContainer.scss";
import Checkbox from "../../global/form/Checkbox/Checkbox";
import Button from "../../global/form/Button/Button";
import Input from "../../global/form/Input/Input";

const CheckboxesContainer = ({
  containerLabel,
  options,
  updateState,
  optionsSelected,
  multipleSelection,
  newDepartment,
  setNewDepartment,
  createDepartment,
  off
}) => {
  const handleCheckboxClick = (selectedItem) => {
    if (multipleSelection) {
      updateState((prevState) =>
        prevState.includes(selectedItem)
          ? prevState.filter((item) => item !== selectedItem)
          : selectedItem === "Acesso completo"
          ? ["Acesso completo"]
          : [...prevState, selectedItem]
      );
    } else {
      updateState(selectedItem);
    }
  };

  return (
    <div className="CheckboxesContainer">
      <h2>{containerLabel}</h2>
      <div className="checkboxes">
        {options?.map((item, key) => {
          const multipleOptionsSelected =
            optionsSelected?.indexOf(item) >= 0 ||
            optionsSelected?.indexOf("Acesso completo") >= 0;

          const checked = multipleSelection
            ? multipleOptionsSelected
            : item === optionsSelected;

          return (
            <Checkbox
              key={key}
              value={item}
              label={item}
              updateState={handleCheckboxClick}
              checked={checked}
              className={
                optionsSelected?.indexOf("Acesso completo") >= 0 &&
                item !== "Acesso completo"
                  ? "permission-checkbox disabled"
                  : "permission-checkbox"
              }
              disabled={
                optionsSelected?.indexOf("Acesso completo") >= 0 &&
                item !== "Acesso completo"
              }
              getValue
            />
          );
        })}
      </div>
      {!off && <div className="create-unit">
        <Input
          label="Criar departamento"
          updateState={setNewDepartment}
          value={newDepartment}
          placeholder="Digite o nome departamento"
        />
        {newDepartment.length > 0 && (
          <Button onClick={createDepartment}>Salvar</Button>
        )}
      </div>}
    </div>
  );
};

export default CheckboxesContainer;
