import {
  GET_TRENDING_REQUEST,
  GET_TRENDING_REQUEST_FAILED,
  GET_TRENDING_REQUEST_SUCCEEDED
} from "../constants/trending";

export const trending = (
  state = {
    data: [],
    isFetching: false
  },
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_TRENDING_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_TRENDING_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      return newState;

    case GET_TRENDING_REQUEST_FAILED:
      newState.isFetching = false;
      return newState;

    default:
      return state;
  }
};
