import React, { useState } from "react";
import "./NewPassword.scss";
import Input from "../../global/form/Input/Input";
import Button from "../../global/form/Button/Button";
import { usePasswordField } from "../../../hooks/inputHooks";
import { useDispatch } from "react-redux";
import { onEnterPressed } from "../../../helper/eventHelper";
import { updatePasswordWithCode } from "../../../redux/actions/recoveryPassword";
import ConfirmPopUp from "../../global/ConfirmPopUp/ConfirmPopUp";
import { useHistory, useParams } from "react-router";
import Loading from "../../global/Loading/Loading";

const NewPassword = () => {
  const { code } = useParams();
  const [password, setPassword, validPassword] = usePasswordField("");
  const [
    passwordConfirmation,
    setPasswordConfirmation,
    validPasswordConfirmation
  ] = usePasswordField("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordEqualError, setPasswordEqualError] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validLink, setValidLink] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    setPasswordError(false);
    setPasswordEqualError(false);

    if (passwordConfirmation !== password) {
      setPasswordEqualError(true);
      return;
    }
    
    if (
        !validPassword || 
        !validPasswordConfirmation
      ) {
        setPasswordError(true);
        return;
    }

    setIsLoading(true);
    dispatch(updatePasswordWithCode(code, { password }))
      .then(() => {
        setOpenPopUp(true);
        setIsLoading(false);
        localStorage.clear();
      })
      .catch(() => {
        setValidLink(false);
        setIsLoading(false);
      });
  };

  return (
    <section className="NewPassword">
      <h2>Alterar senha</h2>
      <form>
        {!validLink && <p>* o link de troca de senha está expirado</p>}
        {passwordError && <p>* Sua senha deve conter 6 caracteres dentre eles letras, números e um caractere especial, além de estarem iguais!</p>}
        <Input
          displayError={passwordError}
          onChange={setPassword}
          label="Nova senha"
          type="password"
          updateState={setPassword}
          value={password}
          valid={validPassword}
          onKeyUp={event => {
            onEnterPressed(event, handleSubmit)}
          }
          displayErrorMessage="Senha inválida"
        />
        <Input
          displayError={passwordEqualError}
          onChange={setPasswordConfirmation}
          label="Confirmação de senha"
          type="password"
          updateState={setPasswordConfirmation}
          value={passwordConfirmation}
          valid={!passwordEqualError}
          onKeyUp={event => {
            onEnterPressed(event, handleSubmit)
          }}
          displayErrorMessage="Senha e confirmação diferentes"
        />
      </form>
      {validLink ? (
        <Button bottom onClick={handleSubmit}>
          alterar senha
        </Button>
      ) : (
        <Button bottom onClick={() => history.push("/recuperar-senha")}>
          reenviar link
        </Button>
      )}
      <Loading visible={isLoading} />
      <ConfirmPopUp
        message="Nova senha cadastrada com sucesso"
        active={openPopUp}
        buttonAction={() => {
          history.push("/login");
        }}
        buttonText="OK"
      />
    </section>
  );
};

export default NewPassword;
