import React from 'react';
import "./ModalContributionTableRow.scss";
import TopicSymbol from "../../../global/TopicSymbol/TopicSymbol";
import {
  translateAcquistionMethodEnum,
  translateContentFormatEnum,
  translateContentTypeEnum,
  translateExecutionFormatEnum,
  translateStatusEnum
} from "../../../../helper/tasksEnum";
import {localizeDate, shortYearDateFormat} from "../../../../helper/dateHelper";

const ModalContributionsTableRow = ({execution}) => {
  return (
    <>
      <tr>
        <td>
          <TopicSymbol symbol={execution?.studyTask?.topic?.symbol} />
        </td>
        <td>
          <p>{execution?.studyTask?.title}</p>
        </td>
        <td>{translateContentTypeEnum(execution?.studyTask?.contentType)}</td>
        <td>
          {translateContentFormatEnum(execution?.studyTask?.contentFormat)}
        </td>
        <td>
          {translateExecutionFormatEnum(execution?.studyTask?.taskFormat)}
        </td>
        <td>
          {translateAcquistionMethodEnum(
            execution?.studyTask?.contentAcquisition
          )}
        </td>
        <td>
          <p>{translateStatusEnum(execution?.status)}</p>
        </td>
        <td>
          {execution?.lastDate
            ? shortYearDateFormat(localizeDate(execution?.lastDate), "/")
            : `${execution?.timeItTook} ${
                execution?.timeItTook > 1 ? "horas" : "hora"
              }`}
        </td>
      </tr>
    </>
  );
};

export default ModalContributionsTableRow;
