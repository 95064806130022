import React from 'react';
import "./Tag.scss";

const Tag = ({name, onClick}) => {
  return (
    <span className="Tag" onClick={() => onClick(name)} role="button">
      {name}
    </span>
  );
};

export default Tag;
