import React from 'react'
import './TermsOfUse.scss'

const TermsOfUse = () => {

  return (
    <div className='TermsOfUse'>

      <h1>Termos e Condições de Uso da Plataforma E2W</h1>

      <p>Obrigado por dedicar este tempo para ler os Termos e Condições de Uso da E2W. Estas informações são essenciais para tirar o melhor proveito da nossa plataforma. Estes termos aplicam-se ao uso dos serviços de gestão do capital intelectual oferecidos pela plataforma/site (“E2W”), entre pessoas que utilizem a rede E2W, sejam eles "usuários" ou "gestores".</p>

      <p>Ao usar nossos Serviços, você concorda com todos esses termos. O uso de nossos serviços também está sujeito à nossa Política de Cookies e à nossa Política de Privacidade e Processamento de Dados, que cobre como coletamos, usamos, compartilhamos e armazenamos suas informações pessoais.</p>

      <p><strong>AO CONCORDAR COM ESTES TERMOS E INICIAR O USO DA PLATAFORMA E2W, VOCÊ SE COMPROMETE A RESPEITAR AS CONDIÇÕES EXPOSTAS DE FORMA IRRETRATÁVEL E IRREVOGÁVEL, BEM COMO A UTILIZAR DE MODO PROVEITOSO E LEGAL OS SERVIÇOS A ELE OFERECIDOS PELA PLATAFORMA DA E2W.</strong></p>

      <h2>A) Termos Gerais</h2>

      <p>É muito importante que conheça as nossas políticas e, por esta razão, é necessário que leia e compreenda os Termos Gerais e condições gerais de uso do site, bem como a leitura das cláusulas que viabilizam a contratação e as limitativas de direitos, aqui destacadas:</p>

      <ol>
        <li>
          <h3>Objeto</h3>
          <ol>
            <li>Os serviços objeto dos presentes Termos Gerais consistem em:
              <ul>
                <li>Oferecer uma ferramenta que possibilite ao usuário centralizar os seus conhecimentos, sobretudo de orientação profissional, incluindo formulários eletrônicos, bancos de dados, painéis de gestão e ferramentas de comunicação e colaboração.</li>
                <li>Viabilizar a gestão do capital intelectual de uma organização, por meio da criação de dashboards gerenciais que permitam aos gestores elaborarem uma análise comparada com outros usuários, com diferentes níveis de agregação, permitindo que tenha evidências que orientem nas políticas de contratação, promoção e treinamentos.</li>
              </ul>
            </li>
            <li>O cadastro básico na plataforma E2W é gratuito e inclui a criação de página com informações pessoais no site da E2W, a possibilidade de realização de testes para fins de mapeamento de perfil e recomendações de melhorias no capital intelectual.</li>
            <li>A E2W se reserva o direito de instituir, modificar, aumentar ou eliminar tarifas a qualquer momento, sempre notificando previamente os usuários, os quais decidirão contratar ou não os serviços pelo preço cobrado ou atualizado.</li>
            <li>Usuários podem ter o cadastro com ou sem vinculação a uma organização parceira. A E2W disponibiliza informações gerenciais de perfis de usuário vinculados a organizações parceiras e seus grupos empresariais de maneira a orientar a tomada de decisão por seus gestores.</li>
          </ol>
        </li>
        <li>
          <h3>Cadastro</h3>
          <ol>
            <li>Os serviços da E2W estão disponíveis para as pessoas físicas ou jurídicas que tenham capacidade legal para contratá-los.</li>
            <li>É proibido o cadastro de usuários que não tenham capacidade civil (com relação a pessoas físicas) ou não sejam representantes legais (com relação a pessoas jurídicas), bem como de usuários que tenham sido suspensos da E2W, temporária ou definitivamente, sem prejuízo da aplicação das sanções legais previstas no Código Civil Brasileiro, notadamente, art. 166, I; 171, I e 180.</li>
            <li>Para cadastrar-se, o usuário obriga-se a informar todos os dados identificados como obrigatórios, responsabilizando-se, com exclusividade, pela exatidão, precisão e veracidade das informações prestadas e pela utilização do serviço em conformidade com estes Termos de Uso.</li>
            <li>A E2W se reserva ao direito, mas não tem a obrigação de checar a veracidade de dados cadastrais fornecidos pelos usuários, podendo, para tanto, exigir-lhes a apresentação de documentos ou a prestação de esclarecimentos. Caso comprovada a inexatidão das informações fornecidas ou se o usuário se recusar a cooperar, a E2W poderá excluir a conta ou suspender seu uso, a seu exclusivo critério e independentemente de prévio aviso ao usuário, sem que caiba ao sancionado qualquer tipo de indenização ou ressarcimento.</li>
            <li>O acesso à conta de usuário no E2W será feito por meio do cadastramento de email e senha. Recomenda-se ao usuário que utilize email e senha de seu conhecimento exclusivo e não os revele a terceiros. O usuário responsabiliza-se exclusiva e integralmente, nos âmbitos cível, criminal e administrativo, pelo uso da conta, devendo suportar, inclusive, os ônus decorrentes de sua eventual utilização indevida por terceiros, autorizados ou não.</li>
            <li>Não se permitirá:
              <ul>
                <li>A criação de novo cadastro por pessoa impedida de utilizar a E2W por violação à legislação vigente ou aos presentes Termos de Uso, a critério exclusivo de seus administradores;</li>
                <li>A utilização de nome de usuário e/ou email idêntico, parecido ou semelhante ao nome "E2W", ou de nome de usuário que, de qualquer forma, a critério de seus administradores, sugira que as atividades relativas à conta, ao invés de realizadas pelo usuário, foram praticadas pela equipe de administradores da E2W;</li>
                <li>A utilização de nome de usuário que contenha palavra considerada ofensiva, discriminatória e/ou de baixo calão, ou, ainda, referência a algum produto, serviço ou dado de uso exclusivo de terceiro;</li>
                <li>A manutenção de mais de um cadastro pela mesma pessoa;</li>
                <li>A cessão, a venda, o aluguel ou a transferência a qualquer título da conta do usuário, bem como das qualificações a ela inerentes; e</li>
                <li>O cadastramento com dados falsos ou pertencentes a terceiros.</li>
              </ul>
            </li>
            <li>É possível que eventualmente o usuário também poderá acessar sua conta por meio de redes sociais que tenham conexão prévia estabelecida com a E2W, a exemplo de Facebook, LinkedIn e Google. Nesse caso, os dados informados naqueles sites poderão estar sujeitos aos termos de uso e privacidade das referidas plataformas.</li>
            <li>Por determinação judicial, ou caso se comprove que o cadastro do usuário viola a legislação vigente ou qualquer das disposições destes Termos de Uso, a E2W poderá recusar o registro do cadastro ou cancelar o uso de cadastro já registrado.</li>
          </ol>
        </li>
        <h2>3. Direitos e Responsabilidades do Usuário</h2>

        <h3>3.1 No uso da E2W, o usuário pode:</h3>
        <ol>
          <li>(i) criar um perfil público para disponibilizar informações acerca do seu capital intelectual;</li>
          <li>(ii) compartilhar nas redes sociais todo o conteúdo disponível dentro da plataforma (site e aplicativo) que esteja disponível para tal ação;</li>
          <li>(iii) alterar, retificar ou excluir seus dados, na forma da Política de Privacidade disponibilizada no momento.</li>
        </ol>

        <h3>3.2 O usuário não pode:</h3>
        <ol>
          <li>(i) criar perfis em nome de terceiros;</li>
          <li>(ii) violar/tentar violar a segurança da E2W como tentativa de investigar, copiar e testar a vulnerabilidade do sistema, rede, falha de segurança ou medidas de autenticação sem autorização;</li>
          <li>(iii) forjar cabeçalhos TCP/IP ou qualquer parte da informação do cabeçalho em qualquer e-mail ou postagens em grupos de notícias;</li>
          <li>(iv) agregar, copiar ou duplicar partes da plataforma E2W (site e aplicativo);</li>
          <li>(v) adaptar ou disponibilizar o link para qualquer conteúdo E2W ou informações disponíveis no Site ou no Aplicativo, salvo se permitido por este Termo;</li>
          <li>(vi) postar conteúdo ou materiais que promovam ou endossam informações falsas/enganosas ou atividades ilegais, bem como informações que ofereçam instruções para atividades ilegais ou proibidas de acordo com o presente Termo;</li>
          <li>(vii) acessar dados de terceiros, servidores ou contas que não é autorizado a acessar;</li>
          <li>(viii) assediar, incitar ou defender quaisquer tipos de assédio de qualquer grupo, empresa ou indivíduo;</li>
          <li>(ix) tentar interferir nos serviços para usuários, administrador ou rede, incluindo, mas não se limitando a métodos de envio de vírus para o site, sobrecarga, acúmulo de mensagens sem conteúdo, spam, “e-mails bomba” ou panes;</li>
          <li>(x) utilizar a Plataforma E2W de forma ilícita para qualquer atividade ilegal, incluir ou submeter qualquer tipo de conteúdo difamatório, acusatório, implicitamente ou explicitamente ofensivo, vulgar, obsceno, ameaçador, abusivo, raivoso, racista, discriminatório, ou que pode causar irritação, inconveniência, humilhação, ansiedade ou ser considerada como assédio. Também não são permitidos links para sites de conteúdo pornográfico, indecentes ou com material de sexo explícito de qualquer tipo; como determinado a critério da E2W.</li>
        </ol>

        <h2>4. Direitos e Responsabilidades do Gestor</h2>

        <h3>4.1 No uso da E2W, o gestor pode:</h3>
        <ol>
          <li>(i) acessar informações a respeito dos usuários cadastrados que estejam vinculadas à organização que representa;</li>
          <li>(ii) acessar informações gerenciais sob o formato de dashboards e relatórios a respeito do conjunto de usuário cadastrados que estejam vinculados à organização que representa;</li>
        </ol>

        <h3>4.2 O gestor não pode:</h3>
        <ol>
          <li>(i) modificar informações pessoais de qualquer usuário cadastrado;</li>
          <li>(ii) acessar informações individuais ou agregadas de usuários que não estejam vinculados à organização que representa;</li>
          <li>(iii) Aos gestores aplicam-se as mesmas proibições elencadas no item 3.2 destes Termos.</li>
        </ol>

        <h2>5. Responsabilidades da E2W</h2>

        <h3>5.1 A E2W reserva-se o direito de, a qualquer momento e, sem aviso prévio, remover ou desativar temporária, ou permanentemente, o acesso a conteúdos, se a E2W, segundo o seu critério exclusivo, considerar que os conteúdos violam ou possivelmente violarem os presentes Termos, de terceiros (incluindo os direitos de propriedade Intelectual), das leis ou dos regulamentos aplicáveis, ou se, de outro modo, forem lesivos para os Serviços, os utilizadores da E2W, ou terceiros.</h3>

        <h3>5.2 Violações à segurança do sistema e da rede poderão resultar em processos civis e/ou criminais. A E2W investigará ocorrências que possam resultar em violações, incluindo, mas não se limitando às citadas acima, e poderá se envolver e cooperar com as autoridades e órgãos de segurança pública na punição de usuários que pratiquem atos de violação.</h3>

        <h3>5.3 A E2W atua como veiculadora de informações advindas dos seus usuários e, portanto, não é prestadora de serviços de consultoria ou ainda intermediária ou participante em nenhum negócio entre os usuários. Dessa forma, a plataforma não assume responsabilidade, direta ou subsidiária, por nenhuma consequência que possa advir de qualquer relação entre os usuários e gestores. Assim, a plataforma não é responsável por qualquer ação ou omissão do usuário baseada nas informações veiculadas no site. Adicionalmente, a E2W emprega seus melhores esforços para manter o site sempre atualizado, preciso e completo, mas não se responsabiliza por imprecisão, erro, fraude, inexatidão ou divergência nos dados e informações aqui contidas.</h3>

        <h3>5.4 A E2W disponibiliza informações gerenciais de perfis de usuário vinculados a organizações parceiras de maneira a orientar a tomada de decisão por seus gestores. A E2W não oferece serviços de consultoria, nem é responsável pelas decisões tomadas, que são de exclusiva responsabilidade das referidas organizações. O objetivo da plataforma é fornecer informações que orientem a tomada de decisões com base em evidência, não sendo responsável pelo mau uso das informações, seja em virtude de inexatidões em razão de má-fé dos usuários ou uso indevido por parte dos gestores.</h3>

        <h3>5.5 A E2W poderá tomar qualquer ação que julgar necessária ou apropriada a respeito de Conteúdo de usuário caso acredite que tal conteúdo possa criar responsabilidades para a E2W, difamar sua marca ou imagem pública ou causar perdas de usuários, ou ainda de seus provedores de Internet ou de outros fornecedores (em partes ou por completo).</h3>

        <h3>5.6 A E2W não tem nenhuma participação no processo seletivo, qualificação, promoção e escolha de qualquer tipo que organizações parceiras façam com base nas informações disponibilizadas, que são de responsabilidade exclusiva da referida organização.</h3>

        <h3>5.7 A E2W não será responsável pelo efetivo cumprimento das tarefas e atribuições assumidas pelos usuários numa eventual promoção. O usuário reconhece e aceita ao realizar negociações com gestores e a organização na qual desempenha atividade profissional que o faz por sua conta e risco, reconhecendo a E2W como mero fornecedor de serviços de disponibilização de informações e inteligência de negócios para orientação na tomada de decisões para gestão de recursos humanos.</h3>

        <h2>6. Sanções</h2>

        <h3>6.1 Sem prejuízo de outras medidas cabíveis, a E2W poderá advertir, suspender, temporária ou definitivamente, a conta de um usuário, a qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a prestação de seus serviços se:</h3>
        <ol>
          <li>(i) o usuário não cumprir qualquer dispositivo destes Termos e condições gerais de uso e demais políticas da E2W;</li>
          <li>(ii) se descumprir com seus deveres de Usuário;</li>
          <li>(iii) se praticar atos fraudulentos ou dolosos;</li>
          <li>(iv) se não puder ser verificada a identidade do Usuário ou se qualquer informação fornecida por ele estiver incorreta;</li>
        </ol>
      </ol>
      <h2>7. Alcance dos Serviços</h2>
      <p>7.1 Estes Termos e condições gerais de uso não geram nenhum contrato de sociedade, mandato,
        franquia ou relação de trabalho entre a E2W e o usuário. O usuário manifesta ciência de que a E2W não
        é parte de nenhuma negociação realizada entre usuários, nem possui controle algum sobre a
        existência, qualidade, segurança, legalidade, veracidade ou exatidão das informações veiculadas pelos
        usuários e sobre a capacidade dos usuários para realizar o trabalho com base nas habilidades
        anunciadas.</p>

      <p>7.2 A E2W não garante a veracidade da publicação de terceiros que apareça em seu site e não será
        responsável pela correspondência ou contratos que o Usuário realize com terceiros.</p>

      <h2>8. Propriedade Intelectual</h2>
      <p>8.1 O uso comercial da expressão "E2W" como marca, nome empresarial ou nome de domínio, bem
        como os conteúdos das telas relativas aos serviços da E2W assim como os programas, look and feel do
        sites, bancos de dados, redes, arquivos que permitem ao Usuário acessar e usar sua Conta são
        propriedade da E2W e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas,
        patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos
        conteúdos são proibidos, salvo a autorização prévia e expressa por escrito do E2W.</p>

      <p>8.2 Os sites podem manter links com outros sites da rede, o que não significa que esses sites sejam de
        propriedade ou operados pela E2W. Não possuindo controle sobre esses sites, a E2W não será
        responsável pelos seus conteúdos, práticas e serviços ofertados. A presença de links para outros sites
        não implica relação de sociedade, de supervisão, de cumplicidade ou solidariedade da E2W para com
        esses sites e seus conteúdos.</p>

      <h2>9. Modificação dos Termos</h2>
      <p>9.1 A E2W poderá alterar, a qualquer tempo, estes termos e condições gerais, visando seu
        aprimoramento e melhoria dos serviços prestados. Os novos termos e condições gerais entrarão em
        vigor imediatamente após sua publicação no site, ou em prazo estipulado na própria revisão.</p>

      <h2>10. Foro</h2>
      <p>10.1 Os presentes Termos de Uso serão regidos e interpretados de acordo com as leis da República
        Federativa do Brasil.</p>

      <p>10.2 As demandas decorrentes da aplicação ou da interpretação das cláusulas consignadas nestes
        Termos de Uso, com relação às quais as partes não tenham logrado êxito em encontrar solução
        amigável, serão submetidas ao Poder Judiciário no Foro da Comarca da Capital do Estado de São Paulo,
        com renúncia a qualquer outro, por mais privilegiado que seja, à exceção das ações ajuizadas por quem,
        nos termos do art. 2º da Lei Federal 8.078/1990, se qualifique como consumidor, as quais poderão ser
        propostas no domicílio do demandante.</p>

      <h2>B) Política de Privacidade e Processamento de Dados</h2>
      <p>Como elemento chave para as operações diárias, para a constante atualização do
        software e manutenção da sua competitividade, a coleta de dados pessoais dos
        clientes é necessária. A privacidade de suas informações é de grande importância
        para nós, e temos como política respeitar a confidencialidade da informação e a
        privacidade das pessoas. Esta Política estipula como ocorrem as operações de
        tratamento   de   dados   pessoais   da   E2W,   em   conformidade   com     as   leis   e
        regulamentações vigentes.</p>

      <h3>1. Dados Coletados</h3>

      <h4>1.1 A E2W utiliza diferentes formas para coletar dados pessoais, tais como:</h4>
      <p>1.1.1 Cadastro Básico:
        (i) Nome completo, E-mail,Data de Nascimento;
        (ii) Nacionalidade, País de Origem, Estado;
        (iii) Identidade de Gênero;
        (iv) Existência de Deficiência;
        (v) Faixa Etária;
        (vi) Orientação Sexual;
        (vii) Grau de Escolaridade;
        (viii) Nome da Profissão e Instituição onde trabalha;
        (ix) Endereço Comercial: CEP, Logradouro, Número, Complemento, Bairro, Estado e
        Cidade;
        (x) Em situações específicas, pode ser necessário o envio de foto de rosto junto a
        um  documento de identificação para seguir com o processo de cadastramento.</p>

      <p>1.1.2 Perfil e Capital Intelectual:
        (i) Detalhamento sobre a escolaridade;
        (ii) Informações generalizadas acerca de habilidades profissionais (hard skills e soft
        skills);
        (iii) Realização de cursos, palestras, leitura de livros acerca dos conhecimentos
        utilizados para preenchimento do formulário de cadastro.</p>

      <p>1.2 Para fins da legislação de proteção de dados, a empresa que detém a
        propriedade da E2W é a controladora final dos dados.</p>

      <h3>2. Utilização dos Dados</h3>

      <p>2.1 Podemos utilizar a sua informação pessoal para as seguintes finalidades:</p>

      <p>2.1.1  Para criação da conta na plataforma. Necessitamos verificar a sua
        identidade de forma a validá-lo como cliente, e isso pode  incluir verificações de
        identidade por entidades terceiras. O uso da sua  informação pessoal é necessário
        para identificarmos quem é você, já que temos a obrigação legal de estar em
        concordância com as obrigações regulamentares.</p>

      <p>2.1.2  Para execução dos serviços contratados. Após abertura de conta em
        nossa plataforma, será necessário utilizar os seus dados, sobretudo os referentes
        ao capital intelectual,  para desempenhar os nossos serviços e cumprir com as
        nossas obrigações.  Utilizaremos as informações para executar o processamento de
        informações de maneira a gerar relatórios gerenciais para organizações parceiras e
        para alimentar a inteligência artificial que recomenda novas habilidades a serem
        buscadas.</p>

      <p>2.1.3 Para cumprimento de uma obrigação legal. Existe uma variedade de
        obrigações legais provenientes das legislações aplicáveis às quais estamos sujeitos
        (ex.   Lei   Geral   de   Proteção   de   Dados).    Também   existem   várias   autoridades
        supervisoras a cujas leis e regulamentações  estamos sujeitos. Tais obrigações e
        normas obrigam-nos a executar atividades de processamento de dados pessoais
        para verificação de  identidade, cumprimento de ordens judiciais, leis tributárias e
        outras obrigações.</p>

      <p>2.1.4  Para   envio   de   comunicados   e   pesquisas   de   satisfação.
        Ocasionalmente, podemos enviar-lhe comunicados transacionais, tanto para fins de
        continuidade do processo de abertura de conta e processo cadastral que já tinha
        sido  iniciado por você, quanto para fins de operações diárias, além de pesquisas de
        satisfação  como parte do nosso processo de feedback do cliente. É do nosso
        legítimo   interesse    obter   tais   opiniões   para   assegurar   que   os   nossos
        serviços/produtos estão sendo  prestados no mais alto nível.</p>

      <p>2.1.5  Para análise de comportamento em nossos Sites e Aplicativos. Os
        nossos Sites e Aplicativos podem conter ferramentas de análise de dados que nos
        permitam analisar comportamentos para garantir a qualidade das plataformas e
        proporcionar a melhor experiência ao usuário. As informações coletadas não são
        consideradas como pessoais de acordo com os termos da Lei Geral de Proteção de
        Dados, pois são utilizadas de forma a não se vincular a qualquer usuário, sendo
        estas  informações anonimizadas.</p>

      <p>2.1.6 Para publicidade. Podemos utilizar os seus dados pessoais com o objetivo
        de garantir que você fique  sempre bem informado para realizar seus investimentos
        intelectuais com segurança. Desta forma,  a E2W compartilha conteúdos relevantes
        com os titulares de dados que optam por  receber informações sobre produtos e
        serviços oferecidos, durante o processo de    abertura de conta. Os envios das
        comunicações   são   realizados   por   E-mail,   SMS,    WhatsApp   ou   por   outro   canal
        previamente acordado e com base no seu consentimento.</p>

      <p>2.1.7  Para criação de dashboards gerenciais que auxiliem a tomada de
        decisões de gestores.  No   caso   de   usuários   que   estejam   vinculados   a   uma
        organização que faça uso dos serviços corporativos da plataforma, as informações
        dos seus funcionários serão agregadas de maneira a orientar tomadas de decisões
        referentes à gestão dos recursos humanos, tais como contratação e treinamentos.
        Importante salientar que a decisão é de exclusiva responsabilidade da organização
        contratante do serviço, não sendo a E2W ou sua empresa controladora responsável
        pelos resultados. A plataforma apenas agrega as informações com trazer critérios
        objetivos na tomada de decisão.</p>

      <p>2.1.8 Para salvaguarda dos legítimos interesses. Processamos dados pessoais
        para salvaguardar nossos legítimos interesses. Alguns exemplos de tais atividades
        incluem:</p>

      <p>(i) Dar início a reivindicações legais e preparação da nossa defesa em casos de
        litígio;</p>
      <p>(ii) Realizar análise de movimentações e tendências para elaboração de relatórios
        estratégicos;</p>
      <p>(iii)  Medidas de gestão de negócio, otimização de desempenho da plataforma, e
        desenvolvimento de novos produtos e serviços;</p>
      <h3>3. Compartilhamento dos Dados</h3>
      <h4>3.1</h4>
      <p>Observados os critérios definidos nesta Política, a E2W não divulgará dados pessoais dos seus clientes a uma terceira parte, exceto:</p>
      <ol>
        <li>Para atendimento à medida necessária dentro dos termos das leis, regras ou regulações aplicáveis;</li>
        <li>Perante a existência de obrigação de divulgação;</li>
        <li>Para fornecimento de informações gerenciais a entidades contratantes e seu grupo empresarial, desde que haja vinculação profissional do usuário com a referida entidade e seu consentimento;</li>
        <li>Por legítimo interesse que exija a divulgação;</li>
        <li>A pedido do cliente, mediante o seu consentimento.</li>
      </ol>
      <h4>3.2</h4>
      <p>As entidades terceiras para as quais são compartilhadas informações pessoais de forma a desempenhar os serviços contratados, devem executar o processamento de forma a garantir o cumprimento com as obrigações legais. Ainda assim, exigimos que as organizações que manuseiam ou obtêm esses dados pessoais, reconheçam a criticidade desta informação, assumam respeitar todos os direitos individuais de privacidade e cumpram com todas as leis relevantes de proteção de dados.</p>
      <h4>3.3</h4>
      <p>Como parte do uso dos seus dados para os fins acima mencionados, podemos eventualmente divulgar a sua informação pessoal a:</p>
      <ol>
        <li>Demais empresas do grupo empresarial que detém a E2W, nos limites das finalidades estabelecidas nesta política;</li>
        <li>Entidades terceiras tais como prestadores de serviços comerciais e consultores especializados contratados para serviços administrativos, financeiros, legais, segurança, pesquisa e outros serviços;</li>
        <li>Entidades terceiras que estejam realizando trabalhos de Auditoria;</li>
        <li>Partes empresariais, fornecedores de crédito, tribunais, órgãos controladores e autoridades reguladoras;</li>
        <li>Qualquer pessoa ou empresa desde que tenhamos o seu consentimento;</li>
        <li>Os clientes aceitam e consentem que podemos ocasionalmente analisar os dados recolhidos quando visita os nossos Sites e Aplicativos ou por outros meios, como questionários, para fins estatísticos de forma a melhorar e aprimorar os nossos serviços.</li>
      </ol>

      <h3>4. Dados Sensíveis</h3>
      <h4>4.1 Definição e Importância:</h4>
      <p>Conforme a LGPD (Art. 5º, II), dados sensíveis abrangem informações que denotam origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organizações de caráter religioso, filosófico ou político, dados sobre saúde ou vida sexual, além de dados genéticos ou biométricos atrelados a um indivíduo.</p>
      <h4>4.2 Coleta, Uso e Compartilhamento:</h4>
      <ol>
        <li>A Mastertech coleta e processa dados sensíveis para fomentar a diversidade nas empresas parceiras através do programa E2W.</li>
        <li>Ao participar do programa E2W, o usuário fornece, neste momento, o seu consentimento explícito e específico para que a Mastertech colete, processe e compartilhe tais dados com empresas parceiras que buscam oportunidades de emprego para grupos diversos.</li>
      </ol>
      <h4>4.3 Consentimento Explícito:</h4>
      <ol>
        <li>O titular dos dados está ciente de que a Mastertech coletará dados pessoais sensíveis e conhece as razões para essa coleta, bem como a maneira pela qual os dados serão usados e compartilhados com empresas parceiras.</li>
        <li>Exceção feita ao nome, todos os demais dados são opcionais. O titular pode escolher fornecer informações sobre certos dados sensíveis e manter outros em anonimato.</li>
      </ol>
      <h4>4.4 Retirada de Consentimento e Direitos do Titular:</h4>
      <ol>
        <li>O titular pode optar por recusar contatos de potenciais recrutadores na plataforma a qualquer momento.</li>
        <li>Em caso de desejarem alterar ou retirar o consentimento relacionado à presença na plataforma ou a dados específicos, os titulares devem entrar em contato diretamente com o suporte da Mastertech. As informações para esse contato estarão disponíveis no FAQ.</li>
        <li>Os titulares têm o direito, conforme a LGPD, de solicitar acesso, correção ou exclusão de seus dados sensíveis. Para exercer esse direito, é necessário entrar em contato com o suporte da Mastertech.</li>
        <li>Caso os dados sensíveis não sejam mais requeridos para os propósitos originários de sua coleta ou o titular revogue seu consentimento, a Mastertech se compromete a excluí-los de maneira segura e em total conformidade com as diretrizes legais.</li>
      </ol>

      <h3>5. Direitos do Usuário</h3>
      <h4>5.1</h4>
      <p>Se desejar realizar a consulta dos seus dados pessoais que são tratados em nosso ambiente, entre em contato e realize a solicitação. Após o processamento das informações, seu relatório será enviado diretamente para o e-mail que está cadastrado em nosso sistema.</p>
      <h4>5.2</h4>
      <p>Se desejar alterar seus dados pessoais, o mesmo poderá ser feito acessando a área logada do site.</p>
      <h4>5.3</h4>
      <p>Se desejar que não realizemos mais o processamento da sua informação pessoal, acesse a área logada do site e solicite o encerramento de sua conta.</p>

      <h3>6. Disposições Gerais</h3>
      <h4>6.1</h4>
      <p>A E2W deverá manter as suas informações pessoais durante o tempo necessário para cumprir com os fins para os quais as informações são tratadas ou por outros motivos válidos para reter suas informações pessoais, como por exemplo o cumprimento de obrigações legais.</p>
      <h4>6.2</h4>
      <p>Encarregado pelo Tratamento de Proteção de Dados: Possuímos um responsável pela proteção de dados o qual inspeciona questões relacionadas a esta Política de Privacidade. Caso tenha alguma dúvida sobre como consultar seus dados e exercer seus direitos de titular, nos contate pelo e-mail:</p>
      <h4>6.3</h4>
      <p>A E2W não é responsável pelo uso indevido ou perda dos dados pessoais a que não tem acesso ou controle. Ficamos também isentos de responsabilidade diante do uso ilegal e não autorizado dessa informação como consequência de uso indevido ou desvio das suas credenciais de acesso, conduta negligente ou maliciosa como consequência de atos ou omissões da sua parte ou de alguém autorizado em seu nome.</p>

      <h3>C) Política de Cookies</h3>
      <p>A presente Política de Cookies é aplicada a todos os produtos e serviços da E2W relacionados a esta política ou incorporados a ela por referência. Utilizamos cookies e tecnologias semelhantes, tais como pixels, armazenamento local e códigos de anúncios em dispositivos móveis (mencionados coletivamente nesta política como “cookies”) para coletar e utilizar dados como parte de nossos Serviços.</p>

      <h4>1. Definição das Tecnologias Utilizadas</h4>
      <h4>1.1</h4>
      <p>Utilizamos ferramentas de análise de navegação para a melhoria da nossa oferta de conteúdo. Essas ferramentas são Google Analytics, que é responsável por analisar quais páginas e por quanto tempo foram vistas, e o Facebook Pixel, que ajuda a mapear o acesso em determinada página por meio do clique em um link de anúncio do Instagram ou do Facebook.</p>
      <h4>1.1.1</h4>
      <p>Um cookie é um pequeno arquivo adicionado ao seu dispositivo ou computador que permite ativar os recursos e as funcionalidades da E2W. Qualquer navegador que acesse nossos sites pode receber cookies nossos ou de terceiros, como nossos clientes, parceiros ou prestadores de serviços.</p>

      <h4>2. Finalidades</h4>
      <h4>2.1</h4>
      <p>Os dados de navegação como IP, ISP, Browser, e outras informações são coletados apenas com a finalidade estatística de melhorar a usabilidade e relevância de nosso site para você. A coleta desses dados é feita pelo Analytics.</p>
      <h4>2.2</h4>
      <p>Os cookies são utilizados com as seguintes finalidades:</p>
      <ol>
        <li>Autenticação - reconhecer quando o usuário acessa o serviço, permitindo à E2W exibir as informações corretas e personalizar a experiência de acordo com as configurações;</li>
        <li>Segurança - tornar a interação com o serviço mais ágil, segura e possibilitar a detecção de atividades mal-intencionadas e violações dos Termos de Uso;</li>
        <li>Preferência, recursos e serviços - habilitar funcionalidades do serviço, fornecendo recursos, estatísticas e conteúdo personalizado, bem como para lembrar informações sobre o navegador do usuário e suas preferências;</li>
        <li>Funcional - melhoria da experiência do usuário;</li>
        <li>Publicidade personalizada - permitir o envio de publicidade relevante para o usuário;</li>
        <li>Análise e pesquisa - realizar pesquisas e estudos sobre o desempenho do serviço em diferentes locais e identificar oportunidades de melhora.</li>
      </ol>
    </div>
  )
}

export default TermsOfUse
