import React from 'react';
import "./UploadButton.scss";
import PropTypes from "prop-types";

const UploadButton = ({handleFileUpload, accept}) => {
  return (
    <input className="UploadButton" accept={accept} type="file" onChange={handleFileUpload}/>
  );
};

export default UploadButton;

UploadButton.propTypes = {
  handleFileUpload: PropTypes.func.isRequired,
  accept: PropTypes.string
};
