import React, {useState} from 'react';
import "./InvitationByCsv.scss";
import DragAndDropUpload from "../../global/form/DragAndDropUpload/DragAndDropUpload";
import {ReactComponent as UploadIcon} from "../../../assets/images/upload.svg";
import UploadButton from "../../global/form/UploadButton/UploadButton";
import Button from "../../global/form/Button/Button";
import {validateFile} from "../../../helper/files";
import { buildAndDownloadCsv } from "../../../helper/csvHelper";

const InvitationByCsv = ({type, onSubmit, closeUploadArea, active, file, setFile}) => {
  const [error, setError] = useState(false);

  const handleFileUpload = (e) => {
    const file = (e.target && e.target.files[0]) || e;

    if (validateFile(file)) {
      setError(false)
      setFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    } else {
      setError(true);
    }
  };

  return (
    <section className={`InvitationByCsv ${active ? "active" : ""}`}>
      <div className="container">
        <h2>Adicione o arquivo CSV no padrão E2W com sua lista de {type}</h2>
        <p>Não conhece o padrão de CSV do E2W? Não tem problema, você pode baixar um modelo
          {" "}<span onClick={() => buildAndDownloadCsv(
                "nome;email;cargo;departamento\nJoão;joao@exemplo.com;desenvolvedor;TI"
              )}>clicando aqui</span>
        </p>
        <p className="error-message">{error && "Ops! Esse não parece ser um arquivo .csv"}</p>
        <DragAndDropUpload>
          <UploadIcon/>
          {!file?.name ? <p>arraste um arquivo csv para a tela ou</p> : <p>{file.name}</p>}
          <UploadButton handleFileUpload={handleFileUpload} accept=".csv"/>
        </DragAndDropUpload>
        <div className="buttons-wrapper">
          <Button onClick={onSubmit} className={!file ? "inactive" : ""}>enviar</Button>
          <button className="cancel-button" onClick={closeUploadArea}>cancelar</button>
        </div>
      </div>
    </section>
  );
};

export default InvitationByCsv;
