export const abbreviationMonths = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ"
];

export const localizeDate = (birthdate, splitChar = "-", joinChar = "/") => {
  if (birthdate && birthdate.includes(splitChar)) {
    return birthdate
      ? birthdate
          .split(splitChar)
          .reverse()
          .join(joinChar)
      : null;
  }
  return "";
};

export const dateToLongFormat = date => {
  const newDate = new Date(date);
  const day = newDate.getUTCDate();
  const month = abbreviationMonths[newDate.getUTCMonth()];
  return `${day}/${month} `;
};

export const getPresentMonth = () => {
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();

  return new Date(year, month + 1, date);
};

export const getPreviousMonth = monthsDifference => {
  let yearsDifference = 0;

  if (monthsDifference > 11) {
    yearsDifference = Math.floor(monthsDifference / 12);
    monthsDifference = monthsDifference - 12 * yearsDifference;
  }

  const today = new Date();
  const month = today.getMonth() - monthsDifference;
  const year = today.getFullYear() - yearsDifference;

  return new Date(year, month, 1);
};

export const shortYearDateFormat = (date, separator) => {
  date = date.split("/");
  let yearDigit = date[2].slice(-2);
  date = `${date[0]}${separator}${date[1]}${separator}${yearDigit}`;

  return date;
};

export const fullDate = date => {
  const split = date?.split("-");
  return `${split[2]} / ${split[1]} / ${split[0]}`;
};

export const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const getMonths = range => {
  let month = new Date().getMonth();
  const months = [];

  for (let i = 0; i < range; i++) {
    if (month > 0) {
      months.push(abbreviationMonths[month]);
      month--;
    } else {
      month = 12;
      months.push([abbreviationMonths[month]]);
    }
  }

  return months.reverse();
};
