import React, {useState} from 'react';
import './ModalInvestor.scss';
import TabButton from "../../global/TabButton/TabButton";
import Loading from "../../global/Loading/Loading";
import InvestorSummary from "../InvestorSummary/InvestorSummary";
import ModalInvestments from "../ModalInvestments/ModalInvestments";
import ModalContributions from "../ModalContributions/ModalContributions";
import { useSelector } from 'react-redux';

const ModalInvestor = ({ investor, handleShowModal }) => {
  const [navModal, setNavModal] = useState("resume");
  const topics = useSelector(state => state?.topics?.person);
  const executions = useSelector((state) => state?.executions);
  const personMetrics = useSelector(state => state?.metrics?.personMetrics);
  const executionMetrics = useSelector(state => state?.metrics?.personExecutionMetrics);

  const renderPage = () => {
    if (navModal === "resume") {
      return <InvestorSummary investorId={investor?.person?.id} />
    } else if (navModal === "investments") {
      return <ModalInvestments investorId={investor?.person?.id} />
    } else {
      return <ModalContributions investorId={investor?.person?.id} />
    }
  }

  return (
    <div className='ModalInvestor'>
      <Loading visible={
        topics?.isFetching ||
        executions?.isFetching ||
        personMetrics?.isFetching ||
        executionMetrics?.isFetching
      } />
      <div className="header">
        <div className="container-investor">
          <p>Investidor</p>
          <div className="info-investor">
            <p>{investor?.person?.name}</p>
            <p>{investor?.unit}</p>
            <p>{investor?.role}</p>
          </div>
          <div className="nav-modal">
            <TabButton className={navModal === "resume" ? "resume" : ""}
                       onClick={() => setNavModal("resume")}>Resumo</TabButton>
            <TabButton className={navModal === "investments" ? "investments" : ""}
                       onClick={() => setNavModal("investments")}>Investimentos</TabButton>
            <TabButton className={navModal === "contributions" ? "contributions" : ""}
                       onClick={() => setNavModal("contributions")}>Aportes</TabButton>
          </div>
        </div>
        <span role="button" onClick={() => handleShowModal()}>Fechar &nbsp; ✖</span>
      </div>
      <section>
        {renderPage()}
      </section>
    </div>
  );
};

export default ModalInvestor;
