export const demo = {
  evaluations: {
    monthly: {
      "average": 4.8
    },
    daily: {
      "average": 4.4
    }
  },
  engagement:
    { count: 15, percentage: 100 },
  topics: {
    topicsList:
      [ {
        "evaluation": 3.77,
        "variation": 3.77,
        topic:
          {
            "name": "Comunicação",
            "symbol": "COMU00",
            "description": "Competência reservada para estratégias de comunicação que convertem em atitudes e comportamentos desejados e planejados.",
            "id": 1
          }
      },
        {
          "evaluation": 5.87,
          "variation": -2.55,
          topic: {
            "name": "Resolução de problemas",
            "symbol": "RDPC00",
            "description": "Competência envolver o pensamento de design para conceber e entregar soluções para problemas de negócios, produtos, sistemas, serviços e processos",
            "id": 2
          }
        },
        {
          "evaluation": 6.78,
          "variation": 3.77,
          topic: {
            "name": "Planejamento e Estratégia",
            "symbol": "PLAN00",
            "description": "Competência envolver o pensamento de design para conceber e entregar planos e estratégias de produtos, serviços e processos",
            "id": 3
          }
        },
        {
          "evaluation": 4.56,
          "variation": 1.24,
          topic: {
            "name": "Negociação",
            "symbol": "NEGC0",
            "description": "Competência reservada para estratégias de criação de conscenso de escopos e decisões entre diferentes partes",
            "id": 4
          }
        },
        {
          "evaluation": 4.76,
          "variation": 3.88,
          topic: {
            "name": "Criatividade",
            "symbol": "CRTV00",
            "description": "Competência reservada a analise de aspectos contextuais e temporais para encontrar soluções ou produções inovadoras",
            "id": 5
          }
        },
        {
          "evaluation": 8.95,
          "variation": 6.77,
          topic: {
            "name": "Raciocinio lógico",
            "symbol": "RCLO00",
            "description": "Competência de considerar todos os fatos, pensando de forma estruturada para chegar a conclusões.",
            "id": 6
          }
        },
        {
          "evaluation": 3.75,
          "variation": 3.77,
          topic: {
            "name": "Gestão da mudança",
            "symbol": "GDMD00",
            "description": "Competência reservada ao trabalho junto a pessoas para entregar resultados em tempos de mudanças.",
            "id": 7
          }
        },
        {
          "evaluation": 7.98,
          "variation": 2.75,
          topic: {
            "name": "Gestão de pessoas",
            "symbol": "GDPS00",
            "description": "Competência reservada para funções de supervisão ou de gestão de times de trabalho",
            "id": 8
          }
        },
        {
          "evaluation": 2.37,
          "variation": -1.00,
          topic: {
            "name": "Análise de dados",
            "symbol": "ANLD00",
            "description": "Competência envolve a gestão de projetos, processos, serviços e produtos se apoiando em informação e dados (quadros, dashboards e gráficos)",
            "id": 9
          }
        },
        {
          "evaluation": 4.52,
          "variation": -2.43,
          topic: {
            "name": "Gestão Lean",
            "symbol": "LEAN00",
            "description": "Competência reservada para funções de identificação, monitoramento e retirada de desperdícios em processos, produtos e sistemas",
            "id": 10
          }
        },
        {
          "evaluation": 3.77,
          "variation": 3.77,
          topic: {
            "name": "Letramento Digital",
            "symbol": "LTDL00",
            "description": "Competência reservada a capacidade de aprender novos sistemas rapidamente bem como usar de forma efetiva a variedade de ferramentas digitais disponíveis.",
            "id": 11
          }
        },
        {
          "evaluation": 3.77,
          "variation": 3.77,
          topic: {
            "name": "Pesquisa e curadoria de informações",
            "symbol": "PECI00",
            "description": "Competência envolve  a identificação de fontes de informação relevantes, mapeamento de contrapontos e dispersão de informações",
            "id": 12
          }
        }
      ],
    evolutionFiltered:
      [
        [
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          }
          ,
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          }
          ,
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          }
          ,
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          }
          ,
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          }, {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },

          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          }
        ],
        [
          {
            "average": 6.83,
            "date": "2022-01-09",
            "max": 8.00,
            "min": 5.78
          },
          {
            "average": 7.91,
            "date": "2022-04-07",
            "max": 9.50,
            "min": 5.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 7.50,
            "min": 2.36
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 4.78,
            "date": "2022-05-09",
            "max": 6.70,
            "min": 3.67
          }
        ],
        [
          {
            "average": 4.20,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 3.50
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 5.55,
            "date": "2022-03-08",
            "max": 7.88,
            "min": 4.56
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 3.50,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 2.35
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 5.76,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 3.57
          },
          {
            "average": 5.64,
            "date": "2022-04-07",
            "max": 6.75,
            "min": 4.65
          },
          {
            "average": 5.55,
            "date": "2022-03-08",
            "max": 5.98,
            "min": 4.66
          },
          {
            "average": 6.78,
            "date": "2022-02-09",
            "max": 7.98,
            "min": 5.07
          },
          {
            "average": 2.54,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 1.25
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 2.45,
            "date": "2022-01-09",
            "max": 3.45,
            "min": 1.25
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 4.65,
            "date": "2022-05-09",
            "max": 5.05,
            "min": 3.67
          }
        ],
        [
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ],
        [
          {
            "average": 5.83,
            "date": "2022-01-09",
            "max": 6.00,
            "min": 4.78
          },
          {
            "average": 3.91,
            "date": "2022-04-07",
            "max": 4.50,
            "min": 2.75
          },
          {
            "average": 4.75,
            "date": "2022-03-08",
            "max": 5.00,
            "min": 3.71
          },
          {
            "average": 5.10,
            "date": "2022-02-09",
            "max": 5.50,
            "min": 5.07
          },
          {
            "average": 4.00,
            "date": "2021-12-10",
            "max": 4.70,
            "min": 3.96
          },
          {
            "average": 3.71,
            "date": "2022-05-09",
            "max": 4.20,
            "min": 3.67
          }
        ]
      ],
    evolution: [
      [
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 4.80,
          "date": "2021-11-10",
          "max": 5.70,
          "min": 3.56
        },
        {
          "average": 5.00,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 3.00
        },
        {
          "average": 6.00,
          "date": "2021-09-10",
          "max": 6.40,
          "min": 3.96
        },
        {
          "average": 6.30,
          "date": "2021-08-10",
          "max": 7.00,
          "min": 4.50
        },
        {
          "average": 6.30,
          "date": "2021-07-10",
          "max": 6.70,
          "min": 3.96
        }
      ],
      [
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 5.50,
          "date": "2021-11-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 4.50,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 2.96
        },
        {
          "average": 5.00,
          "date": "2021-09-10",
          "max": 5.90,
          "min": 4.56
        },
        {
          "average": 4.80,
          "date": "2021-08-10",
          "max": 5.70,
          "min": 1.96
        },
        {
          "average": 5.00,
          "date": "2021-07-10",
          "max": 5.70,
          "min": 3.50
        }
      ],
      [
        {
          "average": 6.83,
          "date": "2022-01-09",
          "max": 8.00,
          "min": 5.78
        },
        {
          "average": 7.91,
          "date": "2022-04-07",
          "max": 9.50,
          "min": 5.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 7.50,
          "min": 2.36
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 4.78,
          "date": "2022-05-09",
          "max": 6.70,
          "min": 3.67
        },
        {
          "average": 4.80,
          "date": "2021-11-10",
          "max": 5.70,
          "min": 3.56
        },
        {
          "average": 4.00,
          "date": "2021-10-10",
          "max": 4.70,
          "min": 2.00
        },
        {
          "average": 4.50,
          "date": "2021-09-10",
          "max": 5.40,
          "min": 3.96
        },
        {
          "average": 5.30,
          "date": "2021-08-10",
          "max": 6.00,
          "min": 4.50
        },
        {
          "average": 6.10,
          "date": "2021-07-10",
          "max": 6.70,
          "min": 4.96
        }
      ],
      [
        {
          "average": 4.20,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 3.50
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 5.55,
          "date": "2022-03-08",
          "max": 7.88,
          "min": 4.56
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 3.50,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 2.35
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 4.00,
          "date": "2021-11-10",
          "max": 5.00,
          "min": 3.56
        },
        {
          "average": 5.00,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 3.00
        },
        {
          "average": 6.00,
          "date": "2021-09-10",
          "max": 6.40,
          "min": 3.96
        },
        {
          "average": 6.30,
          "date": "2021-08-10",
          "max": 7.00,
          "min": 4.50
        },
        {
          "average": 6.30,
          "date": "2021-07-10",
          "max": 6.70,
          "min": 3.96
        }
      ],
      [
        {
          "average": 5.76,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 3.57
        },
        {
          "average": 5.64,
          "date": "2022-04-07",
          "max": 6.75,
          "min": 4.65
        },
        {
          "average": 5.55,
          "date": "2022-03-08",
          "max": 5.98,
          "min": 4.66
        },
        {
          "average": 6.78,
          "date": "2022-02-09",
          "max": 7.98,
          "min": 5.07
        },
        {
          "average": 2.54,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 1.25
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 3.90,
          "date": "2021-11-10",
          "max": 5.70,
          "min": 3.00
        },
        {
          "average": 4.20,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 3.60
        },
        {
          "average": 4.00,
          "date": "2021-09-10",
          "max": 5.70,
          "min": 3.3
        },
        {
          "average": 5.00,
          "date": "2021-08-10",
          "max": 6.00,
          "min": 4.50
        },
        {
          "average": 5.00,
          "date": "2021-07-10",
          "max": 6.00,
          "min": 4.50
        }
      ],
      [
        {
          "average": 2.45,
          "date": "2022-01-09",
          "max": 3.45,
          "min": 1.25
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 4.65,
          "date": "2022-05-09",
          "max": 5.05,
          "min": 3.67
        },
        {
          "average": 4.80,
          "date": "2021-11-10",
          "max": 5.50,
          "min": 3.20
        },
        {
          "average": 4.90,
          "date": "2021-10-10",
          "max": 5.60,
          "min": 3.40
        },
        {
          "average": 4.50,
          "date": "2021-09-10",
          "max": 5.90,
          "min": 2.96
        },
        {
          "average": 5.30,
          "date": "2021-08-10",
          "max": 7.00,
          "min": 4.50
        },
        {
          "average": 5.40,
          "date": "2021-07-10",
          "max": 7.00,
          "min": 4.86
        }
      ],
      [
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 3.80,
          "date": "2021-11-10",
          "max": 4.70,
          "min": 3.80
        },
        {
          "average": 3.40,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 3.00
        },
        {
          "average": 4.00,
          "date": "2021-09-10",
          "max": 6.40,
          "min": 3.96
        },
        {
          "average": 4.30,
          "date": "2021-08-10",
          "max": 6.40,
          "min": 4.50
        },
        {
          "average": 4.00,
          "date": "2021-07-10",
          "max": 6.40,
          "min": 3.96
        }
      ],
      [
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 3.80,
          "date": "2021-11-10",
          "max": 4.70,
          "min": 3.56
        },
        {
          "average": 3.60,
          "date": "2021-10-10",
          "max": 4.70,
          "min": 2.80
        },
        {
          "average": 3.80,
          "date": "2021-09-10",
          "max": 4.90,
          "min": 2.50
        },
        {
          "average": 4.30,
          "date": "2021-08-10",
          "max": 7.00,
          "min": 3.50
        },
        {
          "average": 4.30,
          "date": "2021-07-10",
          "max": 6.70,
          "min": 3.96
        }
      ],
      [
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 4.00,
          "date": "2021-11-10",
          "max": 5.70,
          "min": 3.56
        },
        {
          "average": 3.90,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 3.00
        },
        {
          "average": 4.20,
          "date": "2021-09-10",
          "max": 6.40,
          "min": 3.7
        },
        {
          "average": 4.00,
          "date": "2021-08-10",
          "max": 6.20,
          "min": 3.50
        },
        {
          "average": 4.30,
          "date": "2021-07-10",
          "max": 6.70,
          "min": 3.96
        }
      ],
      [
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 3.71,
          "date": "2021-11-10",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 3.90,
          "date": "2021-10-10",
          "max": 4.70,
          "min": 3.20
        },
        {
          "average": 3.80,
          "date": "2021-09-10",
          "max": 4.40,
          "min": 3.16
        },
        {
          "average": 4.00,
          "date": "2021-08-10",
          "max": 5.00,
          "min": 3.50
        },
        {
          "average": 4.30,
          "date": "2021-07-10",
          "max": 5.70,
          "min": 3.70
        }
      ],
      [
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 4.80,
          "date": "2021-11-10",
          "max": 5.70,
          "min": 3.00
        },
        {
          "average": 4.25,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 2.00
        },
        {
          "average": 5.00,
          "date": "2021-09-10",
          "max": 6.40,
          "min": 3.96
        },
        {
          "average": 4.30,
          "date": "2021-08-10",
          "max": 6.00,
          "min": 3.80
        },
        {
          "average": 4.30,
          "date": "2021-07-10",
          "max": 6.70,
          "min": 3.05
        }
      ],
      [
        {
          "average": 5.83,
          "date": "2022-01-09",
          "max": 6.00,
          "min": 4.78
        },
        {
          "average": 3.91,
          "date": "2022-04-07",
          "max": 4.50,
          "min": 2.75
        },
        {
          "average": 4.75,
          "date": "2022-03-08",
          "max": 5.00,
          "min": 3.71
        },
        {
          "average": 5.10,
          "date": "2022-02-09",
          "max": 5.50,
          "min": 5.07
        },
        {
          "average": 4.00,
          "date": "2021-12-10",
          "max": 4.70,
          "min": 3.96
        },
        {
          "average": 3.71,
          "date": "2022-05-09",
          "max": 4.20,
          "min": 3.67
        },
        {
          "average": 3.80,
          "date": "2021-11-10",
          "max": 5.70,
          "min": 3.56
        },
        {
          "average": 4.20,
          "date": "2021-10-10",
          "max": 5.70,
          "min": 3.60
        },
        {
          "average": 4.00,
          "date": "2021-09-10",
          "max": 6.40,
          "min": 3.2
        },
        {
          "average": 3.90,
          "date": "2021-08-10",
          "max": 6.40,
          "min": 3.00
        },
        {
          "average": 3.50,
          "date": "2021-07-10",
          "max": 6.70,
          "min": 2.95
        } ]
    ]
  },
  employees: [
    {
      "items": [
        {
          "id": 104,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "ana@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "NON_BINARY",
            "id": 185,
            "lastEvaluation": "2022-05-09",
            "name": "Ana Achutti",
            "schooling": null,
            "state": null
          },
          "role": "Trainee",
          "unit": "Marketing"
        },
        {
          "id": 105,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "beatriz@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "MALE",
            "id": 186,
            "lastEvaluation": "2022-05-09",
            "name": "Beatriz Alonso",
            "schooling": null,
            "state": null
          },
          "role": "Pleno",
          "unit": "Marketing"
        },
        {
          "id": 106,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "bruno@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "FEMALE",
            "id": 187,
            "lastEvaluation": "2022-05-09",
            "name": "Bruno Andrade",
            "schooling": null,
            "state": null
          },
          "role": "Senior",
          "unit": "Marketing"
        },
        {
          "id": 107,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "camila@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "FEMALE",
            "id": 188,
            "lastEvaluation": "2022-05-09",
            "name": "Camila Brito",
            "schooling": null,
            "state": null
          },
          "role": "Estagiário",
          "unit": "Marketing"
        },
        {
          "id": 108,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "eric@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "NON_BINARY",
            "id": 189,
            "lastEvaluation": "2022-05-09",
            "name": "Eric Carlotti",
            "schooling": null,
            "state": null
          },
          "role": "Pleno",
          "unit": "Tecnologia"
        },
        {
          "id": 109,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "erica@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "MALE",
            "id": 190,
            "lastEvaluation": "2022-05-09",
            "name": "Érica Efeiche",
            "schooling": null,
            "state": null
          },
          "role": "Senior",
          "unit": "Tecnologia"
        },
        {
          "id": 110,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "fabio@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "NON_BINARY",
            "id": 191,
            "lastEvaluation": "2022-05-09",
            "name": "Fabio Esequiel",
            "schooling": null,
            "state": null
          },
          "role": "Estagiário",
          "unit": "Tecnologia"
        },
        {
          "id": 111,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "laercio@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "MALE",
            "id": 192,
            "lastEvaluation": "2022-05-09",
            "name": "Laércio Rômulo",
            "schooling": null,
            "state": null
          },
          "role": "Trainee",
          "unit": "Tecnologia"
        },
        {
          "id": 112,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "pedro@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "MALE",
            "id": 193,
            "lastEvaluation": "2022-05-09",
            "name": "Pedro Zacarias",
            "schooling": null,
            "state": null
          },
          "role": "Estagiário",
          "unit": "RH "
        },
        {
          "id": 113,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "larissa@xpto.com ",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "NON_BINARY",
            "id": 194,
            "lastEvaluation": "2022-05-09",
            "name": "Larissa Levada",
            "schooling": null,
            "state": null
          },
          "role": "Senior",
          "unit": "Tecnologia"
        }
      ],
      "nextPage": 2,
      "page": 1,
      "prevPage": null,
      "totalItems": 15,
      "totalPages": 2
    },
    {
      "items": [
        {
          "id": 104,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "natalia@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "NON_BINARY",
            "id": 185,
            "lastEvaluation": "2022-05-09",
            "name": "Natalia Roger",
            "schooling": null,
            "state": null
          },
          "role": "Comunicação ",
          "unit": "Trainee"
        },
        {
          "id": 105,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "lydia@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "MALE",
            "id": 186,
            "lastEvaluation": "2022-05-09",
            "name": "Lydia Morita",
            "schooling": null,
            "state": null
          },
          "role": "Trainee",
          "unit": "Novos negócios"
        },
        {
          "id": 106,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "marcos@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "FEMALE",
            "id": 187,
            "lastEvaluation": "2022-05-09",
            "name": "Marcos Orosco",
            "schooling": null,
            "state": null
          },
          "role": "Pleno",
          "unit": "Novos negócios"
        },
        {
          "id": 107,
          "joined_at": "2022-05-09",
          "person": {
            "birthday": null,
            "email": "maria@xpto.com",
            "emailGoogle": null,
            "emailLinkedin": null,
            "gender": "FEMALE",
            "id": 188,
            "lastEvaluation": "2022-05-09",
            "name": "Maria Pimentel",
            "schooling": null,
            "state": null
          },
          "role": "Senior",
          "unit": "Design"
        }
      ],
      "nextPage": null,
      "page": 2,
      "prevPage": 1,
      "totalItems": 15,
      "totalPages": 2
    }
  ]
  ,
  managers: [ {
    "items": [
      {
        "id": 104,
        "joined_at": "2022-05-09",
        "manager": {
          "birthday": null,
          "email": "ana@xpto.com",
          "emailGoogle": null,
          "emailLinkedin": null,
          "gender": "NON_BINARY",
          "id": 185,
          "lastEvaluation": "2022-05-09",
          "name": "Ana Achutti",
          "schooling": null,
          "state": null
        },
        "roles": [ "Trainee", "Estagiário" ],
        "units": [ "Marketing" ]
      },
      {
        "id": 105,
        "joined_at": "2022-05-09",
        "manager": {
          "birthday": null,
          "email": "beatriz@xpto.com",
          "emailGoogle": null,
          "emailLinkedin": null,
          "gender": "MALE",
          "id": 186,
          "lastEvaluation": "2022-05-09",
          "name": "Beatriz Alonso",
          "schooling": null,
          "state": null
        },
        "roles": [ "Pleno", "Senior", "Junior", "Trainee" ],
        "units": [ "Marketing" ]
      }
    ],
    "nextPage": null,
    "page": 1,
    "prevPage": null,
    "totalItems": 2,
    "totalPages": 1
  } ],
  accesses: [
    {
      "company": {
        "icon": "https://i.imgur.com/bBG5eRR.png",
        "id": 17,
        "name": "cd867fa6-dcad-49ff-b707-dcb5c066a69d"
      },
      "given_at": "2022-05-09",
      "id": 25,
      "roles": [
        "role-2",
        "role-12",
        "role-5",
        "role-11",
        "role-4",
        "role-9",
        "role-10",
        "role-3",
        "role-1",
        "role-8",
        "role-7",
        "role-6" ],
      "units": [ "unit-6",
        "unit-5",
        "unit-2",
        "unit-3",
        "unit-1",
        "unit-4" ]
    }
  ],
  tasks: {
    "items": [
      {
        topic: { symbol: "COMU00" },
        title: "Leitura Adams Óbvio",
        createdAt: "18.03.2022",
        taskMedium: "REMOTE",
        contentType: "TEXT",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PECR00" },
        title: "Netflix: Chernobyl",
        createdAt: "16.02.2022",
        taskMedium: "REMOTE",
        contentType: "VIDEO",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "RDPC00" },
        title: "Netflix: The Challenger",
        createdAt: "14.02.2022",
        taskMedium: "REMOTE",
        contentType: "VIDEO",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PECR00" },
        title: "The Inventor Documentário",
        createdAt: "01.02.2022",
        taskMedium: "REMOTE",
        contentType: "VIDEO",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "RDPC00" },
        title: "Formação de Product Owner Mastertech",
        createdAt: "30.01.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PECP00" },
        title: "Data Science I - Alura",
        createdAt: "28.01.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PECP00" },
        title: "Programação para não programadores - Mastertech",
        createdAt: "18.03.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "LIDR00" },
        title: "Lean Six Sigma - Yellow Belt Certification",
        createdAt: "16.02.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "CRTV00" },
        title: "Lean Six Sigma - Red Belt Certification",
        createdAt: "14.02.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "LIDR00" },
        title: "Lean Six Sigma - Red Belt Certification",
        createdAt: "01.02.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "RMIP00" },
        title: "Treinamento Comunicação Não Violenta",
        createdAt: "30.01.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "LIDR00" },
        title: "Condução Lean Inception",
        createdAt: "28.01.2022",
        taskMedium: "FACE_TO_FACE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PECP00" },
        title: "Data Science Lab",
        createdAt: "18.03.2022",
        taskMedium: "REMOTE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PECR00" },
        title: "Leitura Rápido e Devagar",
        createdAt: "16.02.2022",
        taskMedium: "REMOTE",
        contentType: "TEXT",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PLAN00" },
        title: "Participação Projeto Ágora em ágil",
        createdAt: "14.02.2022",
        taskMedium: "FACE_TO_FACE",
        contentType: "HANDS_ON",
        taskMotive: "SELF_INITIATIVE",
        contentAcquisition: "PAID",
      },
      {
        topic: { symbol: "PLAN00" },
        title: "Formação de Mindset Ágil Mastertech",
        createdAt: "01.02.2022",
        taskMedium: "FACE_TO_FACE",
        contentType: "VISUAL_EXPOSURE",
        taskMotive: "IMPOSED",
        contentAcquisition: "PAID",
      }
    ],
    "nextPage": null,
    "page": 1,
    "prevPage": null,
    "totalItems": 16,
    "totalPages": 0
  },
  log: [
    { details: "", createdAt: "2022-05-10T", action: "convidou um usuário" },
    { details: "", createdAt: "2022-05-10T", action: "convidou um grupo de usuários (csv)" },
    { details: "", createdAt: "2022-05-10T", action: "editou um usuário" },
    { details: "", createdAt: "2022-05-10T", action: "removeu um usuário" },
    { details: "", createdAt: "2022-05-10T", action: "convidou um gestor" },
    { details: "", createdAt: "2022-05-10T", action: "convidou um grupo de gestores (csv)" },
    { details: "", createdAt: "2022-05-10T", action: "editou um gestor" },
    { details: "", createdAt: "2022-05-10T", action: "removeu um gestor" }
  ],
  units: [
    "Marketing",
    "Tecnologia",
    "Novos negócios",
    "Design",
    "Comunicação",
    "RH"
  ],
  roles: [ "Trainee",
    "Pleno",
    "Senior",
    "Estagiário" ],
  trending: [
    {
      topic: {
        "name": "Gestão da mudança",
        "symbol": "GDMD00",
        "description": "Competência reservada ao trabalho junto a pessoas para entregar resultados em tempos de mudanças.",
        "id": 7
    },
    variation: 7.5
  }, 
  {
    topic: {
      "name": "Pesquisa e curadoria de informações",
      "symbol": "PECI00",
      "description": "Competência envolve  a identificação de fontes de informação relevantes, mapeamento de contrapontos e dispersão de informações",
      "id": 12
    }, 
    variation: 6
  },
  {
    "variation": 3.77,
    topic: {
      "name": "Letramento Digital",
      "symbol": "LTDL00",
      "description": "Competência reservada a capacidade de aprender novos sistemas rapidamente bem como usar de forma efetiva a variedade de ferramentas digitais disponíveis.",
      "id": 11
    }
  },
  {
    "variation": -2.43,
    topic: {
      "name": "Gestão Lean",
      "symbol": "LEAN00",
      "description": "Competência reservada para funções de identificação, monitoramento e retirada de desperdícios em processos, produtos e sistemas",
      "id": 10
    }
  }
  ]
};
