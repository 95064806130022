import {
  LINKEDIN_DATA_REQUEST,
  LINKEDIN_DATA_REQUEST_FAILED,
  LINKEDIN_DATA_REQUEST_SUCCEEDED,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGIN_REQUEST_SUCCEEDED,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_GOOGLE_SUCCEEDED,
  LOGIN_WITH_MICROSOFT,
  LOGIN_WITH_MICROSOFT_FAILED,
  LOGIN_WITH_MICROSOFT_SUCCEEDED,
  LOGOUT_REQUEST
} from "../constants/login";

export const login = (
  state = {
    data: {},
    loggedIn: null,
    token: "",
    refreshToken: "",
    isFetching: false
  },
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOGIN_WITH_GOOGLE:
    case LINKEDIN_DATA_REQUEST:
    case LOGIN_WITH_MICROSOFT:
      newState.isFetching = true;
      return newState;

    case LOGIN_REQUEST_SUCCEEDED:
    case LOGIN_WITH_GOOGLE_SUCCEEDED:
    case LINKEDIN_DATA_REQUEST_SUCCEEDED:
    case LOGIN_WITH_MICROSOFT_SUCCEEDED:
      newState = {
        ...state.data,
        data: { ...action.data.manager },
        loggedIn: true,
        token: action.data.token,
        refreshToken: action.data.refreshToken
      };
      newState.isFetching = false;
      return newState;

    case LOGIN_REQUEST_FAILED:
    case LINKEDIN_DATA_REQUEST_FAILED:
    case LOGIN_WITH_MICROSOFT_FAILED:
      newState = {
        data: {},
        loggedIn: false
      };
      newState.isFetching = false;
      return newState;

    case LOGOUT_REQUEST:
      newState.data = {};
      newState.isFetching = false;
      return newState;

    default:
      return state;
  }
};
