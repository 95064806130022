export const COMPANY_REQUEST = "COMPANY_REQUEST";
export const COMPANY_REQUEST_SUCCEEDED = "COMPANY_REQUEST_SUCCEEDED";
export const COMPANY_REQUEST_FAILED = "COMPANY_REQUEST_FAILED";

export const GET_METRICS_ENGAGEMENT_REQUEST = 
  "GET_METRICS_ENGAGEMENT_REQUEST";
export const GET_METRICS_ENGAGEMENT_REQUEST_SUCCEEDED =
  "GET_METRICS_ENGAGEMENT_REQUEST_SUCCEEDED";
export const GET_METRICS_ENGAGEMENT_REQUEST_FAILED =
  "GET_METRICS_ENGAGEMENT_REQUEST_FAILED";
