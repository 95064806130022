import React, { useState } from "react";
import "./DescribeQuestion.scss";
import TextArea from "../../global/form/TextArea/TextArea";
import ConfirmPopUp from "../../global/ConfirmPopUp/ConfirmPopUp";
import Button from "../../global/form/Button/Button";

const DescribeQuestion = () => {
  const [sendQuestion, setSendQuestion] = useState("");
  const [activePopUp, setActivePopUp] = useState(false);

  const submitQuestion = event => {
    event.preventDefault();
    setSendQuestion("");
  };

  const openPopUp = () => {
    if (sendQuestion !== "") {
      setActivePopUp(true);
    }
  };

  return (
    <div className="DescribeQuestion">
      <h2>Não encontrou o que procurava?</h2>
      <h3>
        Entre em contato com a gente. Você receberá a resposta no seu e-mail
        cadastrado.
      </h3>
      <form onSubmit={submitQuestion}>
        <TextArea
          placeholder={"Descreva o que está acontecendo"}
          updateState={setSendQuestion}
          value={sendQuestion}
        />
        <div className="button">
          <Button
            invertColors={true}
            onClick={() => {
              openPopUp();
            }}
          >
            solicitar contato
          </Button>
        </div>
      </form>
      {activePopUp && (
        <ConfirmPopUp
          active={activePopUp}
          buttonAction={() => {
            setActivePopUp(false);
          }}
          buttonText="OK"
          message="Dúvida enviada com sucesso, entraremos em contato em breve."
        />
      )}
    </div>
  );
};

export default DescribeQuestion;
