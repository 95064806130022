import {
  CREATE_INVESTMENT,
  CREATE_INVESTMENT_FAILED,
  CREATE_INVESTMENT_SUCCEEDED,
  INVESTMENT_DETAILS_INVESTORS,
  INVESTMENT_DETAILS_INVESTORS_FAILED,
  INVESTMENT_DETAILS_INVESTORS_SUCCEEDED,
  INVESTMENT_DETAILS_PROGRESSION,
  INVESTMENT_DETAILS_PROGRESSION_FAILED,
  INVESTMENT_DETAILS_PROGRESSION_SUCCEEDED,
  INVESTMENT_DETAILS_TASKS,
  INVESTMENT_DETAILS_TASKS_FAILED,
  INVESTMENT_DETAILS_TASKS_SUCCEEDED,
  LIST_INVESTMENTS,
  LIST_INVESTMENTS_FAILED,
  LIST_INVESTMENTS_SUCCEEDED,
  UPDATE_INVESTMENT,
  UPDATE_INVESTMENT_FAILED,
  UPDATE_INVESTMENT_SUCCEEDED
} from "../constants/investments";

export const investments = (
  state = {
    isFetching: false,
    data: {},
    error: {}
  }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case CREATE_INVESTMENT:
    case LIST_INVESTMENTS:
    case UPDATE_INVESTMENT:
      newState.isFetching = true;
      return newState;

    case CREATE_INVESTMENT_SUCCEEDED:
    case LIST_INVESTMENTS_SUCCEEDED:
    case UPDATE_INVESTMENT_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case CREATE_INVESTMENT_FAILED:
    case LIST_INVESTMENTS_FAILED:
    case UPDATE_INVESTMENT_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};

export const investmentDetails = (
  state = {
    isFetching: false,
    progression: [],
    investors: {},
    tasks: {},
  }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case INVESTMENT_DETAILS_PROGRESSION:
    case INVESTMENT_DETAILS_INVESTORS:
    case INVESTMENT_DETAILS_TASKS:
      newState.isFetching = true;
      return newState;

    case INVESTMENT_DETAILS_PROGRESSION_SUCCEEDED:
      newState.isFetching = false;
      newState.progression = action.data;
      return newState;

    case INVESTMENT_DETAILS_PROGRESSION_FAILED:
      newState.isFetching = false;
      newState.progression = [];
      return newState;

    case INVESTMENT_DETAILS_INVESTORS_SUCCEEDED:
      newState.isFetching = false;
      newState.investors = action.data;
      return newState;

    case INVESTMENT_DETAILS_INVESTORS_FAILED:
      newState.isFetching = false;
      newState.investors = [];
      return newState;

    case INVESTMENT_DETAILS_TASKS_SUCCEEDED:
      newState.isFetching = false;
      newState.tasks = action.data;
      return newState;

    case INVESTMENT_DETAILS_TASKS_FAILED:
      newState.isFetching = false;
      newState.tasks = [];
      return newState;
    default:
      return newState;
  }
};