import React from 'react';
import LineChart from '../../dashboard/LineChart/LineChart';
import Grade from '../../global/Grade/Grade';
import Button from '../../global/form/Button/Button';
import Select from '../../global/form/Select/Select';
import './InvestmentResume.scss';

const InvestmentResume = ({ investment, investmentDetails, setShowModal }) => {
  const evoluations = investmentDetails?.progression ? investmentDetails?.progression : []

  const getChartData = () => {
    const lastMonth = [5]
    const hasTopicEvolution = evoluations > 0;
    const topicEvolutionMonths = hasTopicEvolution
      ? evoluations.map((item) => new Date(item.date).getMonth())
      : lastMonth;
    const mockedData = [0, 1, 2]
      .filter(item => !topicEvolutionMonths.includes(item))
      .map(item => (
        {
          date: new Date(new Date().getFullYear(), item, 1).toISOString(),
          average: 0,
          min: 0,
          max: 0
        }
      ));
    const chartData = [...evoluations, ...mockedData]
    return chartData;
  };

  return (
    <div className='InvestmentResume'>
      <div className="select-container">
        <Select
          updateState={() => { }}
          options={["Todas as áreas", "últimos 7 dias", "últimos 90 dias"]}
          displayError={false}
          value={'test'}
        />
        <Select
          updateState={() => { }}
          options={["Todos os níveis", "últimos 90 dias"]}
          displayError={false}
          value={'test'}
        />
        <Select
          updateState={() => { }}
          options={["Últimos 6 meses", "últimos 90 dias"]}
          displayError={false}
          value={'test'}
        />
      </div>
      <main className="resume-tab-content">
        <div className="box">
          <div className="chart">
            <div className="line-chart">
              <LineChart data={getChartData()} />
            </div>
          </div>
        </div>
        <div className="description">
          <Grade value={investment?.tracking?.average} variation={investment?.tracking?.variation} />
          <p className="investing-community">
            Na comunidade, <br /> este investimento está em {investment?.tracking?.variation > 0 ? "alta" : "baixa"}.
          </p>
          <p className="investing-description">
            Isso significa que o valor médio {investment?.tracking?.variation > 0 ? "subiu" : "desceu"}.
            Ou seja, mais pessoas indicaram que possuem de
            {investment?.tracking?.variation > 0
              ? " médio a alto"
              : " baixo a médio"}{" "}
            grau de proficiência nessa competência.
          </p>
          <div className="details">
            <span>{investmentDetails?.tasks?.items?.length || 0} aportes</span>
            <span>{investmentDetails?.investors?.length} Investidores</span>
          </div>
          <Button>Solicitar reavaliações</Button>
          <p onClick={() => setShowModal(true)} className='edit-investment' role='button'>Editar competência</p>

        </div>

      </main>
    </div>
  )
}

export default InvestmentResume
