import React from 'react';
import Button from "../form/Button/Button";
import './PopUpSuccess.scss'
import PopUp from "../PopUp/PopUp";

const PopUpSuccess = ({active, closePopUp, children}) => {
  return (
    <PopUp active={active}>
      <div className="popup-image-success"/>
      <div className="popup-success">
        {children}
        <Button onClick={closePopUp}>OK</Button>
      </div>
    </PopUp>
  );
};

export default PopUpSuccess;
