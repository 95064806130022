import {
  TOPIC_EVOLUTION_FILTERED_REQUEST, TOPIC_EVOLUTION_FILTERED_REQUEST_FAILED, TOPIC_EVOLUTION_FILTERED_REQUEST_SUCCEEDED,
  TOPIC_EVOLUTION_REQUEST, TOPIC_EVOLUTION_REQUEST_FAILED, TOPIC_EVOLUTION_REQUEST_SUCCEEDED,
  TOPICS_PROGRESSION_MONTH_REQUEST,
  TOPICS_PROGRESSION_MONTH_REQUEST_FAILED,
  TOPICS_PROGRESSION_MONTH_REQUEST_SUCCEEDED,
  GET_PERSON_TOPIC_SUMMARY_REQUEST,
  GET_PERSON_TOPIC_SUMMARY_REQUEST_FAILED,
  GET_PERSON_TOPIC_SUMMARY_REQUEST_SUCCEEDED
} from "../constants/topics";

export const topics = (
  state = {
    topicsList: {
      isFetching: false,
      data: [],
      error: {}
    },
    evolution: {
      data: [],
      isFetching: false,
      error:{}
    },
    evolutionFiltered: {
      data: [],
      isFetching: false,
      error:{}
    },
    person: {
      data: [],
      isFetching: false,
      error:{}
    }
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case TOPICS_PROGRESSION_MONTH_REQUEST:
      newState.topicsList.isFetching = true;
      return newState;

    case TOPICS_PROGRESSION_MONTH_REQUEST_SUCCEEDED:
      newState.topicsList.isFetching = false;
      newState.topicsList.data = action.data;
      newState.topicsList.error = {};
      return newState;

    case TOPICS_PROGRESSION_MONTH_REQUEST_FAILED:
      newState.topicsList.isFetching = false;
      newState.topicsList.error = action.error;
      newState.topicsList.data = [];
      return newState;

    case TOPIC_EVOLUTION_REQUEST:
      newState.evolution.isFetching = true;
      return newState;

    case TOPIC_EVOLUTION_REQUEST_SUCCEEDED:
      return { ...state, evolution: {
        isFetching: false,
          error: {},
          data: action.data
        } };

    case TOPIC_EVOLUTION_REQUEST_FAILED:
      newState.evolution.isFetching = false;
      newState.evolution.data = [];
      newState.evolution.error = action.error;
      return newState;

    case TOPIC_EVOLUTION_FILTERED_REQUEST:
      newState.evolutionFiltered.isFetching = true;
      return newState;

    case TOPIC_EVOLUTION_FILTERED_REQUEST_SUCCEEDED:
      newState.evolutionFiltered.isFetching = false;
      newState.evolutionFiltered.error = {};
      newState.evolutionFiltered.data = action.data;
      return newState;

    case TOPIC_EVOLUTION_FILTERED_REQUEST_FAILED:
      newState.evolutionFiltered.isFetching = false;
      newState.evolutionFiltered.data = [];
      newState.evolutionFiltered.error = action.error;
      return newState;
    
    case GET_PERSON_TOPIC_SUMMARY_REQUEST:
      newState.person.isFetching = true;
      return newState

    case GET_PERSON_TOPIC_SUMMARY_REQUEST_SUCCEEDED:
      newState.person.isFetching = false;
      newState.person.error = {};
      newState.person.data = action.data;
      return newState
    
    case GET_PERSON_TOPIC_SUMMARY_REQUEST_FAILED:
      newState.person.isFetching = false;
      newState.person.data = [];
      newState.person.error = action.error;
      return newState

    default:
      return newState;
  }
};
