import {
  recoveryPasswordRequest,
  recoveryPasswordRequestFailed,
  recoveryPasswordRequestSucceeded,
  updatePasswordWithCodeRequest,
  updatePasswordWithCodeRequestFailed,
  updatePasswordWithCodeRequestSucceeded,
} from "../action-creators/recoveryPassword";
import {
  apiRecoveryPasswordRequest,
  apiUpdatePasswordWithCodeRequest,
} from "../api/recoveryPassword";

const createRecoveryPasswordRequest = (payload) => (dispatch) => {
  dispatch(recoveryPasswordRequest());
  return apiRecoveryPasswordRequest(payload)
    .then((json) => {
      dispatch(recoveryPasswordRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((response) => {
      dispatch(recoveryPasswordRequestFailed(response));
      return Promise.reject(response);
    });
};

export const sendRecoveryPasswordCode = (payload) => (dispatch) =>
  dispatch(createRecoveryPasswordRequest(payload));

const createUpdatePasswordWithCodeRequest = (code, payload) => (dispatch) => {
  dispatch(updatePasswordWithCodeRequest());
  return apiUpdatePasswordWithCodeRequest(code, payload)
    .then((json) => {
      dispatch(updatePasswordWithCodeRequestSucceeded(json));
    })
    .catch((response) => {
      dispatch(updatePasswordWithCodeRequestFailed(response));
      return Promise.reject(response);
    });
};

export const updatePasswordWithCode = (code, payload) => (dispatch) =>
  dispatch(createUpdatePasswordWithCodeRequest(code, payload));
