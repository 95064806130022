import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../global/Card/Card";
import Table from "../../global/Table/Table";
import CommunityMetricsDistribution from "../../global/CommunityMetricsDistribution/CommunityMetricsDistribution";
import { getCommunityMetrics } from "../../../redux/actions/metrics";
import Loading from "../../global/Loading/Loading";
import { getEmployeesMetrics, getEmployees } from "../../../redux/actions/employees";
import { getCompanyMetricsEngagement } from "../../../redux/actions/company";
import "./Investors.scss";
import ModalInvestor from "../../modalInvestor/ModalInvestor/ModalInvestor";

const Investors = () => {
  const dispatch = useDispatch();
  const metrics = useSelector((state) => state?.metrics);
  const companyMetricsEngagement = useSelector((state) => state?.company);
  const employeesMetrics = useSelector((state) => state?.employeesMetrics);
  const employeesList = useSelector((state) => state?.employees);
  const community = metrics?.communityMetrics;
  const employeeWithMoreInvestments = employeesMetrics?.data.employeeWithMoreInvestments;
  const employeeWithLessInvestments = employeesMetrics?.data.employeeWithLessInvestments;
  const investors = employeesList?.data?.items;
  const count = employeesList?.data?.totalItems;
  const activeInvestors = (companyMetricsEngagement?.data?.percentage / 100 * count) || '0';
  const [showModal, setShowModal] = useState(false);
  const [selectedInvestor, setSelectedInvestor] = useState({});

  useEffect(() => {
    dispatch(getCommunityMetrics());
    dispatch(getEmployeesMetrics());
    dispatch(getEmployees());
    dispatch(getCompanyMetricsEngagement());
  }, [dispatch]);

  return (
    <section className="Investors">
      <h2 className="title">Investidores</h2>

      <p className="investment">
        Como sua equipe desenvolve seus investimentos?
      </p>

      <p className="contributions">
        Quais são os tipos de aportes mais realizados?
      </p>

      <div className="investors-cards-container">
        <Card title="Engajamento" className="active-investors">
          <div className="active-investors-content">
            <div className="active-investors-count">
              <span className="active-investors-title">{activeInvestors}</span>
              <span>
                Investidores <br /> Ativos
              </span>
            </div>
            <span>
              {companyMetricsEngagement?.data?.percentage}% do total 
              <br /> {count} investidores cadastrados
            </span>
          </div>
        </Card>

        <Card title="Maior Investidor" className="biggest-investors">
          <div className="biggest-investors-content">
            <p>{employeeWithMoreInvestments?.name}</p>
            <div className="biggest-investors-details">
              <p>
                {employeeWithMoreInvestments?.metrics.affiliations || "0"}{" "}
                investimentos
              </p>
              <p>
                {employeeWithMoreInvestments?.metrics.taskExecutionCount || "0"}{" "}
                aportes
              </p>
            </div>
          </div>
        </Card>

        <Card title="Menor Investidor" className="smallest-investors">
          <div className="smallest-investors-content">
            <p>{employeeWithLessInvestments?.name}</p>
            <div className="smallest-investors-details">
              <p>
                {employeeWithLessInvestments?.metrics.affiliations || "0"}{" "}
                investimentos
              </p>
              <p>
                {employeeWithLessInvestments?.metrics.taskExecutionCount || "0"}{" "}
                aportes
              </p>
            </div>
          </div>
        </Card>
      </div>

      <div className="charts-container">
        <div className="profile">
          <Card
            title="Perfis x Investimentos"
            className="card-profile-investment"
          >
            <p>
              Entenda quais são os tipos de investimentos mais realizados
            </p>

            <div className="chart">
              <CommunityMetricsDistribution community={community?.data} skill="skillKindDistribution" />
            </div>
          </Card>

          <Card title="Perfis x Aportes" className="card-profile-contributions">
            <p>
              Entenda quais são os tipos de aportes mais registrados
            </p>

            <div className="chart">
              <CommunityMetricsDistribution community={community?.data} />
            </div>
          </Card>
        </div>
      </div>

      <div className="investors-search">
        <div className="investors-count">
          <h1>Investidores</h1>
          <p>{count || "0"} integrantes da sua empresa</p>
        </div>
      </div>

      <div className="investors-list">
        <Table
          className="investors-table"
          headers={[
            "INVESTIDOR",
            "ÁREA",
            "NÍVEL",
            "INVESTIMENTOS",
            "APORTES",
            "REAVALIAÇÕES PENDENTES",
            "ÚLTIMA REAVALIAÇÂO",
            "",
          ]}
        >
          {investors?.map((investor) => (
            <tr key={investor?.id}>
              <td>{investor?.person.name}</td>
              <td>{investor?.unit}</td>
              <td>{investor?.role}</td>
              <td>{investor?.person.metrics.affiliations}</td>
              <td>{investor?.person.metrics.taskExecutionCount}</td>
              <td>{investor?.person.metrics.pendingEvaluations}</td>
              <td>
                {investor?.person.lastEvaluation ? 
                  new Date(investor?.person.lastEvaluation).toLocaleDateString('pt-BR') :
                  "Nenhuma avaliação encontrada"
                }
              </td>
              <td>
                <button
                  onClick={() => {
                    setShowModal(true);
                    setSelectedInvestor(investor);
                  }}
                >
                  Ver Detalhes
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </div>
      {showModal && (
        <ModalInvestor
          investor={selectedInvestor}
          handleShowModal={() => setShowModal(false)}
        />
      )}
      <Loading
        visible={
          companyMetricsEngagement?.isFetching ||
          community?.isFetching ||
          employeesMetrics?.isFetching ||
          employeesList?.isFetching
        } 
      />
    </section>
  );
};

export default Investors;
