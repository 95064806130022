import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getItemFromStorage } from "../../../helper/storage";
import { getAccesses } from '../../../redux/actions/accesses';
import { isUserAuthenticated } from "../../../redux/actions/authentication";
import { getDemo } from "../../../redux/actions/demo";
import { addInvestors, getEmployees } from "../../../redux/actions/employees";
import { getManagers } from "../../../redux/actions/managers";
import Loading from "../../global/Loading/Loading";
import PopUpSuccess from "../../global/PopUpSuccess/PopUpSuccess";
import InvitationByCsv from "../../invitation/InvitationByCsv/InvitationByCsv";
import AccessHeader from "../AccessHeader/AccessHeader";
import TableContainer from "../TableContainer/TableContainer";
import "./Access.scss";
import InvitesTable from '../InvitesTable/InvitesTable';

const Access = () => {
  const [page, setPage] = useState(1);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState("");
  const companyId = getItemFromStorage("COMPANY_ID");
  const { type } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const employees = useSelector(state => state.employees);
  const managers = useSelector(state => state.managers);
  const demo = useSelector(state => state.demo);
  const accesses = useSelector(state => state.accesses?.data);
  const isRootManager = accesses.find(item => item?.company?.id === Number(companyId));

  const handleEmployeesCsvSubmission = () => {
    setIsLoading(true);
    const payload = {
      file
    };
    dispatch(addInvestors(payload))
      .then(() => {
        setShowPopUp(true);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  const getTableData = () => {
    if (demo.isDemoMode) {
      return type === "gestores" ? demo.data?.managers[page - 1] : demo.data?.employees[page - 1]
    } else {
      return type === "gestores" ? managers.data : employees.data
    }
  }

  useEffect(() => {
    dispatch(getAccesses());
  }, [dispatch]);

  useEffect(() => {
    const isAuth = isUserAuthenticated();
    const isDemo = demo.isDemoMode;

    if (!isAuth && !isDemo) {
      history.push("/login");
    }

    if (isDemo && !isAuth) {
      dispatch(getDemo());
    }

    if (isAuth && type === "convites-gestores" && isRootManager?.level === "MANAGER") {
      history.push("/acessos/convites-gestores");
    }
    
    if (isAuth && type === "gestores" && isRootManager?.level === "MANAGER") {
      history.push("/acessos/colaboradores");
    }

    if (isAuth && type === "colaboradores" && isRootManager) {
      const filters = { page };
      setPage(1);
      dispatch(getEmployees(filters));
    }

    if (isAuth && type === "gestores" && isRootManager?.level !== "MANAGER") {
      const filters = { page };
      setPage(1);
      dispatch(getManagers(filters));
      dispatch(getEmployees(filters));
    }
  }, [demo.isDemoMode, dispatch, history, isRootManager, page, type]);

  return (
    <section className="Access">
      <AccessHeader
        uploadVisible={uploadVisible}
        setUploadVisible={setUploadVisible}
        active={uploadVisible}
        isRootManager={isRootManager}
      />
      { type === "gestores" || type === "colaboradores" ? <TableContainer data={getTableData()} setPage={setPage} hideTable={uploadVisible} /> : <InvitesTable data={getTableData()} setPage={setPage} hideTable={uploadVisible} /> }
      {/* <TableContainer data={getTableData()} setPage={setPage} hideTable={uploadVisible} /> */}
      <InvitationByCsv
        active={uploadVisible}
        file={file} setFile={setFile}
        closeUploadArea={() => setUploadVisible(false)}
        onSubmit={handleEmployeesCsvSubmission}
        type={"colaboradores"}
      />
      <Loading visible={employees?.isFetching || managers?.isFetching || isLoading} />
      <PopUpSuccess
        active={showPopUp}
        closePopUp={() => history.push("/acessos/colaboradores")}
      >
        <p>Convite enviado aos colaboradores!</p>
      </PopUpSuccess>
    </section>
  );
};

export default Access;
