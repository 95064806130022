import {
  SET_DEMO_MODE,
  SET_DEMO_DATA_REQUEST,
  SET_DEMO_DATA_REQUEST_SUCCEEDED
} from "../constants/demo";

export const setDemoMode = () => {
  return {
    type: SET_DEMO_MODE
  }
};

export const setDemoDataRequest = (data) => {
  return {
    type: SET_DEMO_DATA_REQUEST,
    data: data
  }
};

export const setDemoDataRequestSucceeded = (data) => {
  return {
    type: SET_DEMO_DATA_REQUEST_SUCCEEDED,
    data: data
  }
};
