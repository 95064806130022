import React from 'react';
import Table from "../../global/Table/Table";
import InvestmentsTableRow from '../InvestmentsTableRow/InvestmentsTableRow';
import './InvestmentsTable.scss';

const InvestmentsTable = ({ executions, setSeletedContribution, setOpenDetails }) => {
  return (
    <Table
      className="InvestmentsTable"
      headers={[
        "Código",
        "Investimento",
        "Comunidade",
        "Tipo",
        "Aportes",
        "Investidores",
        ""
      ]}
    >
      {executions?.map((execution, key) => (
        <InvestmentsTableRow
          setOpenDetails={setOpenDetails}
          setSeletedContribution={setSeletedContribution}
          execution={execution}
          key={key}
        />
      ))}
    </Table>
  )
}

export default InvestmentsTable
