import {
  GET_PERSON_EXECUTIONS_REQUEST,
  GET_PERSON_EXECUTIONS_REQUEST_FAILED,
  GET_PERSON_EXECUTIONS_REQUEST_SUCCEEDED
} from "../constants/executions";

export const personExecutionsRequest = () => {
  return {
    type: GET_PERSON_EXECUTIONS_REQUEST
  }
};

export const personExecutionsRequestSucceeded = (payload) => {
  return {
    type: GET_PERSON_EXECUTIONS_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const personExecutionsRequestFailed = (json) => {
  return {
    type: GET_PERSON_EXECUTIONS_REQUEST_FAILED,
    error: json
  }
};
