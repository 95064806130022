import {
  personExecutionsRequest,
  personExecutionsRequestFailed,
  personExecutionsRequestSucceeded
} from "../action-creators/executions";
import { apiPersonExecutionsRequest } from "../api/executions";
import { getItemFromStorage } from "../../helper/storage";

const createPersonExecutionsRequest = (personId) => (dispatch) => {
  dispatch(personExecutionsRequest());
  const token = getItemFromStorage("TOKEN");
  return apiPersonExecutionsRequest(token, personId)
    .then((json) => {
      dispatch(personExecutionsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((response) => {
      dispatch(personExecutionsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getPersonExecutions = (personId) => (dispatch) =>
  dispatch(createPersonExecutionsRequest(personId));
