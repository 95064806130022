import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  translateAcquistionMethodEnum,
  translateContentFormatEnum,
  translateContentTypeEnum,
  translateExecutionFormatEnum
} from "../../../helper/tasksEnum";
import { getCommunityContentTypeMetrics, getCommunityTasksMetrics } from "../../../redux/actions/metrics";
import { getTaskFunctionality, getTasks } from "../../../redux/actions/tasks";
import Card from "../../global/Card/Card";
import CommunityMetricsDistribution from "../../global/CommunityMetricsDistribution/CommunityMetricsDistribution";
import Loading from "../../global/Loading/Loading";
import Table from "../../global/Table/Table";
import TopicSymbol from "../../global/TopicSymbol/TopicSymbol";
import Button from "../../global/form/Button/Button";
import ContributionDetails from "../ContributionDetails/ContributionDetails";
import CreateTask from "../CreateTask/CreateTask";
import "./Contributions.scss";
import CreateNotifyTask from "../CreateNotifyTask/CreateNotifyTask";

const Contributions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const contentTypeDistribution = useSelector((state) => state?.metrics?.communityContentTypeDistribution);
  const communityTasksMetrics = useSelector((state) => state?.metrics?.communityTasksMetrics);
  const tasksState = useSelector((state) => state?.tasks);
  const tasksExecutions = useSelector((state) => state?.tasksExecutions);
  const tasksFunctionality = useSelector((state) => state?.tasksFunctionality);
  const tasksList = tasksState?.data;
  const tasks = tasksList?.items;
  const tasksCount = tasksList?.totalItems;
  const [showModal, handleShowModal] = useState(false);
  const [showNewTask, setShowNewTask] = useState(false);
  const [dataNotify, setDataNotify] = useState('')
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [selectedContribution, handleSelectedContribution] = useState(null);

  useEffect(() => {
    dispatch(getCommunityContentTypeMetrics());
    dispatch(getCommunityTasksMetrics());
    dispatch(getTasks());
    dispatch(getTaskFunctionality())
  }, [dispatch]);

  const handleShowInvestmentModal = (contribution) => {
    setDataNotify(contribution);
    setShowNewTask(false);
    setShowNotifyModal(true)
  };

  const formatTimeDifference = (wasReadAt) => {
    const notificationDate = new Date(wasReadAt);
    const currentDate = new Date();
    const yearDifference = currentDate.getFullYear() - notificationDate.getFullYear();
    const monthDifference = currentDate.getMonth() - notificationDate.getMonth();
    const dayDifference = currentDate.getDate() - notificationDate.getDate();

    if (yearDifference > 0) {
      return `${yearDifference} ${yearDifference === 1 ? 'ano' : 'anos'}`;
    } else if (monthDifference > 0) {
      return `${monthDifference} ${monthDifference === 1 ? 'mês' : 'meses'}`;
    } else if (dayDifference > 0) {
      return `${dayDifference} ${dayDifference === 1 ? 'dia' : 'dias'}`;
    } else {
      return "Hoje";
    }
  };


  // Pagination Tables
  const [currentPageTaskExecutions, setCurrentPageTaskExecutions] = useState(1);
  const itemsPerPageTaskExecutions = 10;

  const buildFiltersTaskExecutions = () => {
    let filters = {};
    return filters;
  }

  const onPageTaskExecutionsChange = (newPage) => {
    if (newPage !== currentPageTaskExecutions) {
      let filters = buildFiltersTaskExecutions();
      filters.page = newPage;
      setCurrentPageTaskExecutions(newPage);
      dispatch(getTasks(filters));
    }
  };

  const totalPagesTaskExecutions = tasksState?.data?.totalPages;
  const currentPageGroupTaskExecutions = Math.ceil(currentPageTaskExecutions / itemsPerPageTaskExecutions);
  const startPageTaskExecutions = (currentPageGroupTaskExecutions - 1) * itemsPerPageTaskExecutions + 1;
  const endPageTaskExecutions = Math.min(currentPageGroupTaskExecutions * itemsPerPageTaskExecutions, totalPagesTaskExecutions);

  const pageNumbersTaskExecutions = Array.from({ length: endPageTaskExecutions - startPageTaskExecutions + 1 }, (_, i) => startPageTaskExecutions + i);

  const [currentPageFunctionality, setCurrentPageFunctionality] = useState(1);
  const itemsPerPageFunctionality = 10;

  const buildFiltersFunctionality = () => {
    let filters = {};
    return filters;
  }

  const onPageFunctionalityChange = (newPage) => {
    if (newPage !== currentPageFunctionality) {
      let filters = buildFiltersFunctionality();
      filters.page = newPage;
      setCurrentPageFunctionality(newPage);
      dispatch(getTaskFunctionality(filters));
    }
  };

  const totalPagesFunctionality = tasksFunctionality?.data?.totalPages;
  const currentPageGroupFunctionality = Math.ceil(currentPageFunctionality / itemsPerPageFunctionality);
  const startPageFunctionality = (currentPageGroupFunctionality - 1) * itemsPerPageFunctionality + 1;
  const endPageFunctionality = Math.min(currentPageGroupFunctionality * itemsPerPageFunctionality, totalPagesFunctionality);

  const pageNumbersFunctionality = Array.from({ length: endPageFunctionality - startPageFunctionality + 1 }, (_, i) => startPageFunctionality + i);
  //

  return (
    <section className="Contributions">
      <article className="descritption-text">
        <h2>Aportes</h2>
        <p className="description">
          Os aportes não são feitos com dinheiro. Toda ação realizada com
          intenção ou não de aprender pode ser registrada por cada integrante da
          comunidade.
          <span> Como cada um investe em seu desenvolvimento ? </span>
          Aulas, livros, projetos?
        </p>
      </article>

      <div className="cards-container">
        <Card title="Tipos de aportes na sua empresa">
          <CommunityMetricsDistribution community={contentTypeDistribution?.data} />
        </Card>

        <div className="contributions-cards">
          <Card title="Aporte Mais popular">
            <div className="most-popular-contribution">
              <p className="contribution-title">
                {communityTasksMetrics?.data?.taskWithMoreExecutions?.study_task?.title}
              </p>
              <p className="contribution-register-count">
                <span className="count">
                  {communityTasksMetrics?.data?.taskWithMoreExecutions?.executionCount}
                </span>
                <br />
                {communityTasksMetrics?.data?.taskWithMoreExecutions?.executionCount === 1 ? "registrado" : "registrados"}
              </p>
            </div>
          </Card>
          <Card title="Aporte Menos popular">
            <div className="less-popular-contribution">
              <p className="contribution-title">
                {communityTasksMetrics?.data?.taskWithLessExecutions?.study_task?.title}
              </p>
              <p className="contribution-register-count">
                <span className="count">
                  {communityTasksMetrics?.data?.taskWithLessExecutions?.executionCount}
                </span>
                <br />
                {communityTasksMetrics?.data?.taskWithLessExecutions?.executionCount === 1 ? "registrado" : "registrados"}
              </p>
            </div>
          </Card>
        </div>
      </div>

      <div className="contributions-list functionality">
        <div class="button-create-investment functionality">
          <p className="title">Tarefas da empresa</p>
          <Button onClick={() => setShowNewTask(!showNewTask)}
          >
            Criar nova tarefa
          </Button>
        </div>

        <Table
          className="contributions-table"
          headers={[
            "INVESTIMENTO",
            "NOME DO APORTE",
            "FORMATO",
            "MEIO",
            "MODALIDADE",
            "CUSTO",
            "CRIADO EM",
          ]}
        >
          {tasksFunctionality?.data?.items?.map((task) => (
            <tr key={task?.id}>
              <td>
                <TopicSymbol symbol={task?.topic?.symbol} />
              </td>
              <td>{task?.title}</td>
              <td>{translateContentTypeEnum(task?.contentType)}</td>
              <td>{translateContentFormatEnum(task?.contentFormat)}</td>
              <td>{translateExecutionFormatEnum(task?.taskFormat)}</td>
              <td>{translateAcquistionMethodEnum(task?.contentAcquisition)}</td>
              <td>{formatTimeDifference(task?.createdAt)}</td>
            </tr>
          ))}
        </Table>
        <div className='container-buttons'>
          <button
            onClick={() => onPageTaskExecutionsChange(Math.max(currentPageFunctionality - itemsPerPageFunctionality, 1))}
            disabled={currentPageFunctionality <= itemsPerPageFunctionality}
          >
            Anterior
          </button>

          {pageNumbersFunctionality.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => onPageFunctionalityChange(pageNumber)}
              className={`button-pagination ${pageNumber === currentPageFunctionality ? 'active' : ''}`}
            >
              {pageNumber}
            </button>
          ))}

          <button
            onClick={() => onPageTaskExecutionsChange(Math.min(currentPageFunctionality + itemsPerPageFunctionality, totalPagesFunctionality))}
            disabled={currentPageGroupFunctionality >= totalPagesFunctionality}
          >
            Próximo
          </button>
        </div>
      </div>

      <div className="contributions-list">
        <div class="button-create-investment">
          <p className="title">Aportes</p>
          <Button onClick={() => setShowNewTask(!showNewTask)}
          >
            Criar novo aporte
          </Button>
        </div>

        <Table
          className="contributions-table"
          headers={[
            "INVESTIMENTO",
            "NOME DO APORTE",
            "FORMATO",
            "MEIO",
            "MODALIDADE",
            "CUSTO",
            "POPULARIDADE",
            `${tasksCount ?? '0'} ${tasksCount === 1 ? 'Aporte' : 'Aportes'}`,
          ]}
        >
          {tasks?.map((task) => (
            <tr key={task?.id}>
              <td>
                <TopicSymbol symbol={task?.topic?.symbol} />
              </td>
              <td>{task?.title}</td>
              <td>{translateContentTypeEnum(task?.contentType)}</td>
              <td>{translateContentFormatEnum(task?.contentFormat)}</td>
              <td>{translateExecutionFormatEnum(task?.taskFormat)}</td>
              <td>{translateAcquistionMethodEnum(task?.contentAcquisition)}</td>
              <td>
                <p>
                  {task?.metrics?.completeExecutions ?? "0"} &nbsp;
                  {task?.metrics?.completeExecutions === 1
                    ? "realizado"
                    : "realizados"}
                </p>
                <p>
                  {task?.metrics?.activeExecutions ?? '0'} &nbsp;
                  {task?.metrics?.activeExecutions === 1 ? 'programado' : 'programados'}
                </p>
              </td>
              <td>
                <button
                  onClick={() => {
                    handleShowModal(true);
                    handleSelectedContribution(task);
                  }}
                >
                  Ver Detalhes
                </button>
              </td>
            </tr>
          ))}
        </Table>
        <div className='container-buttons'>
          <button
            onClick={() => onPageTaskExecutionsChange(Math.max(currentPageTaskExecutions - itemsPerPageTaskExecutions, 1))}
            disabled={currentPageTaskExecutions <= itemsPerPageTaskExecutions}
          >
            Anterior
          </button>

          {pageNumbersTaskExecutions.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => onPageTaskExecutionsChange(pageNumber)}
              className={`button-pagination ${pageNumber === currentPageTaskExecutions ? 'active' : ''}`}
            >
              {pageNumber}
            </button>
          ))}

          <button
            onClick={() => onPageTaskExecutionsChange(Math.min(currentPageTaskExecutions + itemsPerPageTaskExecutions, totalPagesTaskExecutions))}
            disabled={currentPageGroupTaskExecutions >= totalPagesTaskExecutions}
          >
            Próximo
          </button>
        </div>
      </div>
      {showModal && (
        <ContributionDetails
          contribution={selectedContribution}
          handleClose={() => handleShowModal(false)}
        />
      )}

      {showNewTask && (
        <CreateTask
          showModal={showNewTask}
          handleShowInvestmentModal={handleShowInvestmentModal}
          handleShowModal={() => setShowNewTask(false)}
        />
      )}

      {showNotifyModal && (
        <CreateNotifyTask
          showNotifyModal
          dataNotify={dataNotify}
          handleShowModal={() => setShowNotifyModal(false)}
        />
      )}

      <Loading
        visible={
          contentTypeDistribution?.isFetching ||
          communityTasksMetrics?.isFetching ||
          tasksState?.isFetching ||
          tasksExecutions?.isFetching
        }
      />
    </section>
  );
};

export default Contributions;
