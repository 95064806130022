import {
  ACCESSES_REQUEST,
  ACCESSES_REQUEST_FAILED,
  ACCESSES_REQUEST_SUCCEEDED
} from "../constants/accesses";

export const accesses = (
  state = {
    isFetching: false,
    data: [],
    error: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case ACCESSES_REQUEST:
      newState.isFetching = true;
      return newState;

    case ACCESSES_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case ACCESSES_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
