import React, { useEffect } from 'react';
import "./ModalContributions.scss";
import Table from "../../global/Table/Table";
import ModalContributionsTableRow from "./ModalContributionsTableRow/ModalContributionsTableRow";
import { useDispatch } from 'react-redux';
import { getPersonExecutions } from "../../../redux/actions/executions";
import { useSelector } from 'react-redux';

const ModalContributions = ({investorId}) => {
  const dispatch = useDispatch();
  const myExecutionsData = useSelector((state) => state?.executions?.data?.items);
  
  useEffect(() => {
    dispatch(getPersonExecutions(investorId));
  }, [dispatch, investorId])

  return (
    <div className="ModalContributions">
      <div className="contributions-search">
        <div className="contributions-count">
          <h1>Aportes</h1>
          <p>{myExecutionsData && (myExecutionsData.length + " " + (myExecutionsData.length > 1 ? "Aportes": "Aporte"))}</p>
        </div>
      </div>
      <Table
        className="ModalContributionsTableRow"
        headers={[
          "Investimento",
          "Nome do Aporte",
          "Formato",
          "Meio",
          "Modalidade",
          "Custo",
          "Status",
          "Prazo",
        ]}
      >
        {myExecutionsData?.map((execution, key) => (
          <ModalContributionsTableRow execution={execution} key={key}/>
        ))}
      </Table>
    </div>
  );
};

export default ModalContributions;
