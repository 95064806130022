export const MANAGERS_REQUEST = "MANAGERS_REQUEST";
export const MANAGERS_REQUEST_SUCCEEDED = "MANAGERS_REQUEST_SUCCEEDED";
export const MANAGERS_REQUEST_FAILED = "MANAGERS_REQUEST_FAILED";

export const DELETE_MANAGER_REQUEST = "DELETE_MANAGER_REQUEST";
export const DELETE_MANAGER_REQUEST_SUCCEEDED = "DELETE_MANAGER_REQUEST_SUCCEEDED";
export const DELETE_MANAGER_REQUEST_FAILED = "DELETE_MANAGER_REQUEST_FAILED";

export const UPDATE_MANAGER_REQUEST = "UPDATE_MANAGER_REQUEST";
export const UPDATE_MANAGER_REQUEST_SUCCEEDED = "UPDATE_MANAGER_REQUEST_SUCCEEDED";
export const UPDATE_MANAGER_REQUEST_FAILED = "UPDATE_MANAGER_REQUEST_FAILED";
