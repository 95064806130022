import React, { useEffect } from 'react';
import "./SelectCompany.scss";
import CardSelectCompany from "../CardSelectCompany/CardSelectCompany";
import { useDispatch, useSelector } from "react-redux";
import { getAccesses } from "../../../redux/actions/accesses";
import { useHistory } from "react-router-dom";
import Logo from "../../global/Logo/Logo";
import Loading from '../../global/Loading/Loading';
import { getDemo } from '../../../redux/actions/demo';

const SelectCompany = () => {
  const accesses = useSelector(state => state.accesses);
  const demo = useSelector(state => state.demo);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    !demo.isDemoMode ? dispatch(getAccesses()) : dispatch(getDemo());
  }, [dispatch, demo.isDemoMode]);

  return (
    <div className="SelectCompany">
      <Logo />
      <div className="container-selectcompany" style={{ height: accesses.data.length > 2 ? "100%" : "" }}>
        <div className="title-selectcompany">
          <h1>Bem vindo(a) ao E2W!</h1>
          <h2>Clique abaixo para adicionar uma nova empresa ou selecione.</h2>
        </div>
        <div className="cards-selectcompany">
          {demo.isDemoMode ? (demo.data?.accesses?.map((item, key) =>
            <CardSelectCompany logo={item.company.icon} key={key} id={item.company.id}
              name={item.company.name} />
          )) : (accesses && accesses.data.map((item, key) =>
            <CardSelectCompany logo={item.company.icon} key={key} id={item.company.id}
              name={item.company.name} />
          ))}

          <div className="add-selectcompany" onClick={() => history.push("/empresa/cadastro")}>
            <div className="textadd-selectcompany">
              <div className="span-selectorcompany">
                <span />
                <span />
              </div>
              <p>CRIAR NOVA</p>
            </div>
          </div>
        </div>
      </div>
      <Loading visible={accesses.isFetching || demo.isFetching} />
    </div>
  );
};

export default SelectCompany;
