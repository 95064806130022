import {engagementRequest, engagementRequestFailed, engagementRequestSucceeded} from "../action-creators/engagement";
import {apiEngagement} from "../api/engagement";
import { getItemFromStorage } from "../../helper/storage";

const createEngagementRequest = () => dispatch => {
  engagementRequest();
  const company_id = getItemFromStorage( "COMPANY_ID" );
  const token = getItemFromStorage( "TOKEN" );

  return apiEngagement(token, company_id)
    .then((json) => {
      dispatch(engagementRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(engagementRequestFailed(error));
      return Promise.reject();
    });
};

export const getEngagement = () => dispatch => dispatch(createEngagementRequest());
