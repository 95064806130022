const bloomsTaxonomy = [
  {
    action: "lembrar",
    minimumGrade: "0.5 a 2.5",
    title: "O que significa <strong>lembrar</strong> uma competência?",
    explanation:
      "Reconhecer e lembrar de fatos, termos, conceitos básicos ou perguntas sem necessariamente entender" +
      " o que elas querem dizer.",
    synonyms: "Recordar, relembrar, memorizar"
  },
  {
    action: "compreender",
    minimumGrade: "2.5 a 5",
    title: "O que significa <strong>compreender</strong> uma competência?",
    explanation:
      "Demonstrar um entendimento de fatos e ideias, organizando, comparando, traduzindo, interpretando, descrevendo ideias principais.",
    synonyms:
      "Esquematizar, relacionar, explicar, demonstrar, parafrasear, associar."
  },
  {
    action: "aplicar",
    title: "O que significa <strong>aplicar</strong> uma competência?",
    minimumGrade: "5 a 6.5",
    explanation:
      "Usar o conhecimento adquirido em situações novas aplicando o que aprendeu.",
    synonyms: "Empregar, aplicar, utilizar, manipular"
  },
  {
    action: "analisar",
    minimumGrade: "6.5 a 8",
    title: "O que significa <strong>analisar</strong> uma competência?",
    explanation:
      "Examinar e dividir a informação em partes, além de determinar como essas partes se relacionam; identificar" +
      " motivos ou causas, fazer inferências e encontrar evidências para apoiar generalizações.",
    synonyms:
      "Explorar, aprofundar, analisar, considerar, pesar, ponderar, refletir"
  },
  {
    action: "criar",
    minimumGrade: "8 a 9",
    title: "O que significa <strong>criar</strong> uma competência?",
    explanation:
      "Construir uma estrutura ou caminho de elementos diversos. Também faz referência ao ato de juntar partes para" +
      " formar um todo.",
    synonyms:
      "Gerar, conceber, formar, originar, produzir, fazer, fabricar, elaborar."
  },
  {
    action: "avaliar",
    minimumGrade: "9 a 10",
    title: "O que significa <strong>avaliar</strong> uma competência?",
    explanation:
      "Apresentar e defender opiniões, fazendo julgamento de valor sobre a informação, a validade das ideias ou" +
      " qualidade do trabalho baseado em algum critério.",
    synonyms:
      "Argumentar, discorrer, discursar, tratar, comentar, expor, exibir, expressar, evidenciar, dissertar"
  }
];

export default bloomsTaxonomy;
