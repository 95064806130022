import React from "react";
import "./BackButton.scss";
import {useHistory} from "react-router";
import {ReactComponent as Arrow} from "../../../assets/images/arrow.svg";

const BackButton = ({inverted, action}) => {
  const history = useHistory();
  const goBack = () => history.goBack();

  return (
    <button
      className={`BackButton ${inverted ? "inverted" : ""}`}
      onClick={action ? action : goBack}
    >
      <Arrow/>
      Voltar
    </button>
  );
};

export default BackButton;
