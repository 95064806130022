import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiManagersRequest = (token, company_id, filters) => {
  return fetch(urlFilters(`${baseUrl}/company/${company_id}/managers`),
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiDeleteManagerRequest = ( token, company_id, manager_id ) => {
  return fetch( `${ baseUrl }/company/${ company_id }/manager/${ manager_id }`,
    {
      method: "DELETE",
      headers: buildHeaders( token )
    } )
    .then( ( res ) => {
      if ( res.status === 204 ) {
        return Promise.resolve();
      }
    } )
    .catch( ( error ) => Promise.reject( error ) );
};

export const apiUpdateManagerRequest = (token, company_id, manager_id, payload) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/manager/${manager_id}`, {
    method: "PUT",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow).catch(err => Promise.reject(err));
}
