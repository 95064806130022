import React from 'react';
import "./EditManager.scss";
import BackButton from "../../global/BackButton/BackButton";

const EditManager = ({managerName, managerEmail}) => {
  return (
    <div className="EditManager">
      <h1>Editar gestor</h1>
      <BackButton />
      <div>
        <h2 className="title-input">Você está editando</h2>
        <h3>Nome</h3>
        <p>{managerName}</p>
        <h3>E-mail</h3>
        <p>{managerEmail}</p>
      </div>
    </div>
  );
}

export default EditManager;
