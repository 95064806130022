import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiRecoveryPasswordRequest = (payload) => {
  return fetch(`${baseUrl}/person/password/recovery`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload),
  })
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
};

export const apiUpdatePasswordWithCodeRequest = (code, payload) => {
  return fetch(`${baseUrl}/person/password/recovery/${code}`, {
    method: "PUT",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  })
    .then(getResponseOrThrow)
    .catch(error => Promise.reject(error));
}
