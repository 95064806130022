import React from 'react';
import {
  translateAcquistionMethodEnum, translateContentFormatEnum,
  translateContentTypeEnum,
  translateExecutionFormatEnum
} from '../../../helper/tasksEnum';
import Table from '../../global/Table/Table';
import TopicSymbol from '../../global/TopicSymbol/TopicSymbol';
import './ContributionsInvestment.scss';

const ContributionsInvestment = ({ investmentDetails }) => {

  return (
    <div className='ContributionsInvestment'>
      <div className="header-investor">
        <p>Aportes</p>
        <p>{!Array.isArray(investmentDetails?.tasks?.items) ? 0 : investmentDetails?.tasks?.items?.length} ações registradas pelos integrantes da Mastertech</p>
      </div>
      <div className="contributions-list">
        <Table
          className="contributions-table"
          headers={[
            "INVESTIMENTO",
            "NOME DO APORTE",
            "FORMATO",
            "MEIO",
            "MODALIDADE",
            "CUSTO",
          ]}
        >
          {investmentDetails?.tasks?.items?.length <= 0 && <p style={{ textAlign: "center" }}>Nenhum aporte encontrado</p>}
          {investmentDetails?.tasks?.items?.length > 0 && investmentDetails?.tasks?.items?.map((task) => (
            <tr key={task?.id}>
              <td>
                <TopicSymbol symbol={task?.topic?.symbol} />
              </td>
              <td>{task?.title}</td>
              <td>{translateContentTypeEnum(task?.contentType)}</td>
              <td>{translateContentFormatEnum(task?.contentFormat)}</td>
              <td>{translateExecutionFormatEnum(task?.taskFormat)}</td>
              <td>{translateAcquistionMethodEnum(task?.contentAcquisition)}</td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  )
}

export default ContributionsInvestment
