export const TASKS_REQUEST = "TASKS_REQUEST";
export const TASKS_REQUEST_SUCCEEDED = "TASKS_REQUEST_SUCCEEDED";
export const TASKS_REQUEST_FAILED = "TASKS_REQUEST_FAILED";

export const GET_TASKS_EXECUTIONS_REQUEST = "GET_TASKS_EXECUTIONS_REQUEST";
export const GET_TASKS_EXECUTIONS_REQUEST_SUCCEEDED = "GET_TASKS_EXECUTIONS_REQUEST_SUCCEEDED";
export const GET_TASKS_EXECUTIONS_REQUEST_FAILED = "GET_TASKS_EXECUTIONS_REQUEST_FAILED";

export const TASK_CREATE_REQUEST = "TASK_CREATE_REQUEST";
export const TASK_CREATE_REQUEST_SUCCEEDED = "TASK_CREATE_REQUEST_SUCCEEDED";
export const TASK_CREATE_REQUEST_FAILED = "TASK_CREATE_REQUEST_FAILED";

export const GET_TASKS_FUNCTIONALITY_REQUEST = "GET_TASKS_FUNCTIONALITY_REQUEST";
export const GET_TASKS_FUNCTIONALITY_REQUEST_SUCCEEDED = "GET_TASKS_FUNCTIONALITY_REQUEST_SUCCEEDED";
export const GET_TASKS_FUNCTIONALITY_REQUEST_FAILED = "GET_TASKS_FUNCTIONALITY_REQUEST_FAILED";
