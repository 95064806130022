import React, {useState} from 'react';
import "./AddManagers.scss";
import Input from "../../global/form/Input/Input";
import Button from "../../global/form/Button/Button";
import BackButton from "../../global/BackButton/BackButton";
import { isValidEmail } from '../../../helper/validationHelper';

const AddManagers = ({managers, setManagers, nextStep, previousStep, filePath, communityName}) => {
  const [feedback, setFeedback] = useState(false);
  const maxManagers = 5;

  const addManager = () => {
    const managersList = [...managers, ""];
    setManagers(managersList);
  };

  const handleOnchange = (value, index) => {
    const managersList = [...managers];
    managersList[index] = value;
    setManagers(managersList);
  }

  const verifyEmailList = () => {
    let flag = true;

    for (let manager of managers) {
      if (!isValidEmail(manager)) {
        flag = false;
      }
    }

    if (flag) {
      nextStep();
    } else {
      setFeedback(true)
    }
  };

  return (
    <div className="AddManagers">
      <div className="top-info">
        <BackButton action={previousStep} />
        <div>
          <img src={filePath} alt={`logo selecionado da comunidade ${communityName}`}/>
          <h3>{communityName}</h3>
        </div>
      </div>
      <h2>Adicionar administradores</h2>
      <p>Por favor, adicione a base de administradores <br/>
        do sistema. Eles terão acesso a todas <br/>
        as funcionalidades administrativas.</p>
      <p className={`feedback-message ${feedback ? "active" : ""}`}>Ocorreu um erro, por favor verifique os e-mails
        registrados.</p>
        <label htmlFor="email">E-mail do(a) administrador(a)</label>
      {managers.map((manager, index) =>
        <Input key={index} type="email" value={managers[index]} updateState={(value) => handleOnchange(value, index)}
               placeholder="ex: admin@mastertech.com.br"
               displayErrorMessage={index !== (managers.length - 1) && managers[index].trim() === ""}
               errorMessage="e-mail inválido"/>
      )}
      {managers.length < maxManagers && (
        <button className="add-another-manager" onClick={addManager}><span className='ion--add-sharp'/><p>Adicionar administrador(a)</p>
        </button>)}
      <p className='sub one'>Não se preocupe, você poderá adicionar novos integrantes e administradores depois.</p>
      <p className='sub'>Todos nessa lista receberão um convite via e-mail para participar da comunidade.</p>
      <Button onClick={verifyEmailList} bottom>Criar comunidade <span className="ph--arrow-right-bold"/></Button>
    </div>
  );
};

export default AddManagers;
