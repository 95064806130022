import { 
  GET_COMPANY_STRUCTURE_REQUEST, 
  GET_COMPANY_STRUCTURE_REQUEST_SUCCEEDED,
  GET_COMPANY_STRUCTURE_REQUEST_FAILED 
} from "../constants/structure";

export const structureRequest = () => {
  return {
    type: GET_COMPANY_STRUCTURE_REQUEST
  }
};

export const structureRequestSucceeded = (payload) => {
  return {
    type: GET_COMPANY_STRUCTURE_REQUEST_SUCCEEDED,
    data: payload
  };
};

export const structureRequestFailed = (json) => {
  return {
    type: GET_COMPANY_STRUCTURE_REQUEST_FAILED,
    error: json
  };
};
