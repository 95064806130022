import React from "react";
import PropTypes from "prop-types";
import "./InviteManagersTable.scss";
import { ReactComponent as ArrowUp } from "../../../assets/images/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/arrow-down.svg";
import { translateGestorLevel } from "../../../helper/dataHelper";

const InviteManagersTable = ({ data, onSort, sortConfig }) => {
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <table className="InviteManagersTable">
        <thead>
          <tr>
            <th>
              E-mail
              <button onClick={() => onSort('email')}>
                {getClassNamesFor('email') === 'asc' ? <ArrowUp /> : <ArrowDown />}
              </button>
            </th>
            <th>
              Squad
              <button onClick={() => onSort('units')}>
                {getClassNamesFor('units') === 'asc' ? <ArrowUp /> : <ArrowDown />}
              </button>
            </th>
            <th>
              Cargo
              <button onClick={() => onSort('level')}>
                {getClassNamesFor('level') === 'asc' ? <ArrowUp /> : <ArrowDown />}
              </button>
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((personData) => {
              return (
                <tr key={personData.id}>
                  <td>{personData?.email}</td>
                  <td>{personData.units}</td>
                  <td>{translateGestorLevel(personData.level)}</td>
                  <td><p>Pendente</p></td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td />
              <td colSpan={8}>
                Você não possui colaboradores cadastrados em sua base - para adicionar clique em enviar convite por email.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

InviteManagersTable.propTypes = {
  data: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired
};

export default InviteManagersTable;
