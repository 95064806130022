import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiEmployeesRequest = (token, company_id, filters) => {
  return authorizedFetch(urlFilters(`${baseUrl}/company/${company_id}/employees`, filters),
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiDeleteEmployee = (token, company_id, employee_id) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/employee/${employee_id}`,
    {
      method: "DELETE",
      headers: buildHeaders(token)
    })
    .then( ( res ) => {
      if ( res.status === 204 ) {
        return Promise.resolve();
      }
    } )
    .catch((error) => Promise.reject(error));
};

export const apiUpdateEmployee = (token, company_id, employee_id, payload) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/employee/${employee_id}`,
    {
      method: "PUT",
      headers: buildHeaders(token),
      body: JSON.stringify(payload)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiAddInvestorsRequest = (payload, token, company_id) => {
  let body = new FormData();
  for(let key in payload) {
    body.append(key, payload[key]);
  }
  return fetch(`${baseUrl}/company/${company_id}/employees`,
    {
      method: "PUT",
      headers: {
        "Authorization" : `Bearer ${token}`
      },
      body
    })
    .then((response) => Promise.resolve(response?.body || []))
    .catch((error) => Promise.reject(error));
};

export const apiEmployeesMetrics = (token, company_id) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/employees/metrics`, {
    method: "GET",
    headers: buildHeaders(token)
  })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
}
