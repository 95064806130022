import {
  COMPANY_REQUEST,
  COMPANY_REQUEST_FAILED,
  COMPANY_REQUEST_SUCCEEDED,
  GET_METRICS_ENGAGEMENT_REQUEST,
  GET_METRICS_ENGAGEMENT_REQUEST_SUCCEEDED,
  GET_METRICS_ENGAGEMENT_REQUEST_FAILED
} from "../constants/company";

export const companyRequest = () => {
  return {
    type: COMPANY_REQUEST
  }
};

export const companyRequestSucceeded = (payload) => {
  return {
    type: COMPANY_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const companyRequestFailed = (json) => {
  return {
    type: COMPANY_REQUEST_FAILED,
    error: json
  }
};

export const metricsEngagementMonthRequest = () => {
  return {
    type: GET_METRICS_ENGAGEMENT_REQUEST
  };
}

export const metricsEngagementMonthRequestSucceeded = (payload) => {
  return {
    type: GET_METRICS_ENGAGEMENT_REQUEST_SUCCEEDED,
    data: payload
  };
}

export const metricsEngagementMonthRequestFailed = (json) => {
  return {
    type: GET_METRICS_ENGAGEMENT_REQUEST_FAILED,
    error: json
  };
}
