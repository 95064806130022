import React from 'react';
import PropTypes from 'prop-types';
import "./TableContainer.scss";
import Button from "../../global/form/Button/Button";
import ManagersTable from "../ManagersTable/ManagersTable";
import EmployeesTable from "../EmployeesTable/EmployeesTable";
import {useParams} from "react-router-dom";

const TableContainer = ({data, setPage, hideTable}) => {
  const pages = data.totalPages;
  const {type} = useParams();

  return (
    <div className={`TableContainer ${hideTable ? "inactive" : ""}`}>
      {type === "gestores" ? <ManagersTable data={data}/> : <EmployeesTable data={data}/>}
      <div className="table-footer">
        {pages > 1 && (
          <div className="pagination">
            <Button onClick={() => {
              setPage(data.prevPage);
            }} text={"anterior"}/>
            {data.page > 1 && <Button text="1" onClick={() => setPage(1)}/>}
            {data.page > 2 && <span>...</span>}
            {data.prevPage > 1 && <Button text={data.prevPage} onClick={() => {
              setPage(data.prevPage)
            }}/>}
            <Button className="active" text={data.page} onClick={() => {
              setPage(data.page)
            }}/>
            {data.nextPage && <Button text={data.nextPage} onClick={() => {
              setPage(data.nextPage)
            }}/>}
            {data.totalPages > (data.page + 1) && <span>...</span>}
            {data.totalPages > data.page && data.totalPages > 2 && <Button text={data.totalPages} onClick={() => {
              setPage(data.totalPages)
            }}/>}
            <Button onClick={() => {
              setPage(data.nextPage)
            }} text={"próximo"}/>
          </div>)}
      </div>
    </div>
  );
};

TableContainer.propTypes = {
  data: PropTypes.array.isRequired
};

export default TableContainer;
