import {
  EMPLOYEES_METRICS_FAILED,
  EMPLOYEES_METRICS_REQUEST,
  EMPLOYEES_METRICS_SUCCEEDED,
  EMPLOYEES_REQUEST,
  EMPLOYEES_REQUEST_FAILED,
  EMPLOYEES_REQUEST_SUCCEEDED
} from "../constants/employees";

export const employees = (
  state = {
    isFetching: false,
    data: [],
    error: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case EMPLOYEES_REQUEST:
      newState.isFetching = true;
      return newState;

    case EMPLOYEES_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case EMPLOYEES_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};

export const employeesMetrics = (
  state = { 
    isFetching: false, 
    data: [], error: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case EMPLOYEES_METRICS_REQUEST:
      newState.isFetching = true;
      return newState;
    
    case EMPLOYEES_METRICS_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case EMPLOYEES_METRICS_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
}
