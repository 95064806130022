import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pillars, schoolSelect } from "../../../helper/dataHelper";
import { ignoredWords } from "../../../helper/ignoredWorks";
import { useTextField } from "../../../hooks/inputHooks";
import {
  createInvestment,
  listInvestments,
  updateInvestment,
} from "../../../redux/actions/investments";
import { getCompanyStructure } from "../../../redux/actions/structure";
import showNotification from "../../global/Notification/showNotification";
import Button from "../../global/form/Button/Button";
import MultiSelect from "../../global/form/MultiSelect/MultiSelect";
import Select from "../../global/form/Select/Select";
import "./CreateInvestment.scss";

const CreateInvestment = ({
  showModal,
  handleShowModal,
  handleNotifyModal,
  selectedContribution,
  setSeletedContribution,
  handleCloseDetails,
  handleShowInvestmentModal,
}) => {
  const dispatch = useDispatch();
  const structure = useSelector((state) => state?.structure?.data);
  const [inputError, setInputError] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [school, setSchool, validSchool] = useTextField("");
  const [tribe, setTribe] = useState([]);
  const [squad, setSquad] = useState([]);
  const [pillar, setPillar, validPillar] = useTextField("");
  const [steps, setSteps] = useState(1);
  const [listSquads, setListSquads] = useState([]);
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    dispatch(getCompanyStructure());
  }, [dispatch]);

  const transformedData =
    Array.isArray(structure) &&
    structure?.map((item) => ({
      [item.name]: item.roles.map((role) => ({ label: role, value: role })),
    }));
  const transformedObject =
    Array.isArray(transformedData) &&
    transformedData.reduce((acc, item) => {
      const title = Object.keys(item)[0];
      acc[title] = item[title];
      return acc;
    }, {});
  const titlesOnly =
    Array.isArray(structure) &&
    transformedData.map((item) => {
      const title = Object.keys(item)[0];
      return { label: title, value: title };
    });

  useEffect(() => {
    if (selectedContribution) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        title: selectedContribution?.name,
      }));
      setInputs((prevInputs) => ({
        ...prevInputs,
        description: selectedContribution?.description,
      }));
      setSelectedOption(selectedContribution?.skillKind);
      setSchool(selectedContribution?.school);
      setPillar(selectedContribution?.pillar);
      const transformedUnits = selectedContribution.units.map((unit) => ({
        label: unit,
        value: unit,
      }));
      setTribe(transformedUnits);
      const transformedRoles = selectedContribution.roles.map((role) => ({
        label: role,
        value: role,
      }));
      setSquad(transformedRoles);
    }
  }, [selectedContribution]);

  const generateSelectOptions = (arrayValues, selectedValue) =>
    arrayValues.map((data, key) => (
      <option
        value={data.value}
        key={key}
        defaultChecked={selectedValue === data.value}
      >
        {data.label}
      </option>
    ));

  const transformWords = (input) => {
    const words = input.split(" ");
    let result = "";

    if (words.length === 1) {
      result = words[0].slice(0, Math.min(4, words[0].length));
    } else {
      result = words
        .map((word) => word.toLowerCase())
        .filter((word) => !ignoredWords.includes(word))
        .map((word) => word[0])
        .join("");
    }

    const randomSuffix = Math.floor(Math.random() * 10);
    result = result.toUpperCase() + randomSuffix;

    return result;
  };

  const nextStep = () => {
    setInputError(false);
    if (
      !inputs.title ||
      !inputs.description ||
      !validPillar ||
      !validSchool ||
      !selectedOption
    ) {
      setInputError(true);
    } else {
      setSteps(2);
    }
  };

  const submitInvestment = () => {
    setInputError(false);
    if (
      !inputs.title ||
      !inputs.description ||
      !validPillar ||
      !validSchool ||
      !selectedOption
    ) {
      setInputError(true);
    } else {
      const payload = {
        description: inputs.description,
        name: inputs.title,
        pillar,
        school,
        skillKind: selectedOption,
        symbol: transformWords(inputs.title),
        roles: squad.map((item) => item.value),
        units: tribe.map((item) => item.value),
      };

      dispatch(createInvestment(payload)).then((json) => {
        handleShowInvestmentModal(json);
      });
    }
  };

  const patchInvestment = async () => {
    setInputError(false);
    if (
      !inputs.title ||
      !inputs.description ||
      !validPillar ||
      !validSchool ||
      !selectedOption
    ) {
      setInputError(true);
    } else {
      const payload = {
        description: inputs.description,
        name: inputs.title,
        pillar,
        school,
        skillKind: selectedOption,
        symbol: selectedContribution?.symbol,
        roles: squad.map((item) => item.value),
        units: tribe.map((item) => item.value),
      };
      dispatch(updateInvestment(selectedContribution?.id, payload)).then(() => {
        handleShowModal();
        handleCloseDetails();
        dispatch(listInvestments());
        showNotification("Investimento editado com sucesso!", 5000);
      });
    }
  };

  useEffect(() => {
    const mapResults = () => {
      const tempResults = [];
      tribe.forEach((item) => {
        const value = item.value;
        if (transformedObject.hasOwnProperty(value)) {
          tempResults.push(...transformedObject[value]);
        }
      });
      setListSquads(tempResults);
    };

    mapResults();
  }, [tribe]);

  return (
    <div className={`CreateInvestment ${showModal ? "active" : ""}`}>
      <div className="container-create">
        <div className="bg" />
        <div className="close-modal">
          <span role="button" onClick={() => handleShowModal()}>
            Fechar &nbsp; ✖
          </span>
        </div>
        <p className="title-create">Adicionar nova competência</p>
        <div className="form-create">
          {steps === 1 ? (
            <>
              <div className="box-input">
                <label for="title">Título:</label>
                <input
                  onChange={handleChange}
                  id="title"
                  name="title"
                  type="text"
                  value={inputs.title}
                />
              </div>
              <div className="box-input">
                <label>Código:</label>
                <p className="generate-code" style={{ fontStyle: "italic" }}>
                  {selectedContribution
                    ? selectedContribution.symbol
                    : "(será gerado automaticamente)"}
                </p>
              </div>
              <div className="box-input description">
                <label for="description">Descrição:</label>
                <textarea
                  onChange={handleChange}
                  id="description"
                  type="text"
                  value={inputs.description}
                  name="description"
                  rows="8"
                  cols="70"
                ></textarea>
              </div>
              <div className="box-checks">
                <p className="title-checks">Tipo:</p>
                <div className="container-checks">
                  <label>
                    <input
                      type="radio"
                      value="HARD"
                      checked={selectedOption === "HARD"}
                      onChange={() => handleOptionChange("HARD")}
                    />
                    Hard skill
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="SOFT"
                      checked={selectedOption === "SOFT"}
                      onChange={() => handleOptionChange("SOFT")}
                    />
                    Soft Skill
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="BOTH"
                      checked={selectedOption === "BOTH"}
                      onChange={() => handleOptionChange("BOTH")}
                    />
                    Ambos
                  </label>
                </div>
              </div>
              <div className="box-input">
                <Select
                  label="Escola:"
                  updateState={setSchool}
                  displayError={false}
                  value={school}
                >
                  <option value="" disabled>
                    Selecione uma escola
                  </option>
                  {generateSelectOptions(schoolSelect, school)}
                </Select>
              </div>
              <div className="box-input">
                <Select
                  label="Pillar:"
                  updateState={setPillar}
                  displayError={false}
                  value={pillar}
                >
                  <option value="" disabled>
                    Selecione um pillar
                  </option>
                  {school && generateSelectOptions(pillars[school], school)}
                </Select>
              </div>
            </>
          ) : (
            <div className="box-input select">
              <label htmlFor="">Obrigatório para:</label>
              <div className="multi-select">
                <MultiSelect
                  title="Selecione"
                  label="Selecione as tribos:"
                  onSelectChange={setTribe}
                  values={tribe}
                  options={titlesOnly}
                />
                <MultiSelect
                  title="Selecione"
                  label="Selecione os squads:"
                  options={listSquads}
                  onSelectChange={setSquad}
                  values={squad}
                />
              </div>
            </div>
          )}
          <div className="container-button">
            <p className={`message-error ${inputError ? "active" : ""} `}>
              Preencha todos os campos!
            </p>
            {steps === 1 ? (
              <Button
                onClick={() => {
                  nextStep();
                }}
              >
                AVANÇAR
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setSteps(1);
                  }}
                >
                  VOLTAR
                </Button>
                <Button
                  onClick={() => {
                    selectedContribution
                      ? patchInvestment()
                      : submitInvestment();
                    // handleNotifyModal()
                  }}
                >
                  {selectedContribution ? "EDITAR" : "CONFIRMAR"}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInvestment;
