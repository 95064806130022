import React, { useState } from "react";
import "./Question.scss";

const Question = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <details className="Question">
      <summary onClick={handleClick}>
        {item?.question}
        <span>▼</span>
      </summary>
      <p
        style={{
          display: open ? "flex" : "none"
        }}
      >
        {item?.answer}
      </p>
    </details>
  );
};

export default Question;
