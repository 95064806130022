import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiCreateInvestmentRequest = async (token, company_id, payload) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/company/${company_id}/topic`,
      {
        method: "POST",
        headers: buildHeaders(token),
        body: JSON.stringify(payload)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiListInvestmentsRequest = async (token, company_id, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/company/${company_id}/topics`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiUpdateInvestmentRequest = async (token, company_id, topicId, payload) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/company/${company_id}/topic/${topicId}`,
      {
        method: "PATCH",
        headers: buildHeaders(token),
        body: JSON.stringify(payload)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiInvestmentProgression = async (token, company_id, topicId) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/company/${company_id}/metrics/topic/${topicId}/progression`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};

export const apiInvestmentInvestors = async (token, company_id, topicId) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/company/${company_id}/topic/${topicId}/employees`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};

export const apiInvestmentTasks = async (token, company_id, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/company/${company_id}/tasks`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};
