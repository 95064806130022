import React, { useState } from "react";
import TopicSymbol from "../../global/TopicSymbol/TopicSymbol";
import TabButton from "../../global/TabButton/TabButton";
import ContributionSummary from "../ContributionSummary/ContributionSummary";
import ContributionsCompleteExecutions from "../ContributionsCompleteExecutions/ContributionsCompleteExecutions";
import ContributionsActiveExecutions from "../ContributionsActiveExecutions/ContributionsActiveExecutions";
import "./ContributionDetails.scss";

const ContributionDetails = ({ contribution, handleClose }) => {
  const [activeContent, setActiveContent] = useState("summary");
  return (
    <div className="ContributionDetails">
      <div className="header">
        <div className="contribution-container">
          <p>Aporte</p>
          <div className="contribution-info">
            <p>
              {contribution.title}
              <TopicSymbol symbol={contribution.topic.symbol} />
            </p>
          </div>
          <div className="tabs">
            <TabButton
              active={activeContent === "summary"}
              className="first-child"
              onClick={() => setActiveContent("summary")}
            >
              Resumo
            </TabButton>
            <TabButton
              active={activeContent === "completeExecutions"}
              amount={contribution.metrics.completeExecutions}
              onClick={() => setActiveContent("completeExecutions")}
            >
              Realizados
            </TabButton>
            <TabButton
              active={activeContent === "activeExecutions"}
              amount={contribution.metrics.activeExecutions}
              onClick={() => setActiveContent("activeExecutions")}
            >
              Programados
            </TabButton>
          </div>
        </div>
        <button onClick={() => handleClose()}>Fechar &nbsp; ✖</button>
      </div>
      <section>
        {activeContent === "summary" && (
          <ContributionSummary contribution={contribution} />
        )}
        {activeContent === "completeExecutions" && (
          <ContributionsCompleteExecutions contribution={contribution} />
        )}
        {activeContent === "activeExecutions" && (
          <ContributionsActiveExecutions contribution={contribution} />
        )}
      </section>
    </div>
  );
};

export default ContributionDetails;
