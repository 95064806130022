import React from 'react';
import "./CommunityRegisterFinished.scss";
import Button from "../../global/form/Button/Button";

const CommunityRegisterFinished = ({ nextStep }) => {
  return (
    <div className="CommunityRegisterFinished">
      <div className="check">
      <span class="material-symbols-light--check"></span>
      </div>
      <h2>A configuração foi finalizada com êxito!</h2>
      <p>Todos os e-mails indicados receberão um <br/> convite para acessar o painel administrativo.</p>
      <Button onClick={nextStep} bottom>acessar plataforma <span class="ph--arrow-right-bold"/></Button>
    </div>
  );
};

export default CommunityRegisterFinished;
