import { setDemoDataRequest, setDemoDataRequestSucceeded, setDemoMode } from "../action-creators/demo";
import { demo } from "../../constants/demo";

const createDemoRequest = () => dispatch => {
  dispatch(setDemoDataRequest())
  setTimeout( () => dispatch( setDemoDataRequestSucceeded(demo) ), 1500 );
  return Promise.resolve();
};

export const getDemo = () => dispatch =>
  dispatch( createDemoRequest() );

export const setDemoModeOn = () => dispatch => {
  dispatch( setDemoMode() );
}
