import React from "react";
import "./Breadcrumb.scss";

const Breadcrumb = ({crumbs, inverted}) => {
  return (
    <p className={`Breadcrumb ${inverted ? "inverted" : ""}`}>
      {crumbs.map((crumb, index) => `${index !== 0 ? " / " : ""}${crumb}`)}
    </p>
  );
};

export default Breadcrumb;
