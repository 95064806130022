import React, { useState } from "react";
import PropTypes from "prop-types";
import "./EmployeesTable.scss";
import { ReactComponent as EditIcon } from "../../../assets/images/access-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/access-delete.svg";
import DeletePopup from "../../global/DeletePopup/DeletePopup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteEmployee, getEmployees } from "../../../redux/actions/employees";

const EmployeesTable = ({ data }) => {
  const [popUp, setPopUp] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const openPopUp = (name, id) => {
    setEmployeeName(name);
    setEmployeeId(id);
    setPopUp(true);
  };

  const deleteEmployeeClick = () => {
    dispatch(deleteEmployee(employeeId)).then(() => {
      setPopUp(false);
      dispatch(getEmployees());
    });
  };

  return (
    <>
      <table className="EmployeesTable">
        <thead>
          <tr>
            <th>Nome</th>
            <th />
            <th>E-mail</th>
            <th>Departamento</th>
            <th>Cargo e função</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.items?.length > 0 ? (
            data.items.map((personData) => {
              return (
                <tr>
                  <td>{personData && personData.person.name}</td>
                  <td></td>
                  <td>{personData && personData.person.email}</td>
                  <td>{personData && `#${personData.unit}`}</td>
                  <td>{personData && `#${personData.role}`}</td>
                  <td>
                    <p
                      onClick={() => {
                        history.push(`/editar/colaborador/id/${personData.id}`);
                      }}
                    >
                      editar <EditIcon />
                    </p>
                  </td>
                  <td
                    onClick={() => {
                      openPopUp(personData.person.name, personData.id);
                    }}
                  >
                    <DeleteIcon />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td />
              <td colSpan={8}>
                Você não possui colaboradores cadastrados em sua base - para
                adicionar clique em enviar convite por email.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <DeletePopup
        name={employeeName}
        active={popUp}
        deleteClick={deleteEmployeeClick}
        cancelClick={() => {
          setPopUp(false);
        }}
      />
    </>
  );
};

EmployeesTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default EmployeesTable;
