import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTasksExecutions } from "../../../redux/actions/tasks";
import { fullDate } from "../../../helper/dateHelper"
import Table from "../../global/Table/Table";
import "./ContributionsCompleteExecutions.scss";

const ContributionsCompleteExecutions = ({ contribution }) => {
  const dispatch = useDispatch();
  const tasksCompleted = useSelector((state) => state?.tasksExecutions?.data?.items);

  useEffect(() => {
    dispatch(getTasksExecutions(contribution.id, { status: "COMPLETE" }));
  }, [contribution.id, dispatch]);

  return (
    <div className="ContributionsCompleteExecutions">
      <div className="contributions-info">
        <div className="complete-executions-count">
          <p>Investidores</p>
          <p>
            {contribution.metrics.completeExecutions} integrantes concluíram
            este aporte
          </p>
        </div>
      </div>
      <div className="investors-list">
        <Table
          className="contributions-table"
          headers={[
            "INVESTIDOR",
            "HORAS DESTINADAS",
            "DATA DE CONCLUSÃO",
            "RECOMENDAÇÃO",
          ]}
        >
          {tasksCompleted?.map((task) => (
            <tr key={task?.id}>
              <td>{task?.person?.name || "-"}</td>
              <td>{task?.timeItTook || "-"}</td>
              <td>
                {task?.deadline ? fullDate(task.deadline): "-"}
              </td>
              <td>{task?.nps !== null || "-"}</td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default ContributionsCompleteExecutions;
