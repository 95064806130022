import {
  ROLES_REQUEST,
  ROLES_REQUEST_FAILED,
  ROLES_REQUEST_SUCCEEDED
} from "../constants/roles";

export const roles = (
  state = {
    isFetching: false,
    data: {roles:[]},
    error: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case ROLES_REQUEST:
      newState.isFetching = true;
      return newState;

    case ROLES_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case ROLES_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
