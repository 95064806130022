import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Departments.scss";
import Checkbox from "../../global/form/Checkbox/Checkbox";
import {getCompanyStructure} from "../../../redux/actions/structure";
import Input from "../../global/form/Input/Input"
import Button from "../../global/form/Button/Button";

const Departments = ({ department, setDepartment }) => {
  const dispatch = useDispatch();
  const structure = useSelector((state) => state?.structure?.data);
  const [units, setUnits] = useState([]);
  const [newDepartment, setNewDepartment] = useState("");
  
  useEffect(() => {
    dispatch(getCompanyStructure());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(structure)) {
      setUnits(structure?.map((unit) => ({ text: unit.name, checkbox: true })));
      setUnits((prevState) => [
        ...prevState,
        { text: "Acesso completo", checkbox: true },
      ]);
    }
  }, [structure]);

  const createDepartment = (e) => {
    e.stopPropagation();

    if (newDepartment === "Acesso completo" || newDepartment === "*") {
      setNewDepartment("")
      return;
    }

    setUnits((prevState) => [
      ...prevState.slice(0, -1), 
      {text: newDepartment, checkbox: true},
      { text: "Acesso completo", checkbox: true }
    ]);
    setDepartment(newDepartment);
    setNewDepartment("");
  }

  return (
    <div className="Departments">
      <h2>Departamentos</h2>
      <div className="container-dpt">
        <div className="wrapper-dpt">
          {units?.map((item, key) => (
            <div className="InformationCheckbox" key={key}>
              <Checkbox
                value={item.text}
                updateState={() => {
                  setDepartment(
                    item.text === "Acesso completo" ? "*" : item.text
                  );
                }}
                checked={
                  department?.indexOf(item.text) >= 0 ||
                  department?.indexOf("*") >= 0
                }
                disabled={
                  department?.indexOf("*") >= 0 &&
                  item.text !== "Acesso completo"
                }
              />
              <p
                className={
                  department?.indexOf("*") >= 0 &&
                  item.text !== "Acesso completo"
                    ? "disabled"
                    : ""
                }
              >
                {item && item.text}
              </p>
            </div>
          ))}
        </div>
        <div className="create-dpt">
          <Input
            label="Criar departamento"
            updateState={setNewDepartment}
            value={newDepartment}
            placeholder="Digite o nome departamento"
          />
          {newDepartment.length > 0 && (
            <Button onClick={createDepartment}>Salvar</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Departments;
