import { getItemFromStorage } from "../../helper/storage";
import {
  authorizedFetch,
  baseUrl,
  buildHeaders,
  getResponseOrThrow,
  urlFilters,
} from "./common";

export const apiInvitesCollaborators = async (filters) => {
  const token = getItemFromStorage( "TOKEN" );
  const company_id = getItemFromStorage("COMPANY_ID")
  try {
    const response = await authorizedFetch(
      urlFilters(`${baseUrl}/company/${company_id}/employees/invite`, filters),
      {
        method: "GET",
        headers: buildHeaders(token),
      }
    );
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};

export const apiInvitesManagers = async (filters) => {
  const token = getItemFromStorage( "TOKEN" );
  const company_id = getItemFromStorage("COMPANY_ID")
  try {
    const response = await authorizedFetch(
      urlFilters(`${baseUrl}/company/${company_id}/managers/invite`, filters),
      {
        method: "GET",
        headers: buildHeaders(token),
      }
    );
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};

