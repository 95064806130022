import React, {useCallback, useState} from "react";
import "./ForgotPassword.scss";
import Input from "../../global/form/Input/Input";
import { useEmailField } from "../../../hooks/inputHooks";
import Button from "../../global/form/Button/Button";
import BackButton from "../../global/BackButton/BackButton";
import { useDispatch } from "react-redux";
import { sendRecoveryPasswordCode } from "../../../redux/actions/recoveryPassword";
import ConfirmPopUp from "../../global/ConfirmPopUp/ConfirmPopUp";
import { useHistory } from "react-router";
import Loading from "../../global/Loading/Loading";
import { useEventListener } from "../../../hooks/useEventListener";

const ForgotPassword = () => {
  const [email, setEmail, validEmail] = useEmailField("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRecoverPassword = useCallback(() => {
    setIsLoading(true);
    setErrorMessage("");

    if (validEmail) {
      dispatch(sendRecoveryPasswordCode({ email }))
        .then(() => {
          setOpenPopUp(true);
          setIsLoading(false);
        }).catch(() => {
          setIsLoading(false);
        });
      return;
    }

    setErrorMessage(email.length > 1 ? "Email inválido!" : "Preencha o campo email!");
    setIsLoading(false);
  }, [dispatch, email, validEmail])
  
  const handleKeyListener = useCallback((e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      handleRecoverPassword()
    }
  }, [handleRecoverPassword]);

  useEventListener("keydown", handleKeyListener);

  return (
    <section className="ForgotPassword">
      <form onSubmit={(e) => { e.preventDefault() }}>
        <BackButton />
        <p>
          Digite abaixo o e-mail cadastrado para enviarmos seu link para
          cadastrar sua nova senha
        </p>
        {errorMessage && <p>{errorMessage}</p>}
        <Input
          displayError={false}
          onChange={setEmail}
          label="E-mail"
          type="text"
          updateState={setEmail}
          value={email}
          valid={validEmail}
        />
      </form>
      <Button bottom type="button" onClick={() => handleRecoverPassword()}>
        enviar
      </Button>
      <Button
        linkStyle
        className="registered"
        onClick={() => {
          history.push("/login");
        }}
      >
        Entrar com a minha conta
      </Button>
      <Loading visible={isLoading} />
      <ConfirmPopUp
        buttonAction={() => history.push("/recuperar-senha/codigo", { email })}
        active={openPopUp}
        message="Solicitação de recuperação feita com sucesso. Em instantes você receberá um e-mail com o código de alteração de senha"
        buttonText="OK"
      />
    </section>
  );
};

export default ForgotPassword;
