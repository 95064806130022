import React, {useState} from "react";
import "./EmployeeInformation.scss";
import Button from "../../global/form/Button/Button";
import Input from "../../global/form/Input/Input";
import InformationCheckbox from "../../editEmployee/InformationCheckbox/InformationCheckbox";

const EmployeeInformation = ({
  handleSubmit,
  unitsOptions,
  selectedUnit,
  setSelectedUnit,
  rolesOptions,
  selectedRole,
  setSelectedRole,
  showCreate,
  addNewUnit,
  addNewRole
}) => {

  const [newDepartment, setNewDepartment] = useState("");
  const [newRole, setNewRole] = useState("");

  const createRole = (e) => {
    e.stopPropagation();
    const createdRole = { text: newRole };
    addNewRole((prevState) => [...prevState, createdRole]);
    setSelectedRole(newRole);
    setNewRole("");
  };

  const createDepartment = (e) => {
    e.stopPropagation();
    
    if (newDepartment === "*") {
      setNewDepartment("");
      return;
    }

    const newUnit = {text: newDepartment, checkbox: true}
    addNewUnit((prevState) => [...prevState, newUnit]);
    setSelectedUnit(newDepartment);
    setNewDepartment("");
  }


  return (
    <div className="EmployeeInformation">
      <h1>Informações do colaborador</h1>
      <div className="checkbox-access">
        <div className="units-container">
          <h2>Departamento</h2>
          {unitsOptions?.map((item, key) => (
            <InformationCheckbox
              item={item}
              key={key}
              updateState={setSelectedUnit}
              currentValue={selectedUnit}
            />
          ))}
          {showCreate && (
            <div className="create-unit">
              <Input
                label="Criar departamento"
                updateState={setNewDepartment}
                value={newDepartment}
                placeholder="Digite o nome departamento"
              />
              {newDepartment.length > 0 && (
                <Button onClick={createDepartment}>Salvar</Button>
              )}
            </div>
          )}
        </div>
        <div className="roles-container">
          <h2>Cargo e Função</h2>
          {rolesOptions?.map((item, key) => (
            <InformationCheckbox
              item={item}
              key={key}
              updateState={setSelectedRole}
              currentValue={selectedRole}
            />
          ))}
          {showCreate && Boolean(selectedUnit) && (
            <div className="create-role">
              <Input
                label="Criar cargo/função"
                updateState={setNewRole}
                value={newRole}
                placeholder="Digite o nome do cargo/função"
              />
              {newRole.length > 0 && <Button onClick={createRole}>Salvar</Button>}
            </div>
          )}
        </div>
      </div>
      <Button onClick={handleSubmit}>Salvar</Button>
    </div>
  );
};

export default EmployeeInformation;
