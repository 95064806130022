import React from "react";
import PopUp from "../PopUp/PopUp";
import Button from "../form/Button/Button";

const ConfirmPopUp = ({ buttonAction, message, buttonText, active }) => {
  return (
    <PopUp active={active}>
      <div className="DeletePopup">
        <h2>{message}</h2>
        <div className="buttons-popup">
          <Button onClick={buttonAction}>{buttonText}</Button>
        </div>
      </div>
    </PopUp>
  );
};

export default ConfirmPopUp;
