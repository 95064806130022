import { getItemFromStorage } from "../../helper/storage";
import { structureRequest, structureRequestFailed, structureRequestSucceeded } from "../action-creators/structure";
import { apiStructureRequest } from "../api/structure";


const createStructureRequest = () => dispatch => {
  dispatch(structureRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  return apiStructureRequest(token, company_id)
    .then(json => {
      dispatch(structureRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(structureRequestFailed(response));
      return Promise.reject(response);
    }
    );
};

export const getCompanyStructure = () => dispatch =>
  dispatch(createStructureRequest());
