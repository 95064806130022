import {
  GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST,
  GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_FAILED,
  GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_SUCCEEDED,
  GET_COMMUNITY_METRICS_REQUEST,
  GET_COMMUNITY_METRICS_REQUEST_FAILED,
  GET_COMMUNITY_METRICS_REQUEST_SUCCEEDED,
  GET_COMMUNITY_TASKS_METRICS_REQUEST,
  GET_COMMUNITY_TASKS_METRICS_REQUEST_FAILED,
  GET_COMMUNITY_TASKS_METRICS_REQUEST_SUCCEEDED,
  GET_METRICS_REQUEST,
  GET_METRICS_REQUEST_FAILED,
  GET_METRICS_REQUEST_SUCCEEDED,
  GET_PERSON_METRICS_EXECUTIONS_REQUEST,
  GET_PERSON_METRICS_EXECUTIONS_REQUEST_FAILED,
  GET_PERSON_METRICS_EXECUTIONS_REQUEST_SUCCEEDED,
  GET_PERSON_METRICS_REQUEST,
  GET_PERSON_METRICS_REQUEST_FAILED,
  GET_PERSON_METRICS_REQUEST_SUCCEEDED
} from "../constants/metrics";

export const metricsRequest = () => {
  return {
    type: GET_METRICS_REQUEST
  };
};

export const metricsRequestSucceeded = payload => {
  return {
    type: GET_METRICS_REQUEST_SUCCEEDED,
    data: payload
  };
};

export const metricsRequestFailed = json => {
  return {
    type: GET_METRICS_REQUEST_FAILED,
    error: json
  };
};

export const communityMetricsRequest = () => {
  return {
    type: GET_COMMUNITY_METRICS_REQUEST
  };
};

export const communityMetricsRequestSucceeded = payload => {
  return {
    type: GET_COMMUNITY_METRICS_REQUEST_SUCCEEDED,
    data: payload
  };
};

export const communityMetricsRequestFailed = json => {
  return {
    type: GET_COMMUNITY_METRICS_REQUEST_FAILED,
    error: json
  };
};

export const communityContentTypeMetricsRequest = () => {
  return {
    type: GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST
  };
};

export const communityContentTypeMetricsRequestSucceeded = payload => {
  return {
    type: GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_SUCCEEDED,
    data: payload
  };
};

export const communityContentTypeMetricsRequestFailed = json => {
  return {
    type: GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_FAILED,
    error: json
  };
};

export const communityTasksMetricsRequest = () => {
  return {
    type: GET_COMMUNITY_TASKS_METRICS_REQUEST
  };
};

export const communityTasksMetricsRequestSucceeded = payload => {
  return {
    type: GET_COMMUNITY_TASKS_METRICS_REQUEST_SUCCEEDED,
    data: payload
  };
};

export const communityTasksMetricsRequestFailed = json => {
  return {
    type: GET_COMMUNITY_TASKS_METRICS_REQUEST_FAILED,
    error: json
  };
};

export const personMetricsRequest = () => {
  return {
    type: GET_PERSON_METRICS_REQUEST,
  };
};

export const personMetricsRequestSucceeded = (payload) => {
  return {
    type: GET_PERSON_METRICS_REQUEST_SUCCEEDED,
    data: payload
  };
};
export const personMetricsRequestFailed = json => {
  return {
    type: GET_PERSON_METRICS_REQUEST_FAILED,
    error: json
  };
};

export const personMetricsExecutionsRequest = () => {
  return {
    type: GET_PERSON_METRICS_EXECUTIONS_REQUEST,
  };
};

export const personMetricsExecutionsRequestSucceeded = (payload) => {
  return {
    type: GET_PERSON_METRICS_EXECUTIONS_REQUEST_SUCCEEDED,
    data: payload
  };
};
export const personMetricsExecutionsRequestFailed = json => {
  return {
    type: GET_PERSON_METRICS_EXECUTIONS_REQUEST_FAILED,
    error: json
  };
};
