import {
  acceptInviteRequest, acceptInviteRequestFailed, acceptInviteRequestSucceeded,
  inviteEmployeeRequest,
  inviteEmployeeRequestFailed,
  inviteEmployeeRequestSucceeded,
  inviteManagerRequest,
  inviteManagerRequestFailed,
  inviteManagerRequestSucceeded,
  managerInviteRequest,
  managerInviteRequestFailed,
  managerInviteRequestSucceeded
} from "../action-creators/invites";
import {
  apiAcceptInviteRequest,
  apiGetManagerInviteRequest,
  apiInviteEmployeeRequest,
  apiInviteManagerRequest
} from "../api/invites";
import { getItemFromStorage } from "../../helper/storage";

const createInviteEmployeeRequest = payload => dispatch => {
  dispatch( inviteEmployeeRequest() );
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  return apiInviteEmployeeRequest(token, company_id, payload)
    .then( json => {
      dispatch( inviteEmployeeRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( inviteEmployeeRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const inviteEmployee = payload => dispatch =>
  dispatch( createInviteEmployeeRequest(payload) );

const createInviteManagerRequest = payload => dispatch => {
  dispatch( inviteManagerRequest() );
  const token = getItemFromStorage( "TOKEN" );
  const company_id = getItemFromStorage( "COMPANY_ID" );
  return apiInviteManagerRequest( token, company_id, payload )
    .then( json => {
      dispatch( inviteManagerRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
      dispatch( inviteManagerRequestFailed( response ) );
    } );
};

export const inviteManager = payload => dispatch =>
  dispatch( createInviteManagerRequest(payload) );

const getManagerInviteRequest = id => dispatch => {
  dispatch( managerInviteRequest() );
  return apiGetManagerInviteRequest(id)
    .then( json => {
      dispatch( managerInviteRequestSucceeded( json ) );
      return Promise.resolve(json);
    } )
    .catch( response => {
        dispatch( managerInviteRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const getManagerInvite = id => dispatch =>
  dispatch( getManagerInviteRequest(id) );

const createAcceptInviteRequest = (id, payload)=> dispatch => {
  dispatch( acceptInviteRequest() );
  return apiAcceptInviteRequest(id, payload)
    .then( json => {
      dispatch( acceptInviteRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( acceptInviteRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const acceptInvite = (id, payload) => dispatch =>
  dispatch( createAcceptInviteRequest(id, payload) );
