import {
  GET_GLOBAL_EVALUATIONS_REQUEST,
  GET_GLOBAL_EVALUATIONS_REQUEST_FAILED,
  GET_GLOBAL_EVALUATIONS_REQUEST_SUCCEDED
} from "../constants/globalEvaluations";

export const globalEvaluations = (
  state = {
    data: [],
    error: {},
    isFetching: false
  },
  action
) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_GLOBAL_EVALUATIONS_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_GLOBAL_EVALUATIONS_REQUEST_SUCCEDED:
      newState.data = action.data;
      newState.error = {};
      newState.isFetching = false;
      return newState;

    case GET_GLOBAL_EVALUATIONS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return state;
  }
};
