import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ReactComponent as Logout } from "../../../assets/images/logoutIcon.svg";
import { logout } from "../../../redux/actions/login";
import "./Navbar.scss";

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <nav className="Navbar">
      <div>
        <button
          className="logo cursor-pointer"
          onClick={() => history.push("/")}
        >
          <Logo className="logo" />
          {/* <p className="text-admin">Admin</p> */}
        </button>
        <button
          className={location.pathname === '/' ? "active" : ""}
          onClick={() => history.push('/')}
        >
          dashboard
        </button>
        <button
          className={
            location.pathname.includes("aportes") || location.pathname.includes("investimentos")
              ? "active"
              : null
          }
        >
          Investimentos
          <ul>
            <li>
              <button
                className={`${location.pathname.includes("investimentos") ? "active" : null
                  }`}
                onClick={() => {
                  history.push('/investimentos')
                }}
              >
                Investimentos
              </button>
            </li>
            <li>
              <button
                className={`${location.pathname.includes("aportes") ? "active" : null}`}
                onClick={() => {
                  history.push('/aportes')
                }}
              >
                Aportes
              </button>
            </li>
          </ul>
        </button>
        <button
          className={location.pathname === '/acessos/gestores' || location.pathname === '/acessos/colaboradores' ? "active" : ""}
          onClick={() => history.push('/acessos/gestores')}
        >
          acessos
        </button>
        <button
          className={location.pathname === '/investidores' ? "active" : ""}
          onClick={() => history.push('/investidores')}
        >
          investidores
        </button>
        <button
          className={location.pathname === "/aportes" ? "active" : ""}
          onClick={() => history.push('/aportes')}
        >
          aportes
        </button>
        <button
          className={location.pathname === "/suporte" ? "active" : ""}
          onClick={() => history.push('/suporte')}
        >
          suporte
        </button>
        <button
          className={location.pathname === "/contato" ? "active" : ""}
          onClick={() => history.push('/contato')}
        >
          contato
        </button>
      </div>
      <div>
        <button className="logout" onClick={() => dispatch(logout()).then(() => history.push("/login"))}>
          sair
          <Logout />
        </button>
      </div>
    </nav>
  )
}

export default Navbar;
