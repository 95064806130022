import React, {useState} from 'react';
import "./CommunityInfo.scss";
import Input from "../../global/form/Input/Input";
import Button from "../../global/form/Button/Button";
import {useHistory} from "react-router-dom";
import BackButton from "../../global/BackButton/BackButton";
import DragAndDropUpload from "../../global/form/DragAndDropUpload/DragAndDropUpload";
import {ReactComponent as Image} from "../../../assets/images/add-image.svg";
import UploadButton from "../../global/form/UploadButton/UploadButton";

const CommunityInfo = ({
                         nextStep,
                         value,
                         updateState,
                         displayErrorMessage,
                         file,
                         setFile,
                         setFilePath
                       }) => {
  const history = useHistory()
  const [error, setError] = useState(false);

  const handleFileUpload = (e) => {
    const file = (e.target && e.target.files[0]) || e;
    if (validateFile(file)) {
      setError(false);
      setFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFilePath(reader.result);
      }
    } else {
      setError(true);
    }
  };

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
    return validTypes.indexOf(file.type) !== -1;
  }

  return (
    <section className="CommunityInfo">
      <div className="CommunityName">
        <BackButton linkStyle action={() => history.goBack()}>voltar</BackButton>
        <h2>Nova comunidade</h2>
        <p>Por favor, insira os dados da comunidade.</p>
        {value?.length > 0 && !(file instanceof File) && (
          <p className="image-error">O logotipo é obrigatório!</p>
        )}
        <Input type="text" value={value} updateState={updateState} 
                displayError={true}
               label="Nome da comunidade"
               displayErrorMessage={displayErrorMessage} errorMessage="Nome Inválido" valid={value?.length > 1}/>
      </div>
      <div className="CompanyLogo">
        <p>Logo ou imagem de capa</p>
        <p className="error-message">{error && "Ops! Esse não parece ser um arquivo de imagem válido"}</p>
        <DragAndDropUpload setFile={handleFileUpload}>
          <Image/>
          {!file.name ?
            <p className='upload-button'>Arraste uma imagem para a tela ou <UploadButton handleFileUpload={handleFileUpload} accept="image/*"/>
            </p> : <p>{file.name}</p>}
        </DragAndDropUpload>
      </div>
      <Button 
        onClick={value?.length > 0 && file instanceof File ? nextStep : () => {}}
        bottom
      >
        IR PARA INTEGRANTES
        <span class="ph--arrow-right-bold"/>
      </Button>
    </section>
  );
};

export default CommunityInfo;
