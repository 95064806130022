import {
  DELETE_MANAGER_REQUEST,
  DELETE_MANAGER_REQUEST_FAILED,
  DELETE_MANAGER_REQUEST_SUCCEEDED,
  MANAGERS_REQUEST,
  MANAGERS_REQUEST_FAILED,
  MANAGERS_REQUEST_SUCCEEDED,
  UPDATE_MANAGER_REQUEST,
  UPDATE_MANAGER_REQUEST_FAILED,
  UPDATE_MANAGER_REQUEST_SUCCEEDED,
} from "../constants/managers";

export const managersRequest = () => {
  return {
    type: MANAGERS_REQUEST,
  };
};

export const managersRequestSucceeded = (payload) => {
  return {
    type: MANAGERS_REQUEST_SUCCEEDED,
    data: payload,
  };
};

export const managersRequestFailed = (json) => {
  return {
    type: MANAGERS_REQUEST_FAILED,
    error: json,
  };
};

export const deleteManagerRequest = () => {
  return {
    type: DELETE_MANAGER_REQUEST,
  };
};

export const deleteManagerRequestSucceeded = (payload) => {
  return {
    type: DELETE_MANAGER_REQUEST_SUCCEEDED,
    data: payload,
  };
};

export const deleteManagerRequestFailed = (json) => {
  return {
    type: DELETE_MANAGER_REQUEST_FAILED,
    error: json,
  };
};

export const updateManagerRequest = () => {
  return {
    type: UPDATE_MANAGER_REQUEST,
  };
};

export const updateManagerRequestSucceeded = (payload) => {
  return {
    type: UPDATE_MANAGER_REQUEST_SUCCEEDED,
    data: payload,
  };
};

export const updateManagerRequestFailed = (json) => {
  return {
    type: UPDATE_MANAGER_REQUEST_FAILED,
    error: json,
  };
};
