const actionFieldConventions = {
  type: 'type',
  error: 'error',
  data: 'data',
  fetching: 'isFetching'
}

const fetchConstConventions = {
  '_REQUEST': 'begin',
  '_REQUEST_SUCCEEDED': 'success',
  '_REQUEST_FAILED': 'error'
}

/*
* When using reducers to store fetch data, it almost always store fetching, data and error state. This processor
* of grouped fetch minimize the effort to create and manage complex reducers with more than one type of request in it.
* */

export const processGroupedFetchReducer = (oldState, reducerNames, action) => {
  const reducerName = getReducerName(reducerNames, action[actionFieldConventions.type])

  if(reducerName === null) return oldState;

  let newState = { ...oldState };
  switch (getActionFetchState(action[actionFieldConventions.type])) {
    case 'begin':
      newState[reducerName][actionFieldConventions.fetching] = true
      return addCollectiveVariables(reducerNames, newState)

    case 'success':
      newState[reducerName][actionFieldConventions.fetching] = false
      newState[reducerName][actionFieldConventions.data] = action[actionFieldConventions.data]
      newState[reducerName][actionFieldConventions.error] = {}
      return addCollectiveVariables(reducerNames, newState)

    case 'error':
      newState[reducerName][actionFieldConventions.fetching] = false
      newState[reducerName][actionFieldConventions.data] = {}
      newState[reducerName][actionFieldConventions.error] = action[actionFieldConventions.error]
      return addCollectiveVariables(reducerNames, newState)

    default:
      return addCollectiveVariables(reducerNames, newState);
  }
}

const addCollectiveVariables = (reducerNames, state) => {
  let isFetching = false;

  for(let reducer of Object.values(reducerNames)) {
    if (state[reducer][actionFieldConventions.fetching]) {
      isFetching = true;
      break;
    }
  }

  state[actionFieldConventions.fetching] = isFetching;
  return state;
}

export const generateDefaultState = (reducerNames) => {
  let defaultState = {};

  for(let reducer of Object.values(reducerNames)) {
    defaultState[reducer] = {}
    defaultState[reducer][actionFieldConventions.fetching] = false
    defaultState[reducer][actionFieldConventions.data] = {}
    defaultState[reducer][actionFieldConventions.error] = {}
  }

  return addCollectiveVariables(reducerNames, defaultState)
}

const getActionFetchState = (actionType) => {
  for (let name of Object.keys(fetchConstConventions)) {
    if (actionType.endsWith(name)) {
      return fetchConstConventions[name]
    }
  }
  return "N/A"
}

const getReducerName = (reducerNames, actionType) => {
  for (let name of Object.keys(reducerNames)) {
    if (actionType.startsWith(name)) {
      return reducerNames[name]
    }
  }
  return null
}
