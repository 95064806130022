import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTextField } from '../../../hooks/inputHooks';
import { listInvestments } from '../../../redux/actions/investments';
import { getCompanyStructure } from '../../../redux/actions/structure';
import { createTaskCompany, getTaskFunctionality, getTasks } from '../../../redux/actions/tasks';
import showNotification from '../../global/Notification/showNotification';
import Button from '../../global/form/Button/Button';
import MultiSelect from '../../global/form/MultiSelect/MultiSelect';
import Select from '../../global/form/Select/Select';
import './CreateTask.scss';

const CreateTask = ({ showModal, handleShowModal, handleShowInvestmentModal }) => {
  const dispatch = useDispatch();
  const structure = useSelector((state) => state?.structure?.data);
  const investments = useSelector(state => state.investments);
  const [inputError, setInputError] = useState(false);
  const [contentType, setContentType] = useState(null)
  const [contentFormat, setContentFormat] = useState(null)
  const [taskMedium, setTaskMedium] = useState(null)
  const [taskMotive, setTaskMotive] = useState(null)
  const [taskFormat, setTaskFormat] = useState(null)
  const [contentAcquisition, setContentAcquisition] = useState(null)
  const [school, setSchool, validSchool] = useTextField('');
  const [tribe, setTribe] = useState([]);
  const [squad, setSquad] = useState([]);
  const [steps, setSteps] = useState(1);
  const [listSquads, setListSquads] = useState([]);
  const [inputs, setInputs] = useState({
    title: "",
    description: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleOptionChange = (option) => {
    setContentType(option);
  };

  const handleTypeChange = (option) => {
    setContentFormat(option);
  };

  const handleMediumChange = (option) => {
    setTaskMedium(option);
  };

  const handleMotiveChange = (option) => {
    setTaskMotive(option)
  }

  const handleFormatChange = (option) => {
    setTaskFormat(option)
  }

  const handleAcquisitionChange = (option) => {
    setContentAcquisition(option)
  }

  useEffect(() => {
    dispatch(getCompanyStructure());
    dispatch(listInvestments())
  }, [dispatch]);

  const formattedList = Array.isArray(investments?.data?.items) && investments?.data?.items?.map(skill => ({ name: skill.name, label: skill.name, id: skill.id }));

  const transformedData = Array.isArray(structure) && structure?.map(item => ({
    [item.name]: item.roles.map(role => ({ label: role, value: role }))
  }));
  const transformedObject = Array.isArray(transformedData) && transformedData.reduce((acc, item) => {
    const title = Object.keys(item)[0];
    acc[title] = item[title];
    return acc;
  }, {});
  const titlesOnly = Array.isArray(structure) && transformedData.map(item => {
    const title = Object.keys(item)[0];
    return { label: title, value: title };
  });

  const generateSelectOptions = (arrayValues, selectedValue) =>
    arrayValues.map((data, key) => (
      <option
        value={data.value}
        key={key}
        defaultChecked={selectedValue === data.value}
      >
        {data.label}
      </option>
    ));

  const nextStep = () => {
    setInputError(false)
    if (!inputs.title || !inputs.description || !contentType || !contentFormat || !contentAcquisition || !taskFormat || !taskMedium || !taskMotive) {
      setInputError(true)
    } else {
      setSteps(3)
    }
  }

  const submitInvestment = () => {
    setInputError(false)
    if (!inputs.title || !inputs.description || !contentType || !contentFormat || !contentAcquisition || !taskFormat || !taskMedium || !taskMotive || !validSchool || squad.length <= 0 || tribe.length <= 0) {
      setInputError(true)
    } else {
      const payload = {
        description: inputs.description,
        title: inputs.title,
        topic: {
          id: formattedList.find(skill => skill.name === school).id
        },
        contentType,
        contentAcquisition,
        contentFormat,
        taskFormat,
        taskMotive,
        roles: squad.map(item => item.value),
        units: tribe.map(item => item.value)
      }
      dispatch(createTaskCompany(payload)).then((json) => {
        showNotification('Aporte criado com sucesso!', 5000);
        handleShowModal()
        dispatch(getTasks());
        dispatch(getTaskFunctionality())
        handleShowInvestmentModal(json)
      }).catch(() => showNotification('Houve um erro inesperado!', 5000))
    }
  }

  useEffect(() => {
    const mapResults = () => {
      const tempResults = [];
      tribe.forEach(item => {
        const value = item.value;
        if (transformedObject.hasOwnProperty(value)) {
          tempResults.push(...transformedObject[value]);
        }
      });
      setListSquads(tempResults);
    };

    mapResults();
  }, [tribe]);
  return (
    <div className={`CreateTask ${showModal ? "active" : ""}`}>
      <div className="container-create">
        <div className="close-modal">
          <span role="button" onClick={() => handleShowModal()}>Fechar &nbsp; ✖</span>
        </div>
        <p className="title-create">{school ? `Criar nova tarefa | ${school}` : "Criar nova tarefa"}</p>
        <div className="form-create">
          {steps === 1 ? (
            < div className="box-input investment">
              <Select
                label="Selecione o investimento:"
                updateState={setSchool}
                displayError={false}
                value={school}
              >
                <option value="" disabled>
                  Selecione
                </option>
                {formattedList && generateSelectOptions(formattedList, school)}
              </Select>
            </div>
          ) : steps === 2 ?
            <>
              <div className='box-input'>
                <label for="title">Título:</label>
                <input onChange={handleChange}
                  id='title'
                  name='title'
                  type='text'
                  value={inputs.title} />
              </div>
              <div className='box-input description'>
                <label for="description">Descrição:</label>
                <textarea
                  onChange={handleChange}
                  id='description'
                  type='text'
                  value={inputs.description}
                  name="description" rows="4" cols="50"></textarea>
              </div>
              <div className="box-checks">
                <p className='title-checks'>Qual é o formato da tarefa ?</p>
                <div className='container-checks'>

                  <div role='input' onClick={() => handleOptionChange("VISUAL_EXPOSURE")} class="radio-item">
                    <input
                      type="radio"
                      value={contentType}
                      checked={contentType === 'VISUAL_EXPOSURE'}
                    />
                    <label for="aula">Aula</label>
                  </div>

                  <div role='input' onClick={() => handleOptionChange('TEXT')} class="radio-item">
                    <input
                      type="radio"
                      value={contentType}
                      checked={contentType === 'TEXT'}
                    />
                    <label>Texto</label>
                  </div>

                  <div role='input' onClick={() => handleOptionChange('AUDIO')} class="radio-item">
                    <input
                      type="radio"
                      value={contentType}
                      checked={contentType === 'AUDIO'}
                    />
                    <label>Áudio</label>
                  </div>

                  <div role='input' onClick={() => handleOptionChange('VIDEO')} class="radio-item">
                    <input
                      type="radio"
                      value={contentType}
                      checked={contentType === 'VIDEO'}
                    />
                    <label>Vídeo</label>
                  </div>

                  <div role='input' onClick={() => handleOptionChange('HANDS_ON')} class="radio-item">
                    <input
                      type="radio"
                      value={contentType}
                      checked={contentType === 'HANDS_ON'}
                    />
                    <label>Vivência</label>
                  </div>
                </div>
              </div>

              <div className="box-checks">
                <p className='title-checks'>Tipo da tarefa:</p>
                <div className='container-checks'>
                  <div role='input' onClick={() => handleTypeChange("RECORDED")} class="radio-item">
                    <input
                      type="radio"
                      value={contentFormat}
                      checked={contentFormat === 'RECORDED'}
                    />
                    <label for="aula">Gravado</label>
                  </div>

                  <div role='input' onClick={() => handleTypeChange('LIVE')} class="radio-item">
                    <input
                      type="radio"
                      value={contentFormat}
                      checked={contentFormat === 'LIVE'}
                    />
                    <label>Em tempo real</label>
                  </div>
                </div>
              </div>

              <div className="box-checks">
                <p className='title-checks'></p>
                <div className='container-checks'>

                  <div role='input' onClick={() => handleMediumChange("REMOTE")} class="radio-item">
                    <input
                      type="radio"
                      value={taskMedium}
                      name='aula'
                      checked={taskMedium === 'REMOTE'}
                    />
                    <label for="aula">Remoto</label>
                  </div>

                  <div role='input' onClick={() => handleMediumChange('FACE_TO_FACE')} class="radio-item">
                    <input
                      type="radio"
                      value={taskMedium}
                      checked={taskMedium === 'FACE_TO_FACE'}
                    />
                    <label>Presencial</label>
                  </div>
                </div>
              </div>

              <div className="box-checks">
                <p className='title-checks'></p>
                <div className='container-checks'>

                  <div role='input' onClick={() => handleMotiveChange("SELF_INITIATIVE")} class="radio-item">
                    <input
                      type="radio"
                      value={taskMotive}
                      checked={taskMotive === 'SELF_INITIATIVE'}
                    />
                    <label for="aula">Iniciativa própria</label>
                  </div>

                  <div role='input' onClick={() => handleMotiveChange('IMPOSED')} class="radio-item">
                    <input
                      type="radio"
                      value={taskMotive}
                      checked={taskMotive === 'IMPOSED'}
                    />
                    <label>Sugestão ou orientação de alguém</label>
                  </div>
                </div>
              </div>

              <div className="box-checks">
                <p className='title-checks'></p>
                <div className='container-checks'>

                  <div role='input' onClick={() => handleFormatChange("BY_MYSELF")} class="radio-item">
                    <input
                      type="radio"
                      value={taskFormat}
                      checked={taskFormat === 'BY_MYSELF'}
                    />
                    <label for="aula">Sozinho</label>
                  </div>

                  <div role='input' onClick={() => handleFormatChange('IN_GROUP')} class="radio-item">
                    <input
                      type="radio"
                      value={taskFormat}
                      checked={taskFormat === 'IN_GROUP'}
                    />
                    <label>Em grupo</label>
                  </div>
                </div>
              </div>

              <div className="box-checks">
                <p className='title-checks'></p>
                <div className='container-checks'>

                  <div role='input' onClick={() => handleAcquisitionChange("PAID")} class="radio-item">
                    <input
                      type="radio"
                      value={contentAcquisition}
                      checked={contentAcquisition === 'PAID'}
                    />
                    <label for="aula">Pago</label>
                  </div>

                  <div role='input' onClick={() => handleAcquisitionChange('FREE')} class="radio-item">
                    <input
                      type="radio"
                      value={contentAcquisition}
                      checked={contentAcquisition === 'FREE'}
                    />
                    <label>Grátis</label>
                  </div>
                </div>
              </div>
            </>
            :
            <div className="box-input select">
              <label htmlFor="">Obrigatório para:</label>
              <div className='multi-select'>
                <MultiSelect
                  title="Selecione"
                  label="Selecione as tribos:"
                  onSelectChange={setTribe}
                  values={tribe}
                  options={titlesOnly}
                />
                <MultiSelect
                  title="Selecione"
                  label="Selecione os squads:"
                  options={listSquads}
                  onSelectChange={setSquad}
                  values={squad}
                />
              </div>
            </div>
          }
          <div className="container-button">
            <p className={`message-error ${inputError ? 'active' : ""} `}>Preencha todos os campos!</p>
            {steps === 1 ?
              <Button onClick={() => {
                if (school) {
                  setSteps(2)
                } else {
                  showNotification('Escolha um investimento', 5000);
                }
              }}>AVANÇAR</Button>
              : steps === 2 ?
                <>
                  <Button onClick={() => {
                    setSteps(steps - 1)
                  }}>VOLTAR</Button>
                  <Button onClick={() => {
                    nextStep()
                  }}>AVANÇAR</Button>
                </>
                :
                <>
                  <Button onClick={() => {
                    setSteps(steps - 1)
                  }}>VOLTAR</Button>
                  <Button onClick={() => {
                    submitInvestment()
                    // handleNotifyModal()
                  }}>CONFIRMAR</Button>
                </>
            }
          </div>
        </div>
      </div>
    </div >
  )
}

export default CreateTask
