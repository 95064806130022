import React, { useState } from "react";
import "./RecoveryCode.scss";
import Button from "../../global/form/Button/Button";
import { sendRecoveryPasswordCode } from "../../../redux/actions/recoveryPassword";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import ConfirmPopUp from "../../global/ConfirmPopUp/ConfirmPopUp";
import Loading from "../../global/Loading/Loading";

const RecoveryCode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const email = location.state?.email;
  const [code, setCode] = useState(new Array(6).fill(""));
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    let newArray = [...code];
    newArray[index] = element.value;
    setCode(newArray);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleResendEmail = () => {
    setIsLoading(true);
    dispatch(sendRecoveryPasswordCode({ email }))
      .then(() => {
        setOpenPopUp(true);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <section className="RecoveryCode">
      <p>Insira o código de 6 dígitos que enviamos para seu email.</p>
      {errorMessage.length > 0 && <p><br/>{errorMessage}</p>}
      <div className="code-container">
        {code.map((data, index) => {
          return (
            <input
              onChange={e => handleChange(e.target, index)}
              name="codeOne"
              className="otp-field"
              type="text"
              value={data}
              key={index}
              maxLength="1"
              onFocus={e => e.target.select()}
            />
          );
        })}
      </div>
      <Button linkStyle onClick={() => handleResendEmail()}>
        Não recebi o código
      </Button>
      <Button
        bottom
        onClick={() => {
          const urlCode = code.join("");
          urlCode.length === 6
            ? history.push(`/recuperar-senha/codigo/${urlCode}`)
            : setErrorMessage("Preencha todos os campos corretamente com o código!");
        }}
      >
        Confirmar
      </Button>
      <Loading visible={isLoading} />
      <ConfirmPopUp
        buttonAction={() => setOpenPopUp(false)}
        active={openPopUp}
        message={`
          Código reenviado com sucesso. Em instantes você receberá um email 
          com o código de alteração de senha. 
          Por favor, verifique também a caixa de SPAM.
        `}
        buttonText="OK"
      />
    </section>
  );
};

export default RecoveryCode;
