import {apiSignUp} from "../api/signup";
import {signUpRequest, signUpRequestFailed, signUpRequestSucceeded} from "../action-creators/signup";

const requestSignUp = payload => dispatch => {
  dispatch(signUpRequest());
  return apiSignUp(payload)
    .then(json => {
      dispatch(signUpRequestSucceeded(json))
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(signUpRequestFailed(json));
      return Promise.reject(json);
    })
}

export const signUp = payload => dispatch => dispatch(requestSignUp(payload));
