import React from 'react';
import "./ManagerInfo.scss"
import Departments from "../Departments/Departments";
import Button from "../../global/form/Button/Button";

const ManagerInfo = ({department, setDepartment, handleUpdate}) => {
  return (
    <div className="ManagerInfo">
      <h1>Permissão de acesso</h1>
      <div className="checkbox-access">
        <Departments department={department} setDepartment={setDepartment}/>
      </div>
        <Button onClick={handleUpdate}>Salvar</Button>
    </div>
  );
};

export default ManagerInfo;
