import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { translateGestorLevels } from '../../../helper/dataHelper';
import { getAccesses } from "../../../redux/actions/accesses";
import { getDemo } from "../../../redux/actions/demo";
import { inviteManager } from "../../../redux/actions/invites";
import Loading from "../../global/Loading/Loading";
import PopUpSuccess from "../../global/PopUpSuccess/PopUpSuccess";
import AccessPermission from "../AcessPermission/AccessPermission";
import IncludeNewPeople from "../IncludeNewPeople/IncludeNewPeople";
import "./ManagerInvitation.scss";
import { getItemFromStorage } from '../../../helper/storage';

const ManagerInvitation = () => {
  const [unitsAccesses, setUnitsAccesses] = useState([]);
  const [email, setEmail] = useState("");
  const [popUpActive, setPopUpActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const demo = useSelector(state => state.demo);
  const [levelsAccesses, setLevelsAccesses] = useState([]);
  const accesses = useSelector(state => state.accesses?.data);
  const companyId = getItemFromStorage("COMPANY_ID");
  

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    if (!demo.isDemoMode) {
      setIsLoading(true);
      const payload = {
        email,
        units: unitsAccesses.includes("Acesso completo") ? ["*"] : [...unitsAccesses],
        level: translateGestorLevels(levelsAccesses)
      };
      dispatch(inviteManager(payload)).then(() => {
        setPopUpActive(true);
        setIsLoading(false);
      });
    } else {
      setPopUpActive(true);
    }
  }

  useEffect(() => {
    demo.isDemoMode ? dispatch(getDemo()) : dispatch(getAccesses());
  }, [dispatch, demo.isDemoMode]);

  return (
    <section className="ManagerInvitation">
      <IncludeNewPeople
        units={unitsAccesses}
        setUnits={setUnitsAccesses}
        levelsAccesses={levelsAccesses}
        setLevelsAccesses={setLevelsAccesses}
        setEmail={setEmail}
      />
      <AccessPermission
        units={unitsAccesses}
        setUnits={setUnitsAccesses}
        levelsAccesses={levelsAccesses}
        setLevelsAccesses={setLevelsAccesses}
        handleSubmit={handleSubmit}
      />
      <PopUpSuccess
        active={popUpActive}
        closePopUp={() => history.push("/acessos/gestores")}
      >
        <p>Convite enviado ao gestor!</p>
      </PopUpSuccess>
      <Loading visible={isLoading} />
    </section>
  );
};

export default ManagerInvitation;
