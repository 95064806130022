import React from "react";
import "./Questions.scss";
import DescribeQuestion from "../DescribeQuestion/DescribeQuestion";
import definitions from "../mock";
import Question from "../Question/Question";

const Questions = ({ category, userSearch }) => {
  return (
    <div className="Questions">
      <ul className="questions">
        {definitions
          .filter(item => {
            return category === "" || item.category === category;
          })
          .filter(item => {
            return item.question
              .toLowerCase()
              .includes(userSearch.toLocaleLowerCase());
          })
          .map((item, key) => (
            <Question item={item} key={key} />
          ))}
      </ul>
      <DescribeQuestion />
    </div>
  );
};

export default Questions;
