import React, {useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as Eye} from "../../../../assets/images/eye.svg";
import {ReactComponent as HiddenEye} from "../../../../assets/images/eyeHide.svg";
import "./Input.scss";

const Input = ({
                 label,
                 placeholder,
                 type,
                 updateState,
                 value,
                 displayError,
                 displayErrorMessage,
                 valid,
                 disabled,
                 onKeyUp,
                 min,
                 max,
                 detail
               }) => {
  const [typeInput, setTypeInput] = useState(type);
  const handleChange = event => {
    updateState(event.target.value);
  };

  const handlePasswordDisplay = () => {
    setTypeInput(typeInput === "password" ? "text" : "password");
  };

  const eyeIcon = {
    password: <HiddenEye onClick={handlePasswordDisplay}/>,
    text: <Eye onClick={handlePasswordDisplay}/>
  };

  return (
    <div className="Input">
      <p className={displayError && !valid ? "visible" : null}>
        {value === "" ? "Campo obrigatório" : displayErrorMessage}
      </p>
      <div>
        {label ? <label>{label}</label> : null}
        <input
          className={displayError ? "invalid" : disabled ? "disabled" : ""}
          onChange={handleChange}
          placeholder={placeholder}
          type={typeInput}
          value={value}
          disabled={disabled}
          onKeyUp={onKeyUp}
          min={type === "date" ? min : null}
          max={type === "date" ? max : null}
        />
        {type === "password" ? eyeIcon[typeInput] : null}
      </div>
      {detail && <p className="detail">{detail}</p>}
    </div>
  );
};

Input.propTypes = {
  disabled: PropTypes.bool,
  displayError: PropTypes.bool.isRequired,
  displayErrorMessage: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  updateState: PropTypes.func.isRequired,
  userProfile: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

Input.defaultProps = {
  disabled: false,
  label: "",
  placeholder: "",
  type: "text",
  userProfile: false,
  valid: true
};

export default Input;
