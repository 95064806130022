import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../assets/images/access-delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/access-edit.svg";
import { deleteManager, getManagers } from "../../../redux/actions/managers";
import DeletePopup from "../../global/DeletePopup/DeletePopup";
import "./ManagersTable.scss";
import { translateGestorLevel } from '../../../helper/dataHelper';

const ManagersTable = ({ data }) => {
  const [popUp, setPopUp] = useState(false);
  const [managerName, setManagerName] = useState("");
  const [managerId, setManagerId] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const openPopUp = (name, id) => {
    setManagerName(name);
    setManagerId(id);
    setPopUp(true);
  };

  const deleteEmployeeClick = () => {
    dispatch(deleteManager(managerId)).then(() => {
      setPopUp(false);
      dispatch(getManagers());
    });
  };

  return (
    <>
      <table className="ManagersTable">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Nível</th>
            <th>E-mail</th>
            <th>Departamentos</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {data.items && data.items.length > 0 ? (
            data.items.map((managerData) => (
              <tr>
                <td>{managerData && managerData.manager.name}</td>
                <td>{managerData && translateGestorLevel(managerData.level)}</td>
                <td>{managerData && managerData.manager.email}</td>
                <td>
                  {managerData &&
                    managerData.units.map((department) =>
                      department === "*" ? `#Todos` : `#${department} `
                    )}
                </td>
                <td
                  onClick={() =>
                    history.push({
                      pathname: `/editar/gestor/id/${managerData.id}`,
                      state: { manager: managerData },
                    })
                  }
                >
                  <p>
                    editar <EditIcon />
                  </p>
                </td>
                <td
                  onClick={() => {
                    openPopUp(managerData.manager.name, managerData.id);
                  }}
                >
                  <DeleteIcon />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td />
              <td colSpan={8}>
                Você não possui gestores cadastrados em sua base - para
                adicionar clique em enviar convite por email.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <DeletePopup
        name={managerName}
        active={popUp}
        deleteClick={deleteEmployeeClick}
        cancelClick={() => setPopUp(false)}
      />
    </>
  );
};

ManagersTable.propTypes = {
  data: PropTypes.object.isRequired
};

export default ManagersTable;
