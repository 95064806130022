import {
  UNITS_REQUEST,
  UNITS_REQUEST_FAILED,
  UNITS_REQUEST_SUCCEEDED
} from "../constants/units";

export const units = (
  state = {
    isFetching: false,
    data: {units:[]},
    error: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case UNITS_REQUEST:
      newState.isFetching = true;
      return newState;

    case UNITS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case UNITS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
