import React from 'react';
import "./GenericPage.scss";
import Navbar from "../../global/Navbar/Navbar";

const GenericPage = ({children}) => {
  return (
    <section className="GenericPage">
      <Navbar/>
      {children}
    </section>
  );
};

export default GenericPage;
