import {
  ACCESSES_REQUEST,
  ACCESSES_REQUEST_FAILED,
  ACCESSES_REQUEST_SUCCEEDED
} from "../constants/accesses";

export const accessesRequest = () => {
  return {
    type: ACCESSES_REQUEST
  }
};

export const accessesRequestSucceeded = (payload) => {
  return {
    type: ACCESSES_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const accessesRequestFailed = (json) => {
  return {
    type: ACCESSES_REQUEST_FAILED,
    error: json
  }
};
