import {
  ADD_EMPLOYEES_REQUEST,
  ADD_EMPLOYEES_REQUEST_FAILED,
  ADD_EMPLOYEES_REQUEST_SUCCEEDED,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_REQUEST_FAILED,
  DELETE_EMPLOYEE_REQUEST_SUCCEEDED,
  EMPLOYEES_METRICS_FAILED,
  EMPLOYEES_METRICS_REQUEST,
  EMPLOYEES_METRICS_SUCCEEDED,
  EMPLOYEES_REQUEST,
  EMPLOYEES_REQUEST_FAILED,
  EMPLOYEES_REQUEST_SUCCEEDED,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_REQUEST_FAILED,
  UPDATE_EMPLOYEE_REQUEST_SUCCEEDED
} from "../constants/employees";

export const employeesRequest = () => {
  return {
    type: EMPLOYEES_REQUEST
  }
};

export const employeesRequestSucceeded = (payload) => {
  return {
    type: EMPLOYEES_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const employeesRequestFailed = (json) => {
  return {
    type: EMPLOYEES_REQUEST_FAILED,
    error: json
  }
};

export const deleteEmployeeRequest = () => {
  return {
    type: DELETE_EMPLOYEE_REQUEST
  }
};

export const deleteEmployeeRequestSucceeded = (payload) => {
  return {
    type: DELETE_EMPLOYEE_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const deleteEmployeeRequestFailed = (json) => {
  return {
    type: DELETE_EMPLOYEE_REQUEST_FAILED,
    error: json
  }
};

export const updateEmployeeRequest = () => {
  return {
    type: UPDATE_EMPLOYEE_REQUEST
  }
};

export const updateEmployeeRequestSucceeded = (payload) => {
  return {
    type: UPDATE_EMPLOYEE_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const updateEmployeeRequestFailed = (json) => {
  return {
    type: UPDATE_EMPLOYEE_REQUEST_FAILED,
    error: json
  }
};

export const addEmployeesRequest = () => {
  return {
    type: ADD_EMPLOYEES_REQUEST
  }
};

export const addEmployeesRequestSucceeded = (payload) => {
  return {
    type: ADD_EMPLOYEES_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const addEmployeesRequestFailed = (json) => {
  return {
    type: ADD_EMPLOYEES_REQUEST_FAILED,
    error: json
  }
};

export const employeesMetrics = () => {
  return {
    type: EMPLOYEES_METRICS_REQUEST
  }
}

export const employeesMetricsSucceeded = (payload) => {
  return {
    type: EMPLOYEES_METRICS_SUCCEEDED,
    data: payload
  }
};

export const employeesMetricsFailed = (json) => {
  return {
    type: EMPLOYEES_METRICS_FAILED,
    error: json
  }
};
