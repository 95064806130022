
export const abbreviationMonths = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ"
];

export const getChartData = (data) => {
  data = Array.isArray(data) ? sortDatesAscending(data) : data;
  const labels = [];
  const average = [];
  const min = [];
  const max = [];
  if (data) {
    for (let item of data) {
      const month = Number(item.date?.split("-")[1]);
      labels.push(abbreviationMonths[month - 1]);
      average.push(item.average);
      min.push(item.min);
      max.push(item.max);
    }
  }

  return {labels, average, min, max};
};

const sortDatesAscending = (chartData) => {
  return chartData?.sort((a, b) => new Date(a.date) - new Date(b.date));
};
