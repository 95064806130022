import React, { useCallback, useEffect, useState } from "react";
import EditManager from "../EditManager/EditManager";
import ManagerInfo from "../ManagerInfo/ManagerInfo";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { updateManager } from "../../../redux/actions/managers";
import Loading from "../../global/Loading/Loading";
import "./EditManagerContainer.scss";

const EditManagerContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [department, setDepartment] = useState([]);
  const [manager, setManager] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const state = location.state;

    if (!state || !state?.manager) {
      return history.push("/acessos/colaboradores");
    }

    const { manager } = state.manager;
    setManager(manager);
    setDepartment(state.manager.units);
    setIsLoading(false);
  }, [history, location.state]);

  const updateDepartmentState = useCallback(
    (value) =>
      setDepartment((prevState) =>
        prevState.includes(value)
          ? prevState.filter((item) => item !== value)
          : value === "*"
          ? [value]
          : [...prevState, value]
      ),
    []
  );

  const handleManagerUpdate = () => {
    setIsLoading(true);
    const payload = {
      units: department.includes("*") ? ["*"] : [...department],
    };
    const managerId = location.state.manager.id;
    dispatch(updateManager(managerId, payload)).then(() => {
      setIsLoading(false);
      return history.push("/acessos/gestores");
    }).catch(() => setIsLoading(false));
  };

  return (
    <div className="EditManagerContainer">
      <EditManager managerEmail={manager.email} managerName={manager.name} />
      <ManagerInfo
        department={department}
        setDepartment={updateDepartmentState}
        handleUpdate={handleManagerUpdate}
      />
      <Loading visible={isLoading}/>
    </div>
  );
};

export default EditManagerContainer;
