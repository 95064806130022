import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as DownArrow } from "../../../assets/images/negative-variation.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/positive-variation.svg";
import "./Grade.scss";

const Grade = ({ value, variation, simplified, maxGrade }) => {
  const getVariationIcon = variation => {
    if (variation < 0) {
      return <DownArrow />;
    } else if (variation > 0) {
      return <UpArrow />;
    } else {
      return <span className="stagnant-symbol" />;
    }
  };
  return (
    <p
      className={`Grade ${!simplified ? (variation > 0 ? "positive" : "negative") : ""
        }`}
    >
      {value ? value?.toFixed(1) : "0"}
      {!simplified && getVariationIcon(variation)}
      {maxGrade && <span className="maxGrade">/{maxGrade}</span>}
    </p>
  );
};

Grade.propTypes = {
  value: PropTypes.string.isRequired
};

export default Grade;
