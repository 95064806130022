export const schoolSelect = [
  { label: "Infra", value: "Infra" },
  { label: "Dev", value: "Dev" },
  { label: "Dados", value: "Dados" }
]


export const pillars = {
  "Infra": [
    { label: "DevOps", value: "DevOps" },
    { label: "DevSecOps", value: "DevSecOps" },
    { label: "LGPD", value: "LGPD" }
  ],
  "Dev": [
    { label: "Boletos", value: "Boletos" },
    { label: "Emprestimo", value: "Emprestimo" },
    { label: "Interno", value: "Interno" }
  ],
  "Dados": [
    { label: "Produtos Externos e Produtos Internos", value: "PRODUTOS_EXTERNOS" },
  ]
}

export const translateGestorLevel = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "ADMIN":
      return "Administrador";
    case "COORDINATOR":
      return "Coordenador";
    case "MANAGER":
      return "Gerente";
    default:
      return;
  }
};

export const translateGestorLevels = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "Administrador":
      return "ADMIN";
    case "Coordenador":
      return "COORDINATOR";
    case "Gerente":
      return "MANAGER"
    default:
      return;
  }
};