import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiInviteEmployeeRequest = (token, company_id, payload) => {
  return fetch(`${baseUrl}/company/${company_id}/employees/invite`,
    {
      method: "POST",
      headers: buildHeaders(token),
      body: JSON.stringify(payload)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiInviteManagerRequest = (token, company_id, payload) => {
  return authorizedFetch( `${ baseUrl }/company/${ company_id }/managers/invite`,
    {
      method: "POST",
      headers: buildHeaders( token ),
      body: JSON.stringify( payload )
    } )
    .then( getResponseOrThrow )
    .catch( ( error ) => Promise.reject( error ) );
}

export const apiGetManagerInviteRequest = (id) => {
  return fetch(`${baseUrl}/managers/invite/${id}`,
    {
      method: "GET",
      headers: buildHeaders()
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiAcceptInviteRequest = (id, payload) => {
  return fetch(`${baseUrl}/managers/invite/${id}`,
    {
      method: "PUT",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    })
    .then((res) => {
      if ( res.status === 204 ) {
        return Promise.resolve();
      }
    })
    .catch((error) => Promise.reject(error));
};
