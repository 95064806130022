import React from "react";
import "./Logo.scss";
import {ReactComponent as LogoSvg} from "../../../assets/images/logo.svg";

const Logo = () => {
  return (
    <div className="Logo">
      <LogoSvg/>
    </div>
  );
};

export default Logo;
