import { getItemFromStorage } from "../../helper/storage";
import {
  createNotifyRequest,
  createNotifyRequestFailed,
  createNotifyRequestSucceeded
} from "../action-creators/notify";
import { apiCreateNotifyRequest } from "../api/notify";

export const createNotify = payload => async dispatch => {
  dispatch(createNotifyRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiCreateNotifyRequest(token, company_id, payload);
    dispatch(createNotifyRequestSucceeded(json));
    return await Promise.resolve();
  } catch (response) {
    dispatch(createNotifyRequestFailed(response));
    return await Promise.reject(response);
  }
};
