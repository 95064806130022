import {
  MANAGERS_REQUEST,
  MANAGERS_REQUEST_FAILED,
  MANAGERS_REQUEST_SUCCEEDED
} from "../constants/managers";

export const managers = (
  state = {
    isFetching: false,
    data: [],
    error: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case MANAGERS_REQUEST:
      newState.isFetching = true;
      return newState;

    case MANAGERS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case MANAGERS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
