import React, {useState, useEffect} from "react";
import "./AbilitiesAnalysis.scss";
import {useDispatch, useSelector} from "react-redux";
import LineChart from "../LineChart/LineChart";
import Card from "../../global/Card/Card";
import CompanyAbilitiesTable from "../CompanyAbilitiesTable/CompanyAbilitiesTable";
import {getTopicEvolution} from "../../../redux/actions/topics";

const AbilitiesAnalysis = () => {
  const [selectedAbility, setSelectedAbility] = useState(-1);
  const demo = useSelector(state => state.demo);
  const topics = useSelector(state => state.topics);
  const dispatch = useDispatch();

  const getChartData = () => {
    if (demo.isDemoMode && selectedAbility !== -1) {
      const topicIndex = demo.data.topics.topicsList.findIndex(item => item.topic.id === selectedAbility);
      const lastMonth = [12];
      const hasTopicEvolution = demo.data.topics.evolution[topicIndex]?.length > 0;
      const topicEvolutionMonths = hasTopicEvolution
      ? demo.data.topics.evolution[topicIndex].map((item) => new Date(item.date).getMonth())
      : lastMonth;
      const mockedData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        .filter(item => !topicEvolutionMonths.includes(item))
        .map(item => (
          {
            date: new Date(new Date().getFullYear(), item, 1).toISOString().split("T")[0], 
            average: 0,
            min: 0,
            max: 0
          }
      ));
      const chartData = [...demo.data.topics.evolution[topicIndex], ...mockedData]
      return chartData;
    }
    const lastMonth = [12]
    const hasTopicEvolution = topics.evolution.data.length > 0;
    const topicEvolutionMonths = hasTopicEvolution
      ? topics.evolution.data.map((item) => new Date(item.date).getMonth())
      : lastMonth;
    const mockedData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      .filter(item => !topicEvolutionMonths.includes(item))
      .map(item => (
        {
          date: new Date(new Date().getFullYear(), item, 1).toISOString(), 
          average: 0,
          min: 0,
          max: 0
        }
    ));
    const chartData = [...topics.evolution.data, ...mockedData]
    return chartData;
  };

  useEffect(() => {
    if(selectedAbility === -1) {
      if (!demo.isDemoMode && topics.topicsList.data && topics.topicsList.data.length > 0) {
        setSelectedAbility(topics.topicsList.data[0].topic.id);
      } else {
        setSelectedAbility(demo.data.topics?.topicsList[0].topic.id)
      }
    }
  }, [topics?.topicsList.data, demo.isDemoMode, demo.data.topics?.topicsList])

  useEffect(() => {
    if(selectedAbility > 0 && !demo.isDemoMode) { // prevent from -1 (default value) being requested
      dispatch(getTopicEvolution(selectedAbility))
    }
  }, [selectedAbility, dispatch, demo.isDemoMode])

  return (
    <section className="AbilitiesAnalysis">
      <h2>Investimentos dos integrantes</h2>
      <p className="subtitle">Aqui, os investimentos são as competências da equipe. O patrimônio de cada um é seu conhecimento. Cada
        colaborador seleciona os investimentos que possui ou deseja desenvolver e realiza uma auto-avaliação, atribuindo
        um valor de proficiência naquela competência.</p>
      <Card container>
      <div className="filters">
        <p>{demo.isDemoMode ? demo.data.topics.topicsList.length : topics.topicsList.data.length} competência{(demo.isDemoMode ? demo.data.topics.topicsList.length : topics.topicsList.data?.length > 1) || topics.topicsList.data?.length === 0 ? "s" : ""}</p>
      </div>
        <div className="chart-container">
          {demo.data.topics?.topicsList.length > 0 || topics.topicsList?.data.length > 0 ? (
            <CompanyAbilitiesTable
              companyAbilities={demo.isDemoMode ? demo.data.topics?.topicsList : topics.topicsList.data}
              selectedAbility={selectedAbility}
              setSelectedAbility={setSelectedAbility}
            />
          ) : (<p>Nenhuma competencia a ser exibida!</p>)}
          <div className="line-chart">
            <LineChart data={getChartData()}/>
          </div>
        </div>
      </Card>
    </section>
  );
};

export default AbilitiesAnalysis;
