import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiAccessesRequest = (token, manager_id) => {
  return authorizedFetch(`${baseUrl}/manager/${manager_id}/accesses`,
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};
