import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import microsoft from '../../../assets/images/microsoft-icon.svg';
import { getItemFromStorage, removeItemFromStorage, saveItemInStorage } from "../../../helper/storage";
import { getDemo, setDemoModeOn } from "../../../redux/actions/demo";
import { acceptInvite, getManagerInvite } from "../../../redux/actions/invites";
import { createLoginWitMicrosoftRequest, login } from "../../../redux/actions/login";
import Loading from "../../global/Loading/Loading";
import PopUpCompany from "../../global/PopUpCompany/PopUpCompany";
import Button from "../../global/form/Button/Button";
import Checkbox from "../../global/form/Checkbox/Checkbox";
import Input from "../../global/form/Input/Input";
import GoogleSignIn from "../GoogleSignIn/GoogleSignIn";
import LinkedinSignIn from "../LinkedinSignIn/LinkedinSignIn";
import "./Login.scss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepLogged, setKeepLogged] = useState(false);
  const [error, setError] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const inviteInfo = useSelector(state => state.invites);
  const inviteId = location.search.split("code=")[1];
  const verificationMicrosoft = location.hash.includes("code")
  const [stopVerification, setStopVerification] = useState(false);
  const invitationID = getItemFromStorage("INVITE_ID")

  const handleMicrosoft = () => {
    const handleLoginMicrosoft = () => {
      const loginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=4e243692-2927-48cc-af80-73458c25b6df&scope=user.read%20openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fe2w-gestor.mastertech.com.br%2Flogin&response_mode=fragment&response_type=code&prompt=consent`;
      window.open(loginUrl, '_blank');
    };

    handleLoginMicrosoft();
  }

  const handleLogin = (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      password: password
    }
    if (email === "e2w@gestor.com.br" && password === "e2w@gestor") {
      dispatch(setDemoModeOn())
      dispatch(getDemo()).then(() => history.push("/selecionar-empresa"));
    } else {
      setIsLoading(true);
      dispatch(login(payload, keepLogged))
        .then(() => {
          if (inviteId) {
            dispatch(acceptInvite(inviteId, {
              accept: true
            })).then(() => {
              setIsLoading(false);
              setPopUp(true);
            });
          } else {
            setIsLoading(false);
            history.push("/selecionar-empresa");
          }
        })
        .catch(() => {
          setError(true);
          setIsLoading(false);
          setEmail("");
          setPassword("");
          setTimeout(() => {
            setError(false);
          }, 3000)
        });
    }
  }

  const handlePopUpClose = () => {
    setPopUp(false);
    history.push("/selecionar-empresa");
  }

  useEffect(() => {
    const handleMicrosoftLogin = async () => {
      setIsLoading(true);
      setStopVerification(true)
      const hashValue = location.hash.substr(6)?.split('&session_state=')[0];
      if (hashValue) {
        const microsoftData = {
          code: hashValue,
          redirectUri: "https://e2w-gestor.mastertech.com.br/login",
        };

        dispatch(createLoginWitMicrosoftRequest(microsoftData)).then(() => {

          if (invitationID) {
            let managerInvite = dispatch(getManagerInvite(invitationID))
            let inviteAccept = dispatch(acceptInvite(invitationID, {
              accept: true
            }))

            Promise.all([managerInvite, inviteAccept]).then(() => {
              setIsLoading(false);
              removeItemFromStorage("INVITE_ID");
              setPopUp(true);
            })
          } else {
            setIsLoading(false);
            history.push("/selecionar-empresa");
          }
          setIsLoading(false);
        })
      }
    };

    if (!stopVerification && verificationMicrosoft) {
      handleMicrosoftLogin();
    }

  }, [verificationMicrosoft]);

  useEffect(() => {
    if (inviteId) {
      setIsLoading(true)
      dispatch(getManagerInvite(inviteId))
        .then(() => {
          saveItemInStorage("INVITE_ID", inviteId);
          setIsLoading(false)
        })
    }
  }, [inviteId, dispatch])

  return (
    <section className="Login">
      <h1 hidden>E2W <span>ADMIN</span></h1>
      <article>
        <h2>Olá,</h2>
        <p>Por favor, insira seu e-mail e senha</p>
        <p>para fazer login ou crie a sua conta.</p>
      </article>
      {inviteId && (
        <div className="welcome-text">
          {inviteInfo.isFetching && <h3>Carregando...</h3>}
          {!inviteInfo.isFetching && !inviteInfo.data.company && <h3>Ops! Parece que você acessou o link de um convite expirado ou inválido.</h3>}
          {inviteInfo.data.company && <>
            <img src={inviteInfo.data.company && inviteInfo.data.company.icon} />
            <div>
              <h3>Olá.</h3>
              <h3>Realize seu login </h3>
              <h3>para se tornar</h3>
              <h3>um administrador</h3>
              <h3>da {inviteInfo.data.company && inviteInfo.data.company.name}</h3>
            </div>
          </>}
        </div>
      )}
      <form onSubmit={handleLogin}>
        <p className={`feedback ${error ? "visible" : null}`}>
          Usuário ou senha incorretos
        </p>
        <Input
          updateState={setEmail}
          type="email"
          displayError={false}
          displayErrorMessage={false}
          errorMessage="E-mail inválido"
          label="E-mail"
          value={email} />
        <Input
          updateState={setPassword}
          type="password"
          displayError={false}
          displayErrorMessage={false}
          errorMessage=""
          label="Senha"
          value={password} />
        <div className="actions">
          <Checkbox
            label="Mantenha-me logado"
            updateState={setKeepLogged}
            value={keepLogged}
          />
          <button
            type={"button"}
            className="forgot-password"
            onClick={() => history.push("/recuperar-senha")}
          >
            esqueci minha senha
          </button>
        </div>
        <div className="buttons">
          <div onClick={e => handleMicrosoft(e)} role="button" className="microsoft-button">
            <img src={microsoft} alt="microsoft" />
            <p className="text-microsoft">entrar com microsoft</p>
          </div>
          {/* <div className="container-buttons">
            <GoogleSignIn />
            <LinkedinSignIn text={"login com linkedin"} />
          </div> */}
          <Button type={"submit"} bottom onClick={(e) => handleLogin(e)}>
            entrar
          </Button>
          <Button type={"button"} linkStyle onClick={() => inviteId ? history.push("/cadastro?code=" + inviteId) : history.push("/cadastro")}>
            não possuo cadastro
          </Button>
        </div>
      </form>
      <PopUpCompany
        company={inviteInfo.data.company && inviteInfo.data.company.name} active={popUp}
        closeCompany={() => {
          handlePopUpClose()
        }} />
      <Loading visible={login.isFetching || isLoading} />
    </section>
  );
}

export default Login;
