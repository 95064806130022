import { saveItemInStorage } from "../../helper/storage";
import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiLogin = (payload) => {
  return fetch(`${baseUrl}/manager/login`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(() => Promise.reject());
};

export const apiRefreshLogin = (refreshToken) => {
  return fetch(`${baseUrl}/manager/token/refresh`, {
    method: "POST",
    headers: buildHeaders(refreshToken)
  })
    .then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(() => Promise.reject());
};

export const apiGoogleLogin = payload => {
  return fetch(`${baseUrl}/manager/googleauth`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  })
    .then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(() => Promise.reject());
};

export const apiLinkedInLogin = payload =>
  fetch(`${baseUrl}/manager/linkedinauth`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  })
    .then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    });


export const apiMicrosoftLogin = payload =>
  fetch(`${baseUrl}/manager/microsoftauth`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  })
    .then(getResponseOrThrow)
    .then(json => {
      saveItemInStorage("TOKEN", json.token, true);
      saveItemInStorage("REFRESH_TOKEN", json.refreshToken, true);
      saveItemInStorage("MANAGER_ID", json.manager.id, true);
      saveItemInStorage("LAST_LOGIN", Date.now(), true);

      return Promise.resolve(json);
    })
    .catch(() => Promise.reject());