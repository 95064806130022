import React from "react";
import "./NotFoundPage.scss";
import {useHistory} from "react-router";
import Button from "../global/form/Button/Button";

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <section className="NotFoundPage">
      <div className="notfound-container">
        <div>
          <h2>404</h2>
        </div>
        <div>
          <p>
            Página não encontrada
          </p>
          <p>
            Essa página não está disponível.
            Caso você tenha vindo de dentro do sistema, por favor contacte reporte a&nbsp;
            <a className="tm-email" href="mailto:suporte@mastertech.com.br">
              suporte@mastertech.com.br
            </a>{" "}
            para informar o problema.
          </p>
          <div className="button-notfound">
            <Button onClick={() => history.push("/")}>
              Voltar para Home
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
};

export default NotFoundPage;
