export const definitions = [
  {
    question: "Não consigo editar as aportes dos meu profissionais",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica",
    category: "home"
  },
  {
    question: "Não consigo adicionar outro administrador",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "home"
  },
  {
    question: "Não consigo ver os gráficos de evolução",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "home"
  },
  {
    question: "Como posso editar o meu perfil?",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "home"
  },
  {
    question: "Não consigo editar as aportes dos meu profissionais",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "investimento"
  },
  {
    question: "Não consigo adicionar outro administrador",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "investimento"
  },
  {
    question: "Não consigo ver os gráficos de evolução",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "investimento"
  },
  {
    question: "Como posso editar o meu perfil?",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "investimento"
  },
  {
    question: "Não consigo adicionar outro administrador",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "aporte"
  },
  {
    question: "Não consigo ver os gráficos de evolução",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "aporte"
  },
  {
    question: "Como posso editar o meu perfil?",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "aporte"
  },
  {
    question: "Não consigo editar as aportes dos meu profissionais",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica",
    category: "reavaliação"
  },
  {
    question: "Não consigo adicionar outro administrador",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "reavaliação"
  },
  {
    question: "Não consigo ver os gráficos de evolução",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "reavaliação"
  },
  {
    question: "Como posso editar o meu perfil?",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "reavaliação"
  },
  {
    question: "Não consigo editar as aportes dos meu profissionais",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "extrato"
  },
  {
    question: "Não consigo adicionar outro administrador",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "extrato"
  },
  {
    question: "Não consigo ver os gráficos de evolução",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "extrato"
  },
  {
    question: "Como posso editar o meu perfil?",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "extrato"
  },
  {
    question: "Não consigo adicionar outro administrador",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "perfil"
  },
  {
    question: "Não consigo ver os gráficos de evolução",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "perfil"
  },
  {
    question: "Como posso editar o meu perfil?",
    answer:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica" +
      " e de impressos, e vem sendo utilizado desde o século XVI",
    category: "perfil"
  }
];

export default definitions;
