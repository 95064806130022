import React from 'react';
import Table from '../../global/Table/Table';
import './InvestorsInInvestment.scss';

const InvestorsInInvestment = ({ investmentDetails }) => {

  return (
    <div className='InvestorsInInvestment'>
      <div className="header-investor">
        <p>Investidores</p>
        <p>{investmentDetails?.investors ? investmentDetails?.investors?.length : 0} integrantes da comunidade Mastertech</p>
      </div>
      <div className="investors-list">
        <Table
          className="investors-table"
          headers={[
            "INVESTIDOR",
            "ÁREA",
            "NÍVEL",
            "VALOR",
            "APORTES",
            "REAVALIAÇÕES",
            "ÚLTIMA ATUALIZAÇÃO",
          ]}
        >
          {investmentDetails?.investors ? investmentDetails?.investors?.map((investor) => (
            <tr key={investor?.id}>
              <td>{investor?.person.name}</td>
              <td>{investor?.unit}</td>
              <td>{investor?.role}</td>
              <td>{investor?.person.metrics.affiliations}</td>
              <td>{investor?.person.metrics.taskExecutionCount} aportes</td>
              <td>{investor?.person.metrics.pendingEvaluations} reavaliações</td>
              <td>
                {investor?.person.lastEvaluation ?
                  new Date(investor?.person.lastEvaluation).toLocaleDateString('pt-BR') :
                  "Nenhuma avaliação encontrada"
                }
              </td>
            </tr>
          )) : <p style={{ textAlign: "center" }}>Nenhuma avaliação encontrada</p>}
        </Table>
      </div>
    </div>
  )
}

export default InvestorsInInvestment
