import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiCreateNotifyRequest = async (token, company_id, payload) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/company/${company_id}/notification`,
      {
        method: "POST",
        headers: buildHeaders(token),
        body: JSON.stringify(payload)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
}
