import React, {useState} from 'react';
import "./Support.scss";
import BrowseFaq from "../BrowseFaq/BrowseFaq";
import Questions from "../Questions/Questions";

const Support = () => {
  const [category, setCategory] = useState("");
  const [userSearch, setUserSearch] = useState("");

  return (
    <div className="Support">
      <BrowseFaq category={category} setCategory={setCategory} setUserSearch={setUserSearch}/>
      <Questions category={category} userSearch={userSearch}/>
    </div>
  );
};

export default Support;
