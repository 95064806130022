import React, { useState } from 'react';
import arrowImage from '../../../../assets/images/select-down-arrow.svg';
import './MultiSelect.scss';

const MultiSelect = ({ options, title, label, onSelectChange, values }) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleToggleOptions = () => {
    if (options && options?.filter((option) => !values.includes(option.value)).length !== 0)
      setShowOptions(!showOptions);
  };
  
  const handleOptionToggle = (data) => {
    if (values.some(selected => selected.value === data.value)) {
      onSelectChange(values.filter((selected) => selected.value !== data.value));
    } else {
      onSelectChange([...values, data]);
    }
  };

  const handleRemoveOption = (value) => {
    onSelectChange(values.filter((selected) => selected.value !== value.value));
  };

  return (
    <div className='MultiSelect'>
      <div className='container-select' style={{ position: 'relative' }}>
        <label >{label}</label>
        <div className='box-select' onClick={handleToggleOptions} >
          {values.length <= 0 && <p className='title-select'>{title}</p>}
          {values.map((selectedOption) => (
            <div className='button-selected' key={selectedOption.value}>
              <p>{selectedOption.label}</p>
              <div onClick={() => { handleRemoveOption(selectedOption); setShowOptions(false) }} role="button" className="button" aria-label="Remove Purple"><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg></div>
            </div>
          ))}
          <img className='arrow-image' src={arrowImage} alt="" />
        </div>
      </div>


      {showOptions && (
        <div className='modal-select'
        >
          {options
            .filter((option) => !values.some(selected => selected.value === option.value))
            .map((option, key) => (
              <div className='submit-button' key={key} onClick={() => {
                handleOptionToggle(option);
                handleToggleOptions()
              }}>
                <p>{option.label}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
