import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiPersonExecutionsRequest = (token, personId) => {
  return authorizedFetch(`${baseUrl}/person/${personId}/executions`,
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};
