import React from 'react';
import PopUp from "../PopUp/PopUp";
import Button from "../form/Button/Button";
import './PopUpCompany.scss';

const PopUpCompany = ({active, company, closeCompany}) => {
  return (
    <PopUp active={active}>
      <div className="popup-image-company"/>
      <div className="popup-company">
        <p>{company} foi adicionada</p>
        <p>À lista de empresas nas quais</p>
        <p>você administra</p>
        <Button onClick={closeCompany}>OK</Button>
      </div>
    </PopUp>
  );
};

export default PopUpCompany;
