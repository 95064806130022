import React, { useEffect, useState } from "react";
import BackButton from "../../global/BackButton/BackButton";
import Breadcrumb from "../../global/Breadcrumb/Breadcrumb";
import Tag from "../../global/Tag/Tag";
import Input from "../../global/form/Input/Input";
import "./IncludeNewPeople.scss";

const IncludeNewPeople = ({ units, setUnits, setEmail, levelsAccesses, setLevelsAccesses }) => {
  const [valueInput, setValueInput] = useState("");
  useEffect(() => {
    setEmail(valueInput);
  }, [valueInput, setEmail]);

  const handleUnitClick = (selectedItem) => {
    setUnits((prevState) =>
      prevState.includes(selectedItem)
        ? prevState.filter((item) => item !== selectedItem)
        : [...prevState, selectedItem]
    );
  };

  return (
    <div className="IncludeNewPeople">
      <Breadcrumb
        crumbs={["gestão de acessos", "gestores", "convite por email"]}
      />
      <BackButton />
      <div className="container-new-people">
        <h2>Para quem você vai enviar esse convite?</h2>
        <div className="input-new-people">
          <span>Para</span>
          <Input
            value={valueInput}
            updateState={setValueInput}
            type="email"
            displayErrorMessage={false}
            required
          />
        </div>
      </div>
      <h2>Níveis</h2>
      {levelsAccesses?.length >= 1 && <Tag name={levelsAccesses} onClick={() => setLevelsAccesses("")} />}
      <h2>Departamentos</h2>
      <div className="tags-container">
        {units?.map((unit, key) => (
          <Tag key={key} name={unit} onClick={handleUnitClick} />
        ))}
      </div>
    </div>
  );
};

export default IncludeNewPeople;
