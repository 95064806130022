import React, {useEffect, useState} from 'react';
import "./InviteEmployee.scss";
import Input from "../../global/form/Input/Input";
import Breadcrumb from "../../global/Breadcrumb/Breadcrumb";
import BackButton from "../../global/BackButton/BackButton";
import Tag from "../../global/Tag/Tag";

const InviteEmployee = ({employeeEmail, unit, role, setRole, setUnit, setEmail}) => {
  const [valueInput, setValueInput] = useState("");

  useEffect(() => {
    setEmail(valueInput);
  }, [valueInput, setEmail]);

  useEffect(() => {
    setValueInput(employeeEmail);
  }, [employeeEmail]);

  return (
    <div className="InviteEmployee">
      <Breadcrumb
        crumbs={["gestão de acessos", "colaborador", "convite por email"]}
      />
      <BackButton />
      <div className="container-new-people">
        <h2>Para quem você vai enviar esse convite?</h2>
        <div className="input-new-people">
          <span>Para</span>
          <Input
            value={valueInput}
            updateState={setValueInput}
            type="email"
            displayErrorMessage={true}
          />
        </div>
      </div>
      <h2>Departamentos</h2>
      <div className="tags-container">
        <Tag name={unit} onClick={setUnit} />
      </div>
      <h2>Cargos e funções</h2>
      <div className="tags-container">
        <Tag name={role} onClick={setRole} />
      </div>
    </div>
  );
}

export default InviteEmployee;
