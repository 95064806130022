import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ min, max, value, label, secondaryColor }) => {
  return (
    <progress
      className={`ProgressBar ${label ? "label" : ""} ${
        secondaryColor ? "secondaryColor" : ""
      }`}
      value={value}
      max={max}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      data-name={label}
    />
  );
};

export default ProgressBar;
