import React from 'react';
import "./AcceptInvite.scss";
import Button from "../../global/form/Button/Button";
import {useHistory} from "react-router-dom";

const AcceptInvite = () => {
  const name = "mastertech";
  const history = useHistory();
  const item = {};

  return (
    <section className="AcceptInvite">
      <h2>Você foi adicionado como administrador da empresa {name}</h2>
      <div className="card-selectcompany"
           style={{backgroundImage: `url(${item && item.background})`}}>
      </div>
      <Button onClick={() => history.push("/")} text="continuar"/>
    </section>
  );
};

export default AcceptInvite;
