import React from "react";
import "./Table.scss";

const Table = ({children, headers, className}) => {
  return (
    <table className={`Table ${className ? className : ""}`}>
      {headers && (
        <thead>
        <tr>
          {headers?.map((header, key) => (
            <th key={key}>{header}</th>
          ))}
        </tr>
        </thead>
      )}
      <tbody> {children}</tbody>
    </table>
  );
};

export default Table;
