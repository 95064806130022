import React, { useState } from "react";
import "./Departments.scss"
import InformationCheckbox from "../InformationCheckbox/InformationCheckbox";
import Input from "../../global/form/Input/Input"
import Button from "../../global/form/Button/Button";

const Departments = ({department, setDepartment, units, addNewUnit, showAddNewUnit}) => {
  const [newDepartment, setNewDepartment] = useState("");

  const createDepartment = (e) => {
    e.stopPropagation();
    
    if (newDepartment === "*") {
      setNewDepartment("");
      return;
    }

    const newUnit = {text: newDepartment, checkbox: true}
    addNewUnit((prevState) => [...prevState, newUnit]);
    setDepartment(newDepartment);
    setNewDepartment("");
  }

  return (
    <div className="Departments">
      <h2>Departamentos</h2>
      <div className="container-dpt">
        <div className="wrapper-dpt">
          {units?.map((item, key) => (
            <InformationCheckbox
              item={item}
              key={key}
              updateState={setDepartment}
              currentValue={department}
            />
          ))}
        </div>
        {showAddNewUnit && (
          <div className="create-dpt">
            <Input
              label="Criar departamento"
              updateState={setNewDepartment}
              value={newDepartment}
              placeholder="Digite o nome departamento"
            />
            {newDepartment.length > 0 && (
              <Button onClick={createDepartment}>Salvar</Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Departments;
