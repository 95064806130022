export const translateContentTypeEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "TEXT":
      return "Texto";
    case "AUDIO":
      return "Áudio";
    case "VISUAL_EXPOSURE":
      return "Aula";
    case "HANDS_ON":
      return "Vivência";
    case "VIDEO":
      return "Vídeo";
    default:
      return "";
  }
};

export const translateAcquistionMethodEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "PAID":
      return "Pago";
    case "FREE":
      return "Grátis";
    default:
      return "";
  }
};

export const translateTaskEstimateEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "LESS_THAN_3_HOURS":
      return "Menos de 3 horas";
    case "MORE_THAN_3_HOURS":
      return "Mais de 3 horas";
    default:
      return "";
  }
};

export const translateChoiceMotiveEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "SELF_INITIATIVE":
      return "Iniciativa própria";
    case "IMPOSED":
      return "Sugestão ou orientação de alguém";
    default:
      return "";
  }
};

export const translateTaskMediumEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "REMOTE":
      return "Remoto";
    case "FACE_TO_FACE":
      return "Presencial";
    default:
      return "";
  }
};

export const translateSkillKindEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "HARD":
      return "Hard skills";
    case "SOFT":
      return "Soft Skills";
    case "BOTH":
      return "Um pouco de tudo";
    default:
      return "";
  }
};

export const translateStatusEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "PENDING":
      return "Pendente";
    case "DOING":
      return "Fazendo";
    case "COMPLETE":
      return "Concluído";
    case "DISMISS":
      return "Cancelada";
    default:
      return "";
  }
};

export const translateContentFormatEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "LIVE":
      return "Em tempo real";
    case "RECORDED":
      return "Remoto";
    default:
      return "Não informado";
  }
};

export const translateExecutionFormatEnum = enumToBeTranslated => {
  switch (enumToBeTranslated) {
    case "IN_GROUP":
      return "Em grupo";
    case "BY_MYSELF":
      return "Individual";
    default:
      return "";
  }
};
