import { accessesRequest, accessesRequestFailed, accessesRequestSucceeded } from "../action-creators/accesses";
import { apiAccessesRequest } from "../api/accesses";
import { getItemFromStorage } from "../../helper/storage";

const createAccessesRequest = () => dispatch => {
  dispatch( accessesRequest() );
  const token = getItemFromStorage( "TOKEN" );
  const manager_id = getItemFromStorage( "MANAGER_ID" )
  return apiAccessesRequest( token, manager_id )
    .then( json => {
      dispatch( accessesRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( accessesRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const getAccesses = () => dispatch =>
  dispatch( createAccessesRequest() );
