import {
  GET_TRENDING_REQUEST,
  GET_TRENDING_REQUEST_FAILED,
  GET_TRENDING_REQUEST_SUCCEEDED
} from "../constants/trending";

export const trendingRequest = () => ({
  type: GET_TRENDING_REQUEST
});

export const trendingRequestSucceeded = json => ({
  type: GET_TRENDING_REQUEST_SUCCEEDED,
  data: json
});

export const trendingRequestFailed = json => ({
  type: GET_TRENDING_REQUEST_FAILED,
  error: json
});
