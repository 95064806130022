import {
  CREATE_NOTIFY_REQUEST,
  CREATE_NOTIFY_REQUEST_FAILED,
  CREATE_NOTIFY_REQUEST_SUCCEEDED
} from "../constants/notify";

export const createNotifyRequest = () => {
  return {
    type: CREATE_NOTIFY_REQUEST
  }
};

export const createNotifyRequestSucceeded = (payload) => {
  return {
    type: CREATE_NOTIFY_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const createNotifyRequestFailed = (json) => {
  return {
    type: CREATE_NOTIFY_REQUEST_FAILED,
    error: json
  }
};