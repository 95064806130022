import { getItemFromStorage } from "../../helper/storage";
import { createTaskRequest, createTaskRequestFailed, createTaskRequestSucceeded, tasksExecutionsRequest, tasksExecutionsRequestFailed, tasksExecutionsRequestSucceeded, tasksFunctionalityRequest, tasksFunctionalityRequestFailed, tasksFunctionalityRequestSucceeded, tasksRequest, tasksRequestFailed, tasksRequestSucceeded } from "../action-creators/tasks";
import { apiCreateTaskRequest, apiTasksExecutionsRequest, apiTasksFunctionalityRequest, apiTasksRequest } from "../api/tasks";

const createTasksRequest = (filters) => dispatch => {
  dispatch(tasksRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  return apiTasksRequest(token, company_id, filters)
    .then(json => {
      dispatch(tasksRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(tasksRequestFailed(response));
      return Promise.reject(response);
    }
    );
};

export const getTasks = (filters) => dispatch =>
  dispatch(createTasksRequest(filters));

const createTasksExecutionsRequest = (task_id, filters) => dispatch => {
  dispatch(tasksExecutionsRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  return apiTasksExecutionsRequest(token, company_id, task_id, filters)
    .then((json) => {
      dispatch(tasksExecutionsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((response) => {
      dispatch(tasksExecutionsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getTasksExecutions = (task_id, filters) => dispatch => dispatch(
  createTasksExecutionsRequest(task_id, filters)
);

export const createTaskCompany = (payload) => async dispatch => {
  dispatch(createTaskRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiCreateTaskRequest(token, company_id, payload)
    dispatch(createTaskRequestSucceeded(json));
    return await Promise.resolve(json);
  } catch (response) {
    dispatch(createTaskRequestFailed(response));
    return Promise.reject(response);
  }
};

export const getTaskFunctionality = (filters) => async dispatch => {
  dispatch(tasksFunctionalityRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiTasksFunctionalityRequest(token, company_id, filters)
    dispatch(tasksFunctionalityRequestSucceeded(json));
    return await Promise.resolve(json);
  } catch (response) {
    dispatch(tasksFunctionalityRequestFailed(response));
    return Promise.reject(response);
  }
};

