import React, {useEffect, useState} from "react";
import "./CommunityMetricsDistribution.scss";
import {
  translateContentTypeEnum,
  translateSkillKindEnum
} from "../../../helper/tasksEnum";
import {useWindowSizeHook} from "../../../hooks/WindowHooks";

const CommunityMetricsDistribution = ({community, skill}) => {
  const {width} = useWindowSizeHook();
  const [currentDataVisualization, setCurrentDataVisualization] = useState(null);
  useEffect(() => {
    skill ? 
    setCurrentDataVisualization("skillKindDistribution") :
    setCurrentDataVisualization("contentTypeDistribution")
  }, [skill])
  
  const isEmptyData = () => {
    return (
      !community?.[currentDataVisualization] ||
      Object.values(community?.[currentDataVisualization])?.reduce((a, b) => a + b, 0) === 0
    )
  }

  let percentageSum = 0;
  return (
    <div>
      <div className="chart">
        {isEmptyData() ? (
          <div className="error-message">
            <span>Ainda não temos dados suficientes para exibir</span>
          </div>
        ) : (
          <svg
            xmlns="http://w3.org/2000/svg"
            viewBox="25 50 140 60"
            width="200"
            height="60"
          >
            <g>
              <circle
                r="40"
                cx="90"
                cy="70"
                fill="transparent"
                stroke="#EFEFF2"
                strokeWidth=".5"
              />
              {currentDataVisualization === "skillKindDistribution" ? (
                <>
                  <text
                    x="72"
                    y="66.5"
                    fill="#EFEFF2"
                    className="chart-title"
                  >
                    Alocação
                  </text>
                  <text
                    x="70"
                    y="76.5"
                    fill="#EFEFF2"
                    className="chart-title"
                  >
                    de carteira
                  </text>
                </>
              ) : (
                <text x="75" y="74" fill="#EFEFF2" className="chart-title">
                  Aportes
                </text>
              )}
            </g>
            {community?.[currentDataVisualization] &&
            Object.keys(community?.[currentDataVisualization]).map(
              (key, index) => {
                let xText, yText, d, x, y;
                percentageSum += community?.[currentDataVisualization][key];
                const right =
                  percentageSum -
                  community?.[currentDataVisualization][key] / 2 <=
                  25 ||
                  percentageSum -
                  community?.[currentDataVisualization][key] / 2 >=
                  75;
                const top =
                  percentageSum -
                  community?.[currentDataVisualization][key] / 2 >=
                  50;
                const halfPercentage =
                  community?.[currentDataVisualization][key] / 2;
                const degrees =
                  (360 * (percentageSum - halfPercentage)) / 100;
                const radians = degrees * (Math.PI / 180);
                x = 90 + Math.cos(radians) * 40;
                y = 70 + Math.sin(radians) * 40;

                const splitLabel = translateSkillKindEnum(key).split(" ");

                if (right && top) {
                  y = width < 1200 ? y - 3 : y - 20;
                  xText = x + 40;
                  yText = width <= 1200 ? y : y + 4;
                  d = "M0.636719 64.6631L63.8205 1.47925H86.5667";
                } else if (right && !top) {
                  y = width < 1200 ? y - 3 : y;
                  x = width < 1200 ? x : x - 3;
                  xText = width < 1200 ? x + 22 : x + 32;
                  yText = width < 1200 ? y + 20 : y + 25;
                  d = "M0.636719 0.481478L63.8205 63.6653H86.5667";
                } else if (!right && top) {
                  y = width < 1200 ? y - 17 : y - 24;
                  x = x - 32;
                  xText = width < 1200 ? x - 23 : x - 25;
                  yText = width < 1200 ? y + 5 : y;
                  d = "M86.8672 64.6631L23.6834 1.47925H0.937212";
                } else {
                  x = width < 1200 ? x - 20 : x - 35;
                  y = width < 1200 ? y - 3 : y - 2;
                  xText = width < 1200 ? x - 30 : x - 25;
                  yText = width < 1200 ? y + 20 : y + 25;
                  d = "M85.9297 1.00003L22.7459 64.1838H-0.000287771";
                }
                return community?.[currentDataVisualization][key] !== 0 ? (
                  <g key={index}>
                    <circle
                      r="40"
                      cx="90"
                      cy="70"
                      fill="transparent"
                      stroke={`rgba(255, 255, 255, calc(${0.35 /
                      (index + 1)}))`}
                      strokeWidth="15"
                      strokeDasharray={`calc(${(percentageSum *
                        (3.1416 * 80)) /
                      100}) calc(3.1416 * 80)`}
                    />
                    <text fill="#F3A543" x={xText} y={yText}>
                      {community?.[currentDataVisualization][key]}%
                    </text>
                    <text
                      x={xText}
                      y={width < 1200 ? yText + 8 : yText + 10}
                      fill="#EFEFF2"
                    >
                      {currentDataVisualization ===
                      "skillKindDistribution" ? (
                        splitLabel.length === 4 ? (
                          <>
                            <tspan
                              x={xText}
                              y={width < 1200 ? yText - 2 : yText}
                              dy="1.2em"
                            >
                              {`${splitLabel[0]} ${splitLabel[1]}`}
                            </tspan>
                            <tspan
                              x={xText}
                              y={width < 1200 ? yText + 6 : yText + 10}
                              dy="1.2em"
                            >
                              {`${splitLabel[2]} ${splitLabel[3]}`}
                            </tspan>
                          </>
                        ) : (
                          translateSkillKindEnum(key)
                        )
                      ) : (
                        translateContentTypeEnum(key)
                      )}
                    </text>
                    <svg
                      width={width < 1200 ? 20 : 35}
                      height="21"
                      viewBox="0 0 88 66"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x={x}
                      y={y}
                    >
                      <path
                        d={d}
                        stroke="#EFEFF2"
                        strokeWidth="2"
                        strokeDasharray="1 3"
                        strokeLinecap="round"
                      />
                    </svg>
                  </g>
                ) : null;
              }
            )}
          </svg>
        )}
      </div>
    </div>
  );
};

export default CommunityMetricsDistribution;
