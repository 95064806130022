import {
  GOOGLE_LOGIN_FAILED,
  GOOGLE_LOGIN_SUCCEEDED,
  LINKEDIN_DATA_REQUEST,
  LINKEDIN_DATA_REQUEST_FAILED,
  LINKEDIN_DATA_REQUEST_SUCCEEDED,
  LINKEDIN_OAUTH_SUCCEEDED,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGIN_REQUEST_SUCCEEDED,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_GOOGLE_FAILED,
  LOGIN_WITH_GOOGLE_SUCCEEDED, LOGIN_WITH_MICROSOFT, LOGIN_WITH_MICROSOFT_FAILED, LOGIN_WITH_MICROSOFT_SUCCEEDED, LOGOUT_REQUEST, MICROSOFT_LOGIN_FAILED, MICROSOFT_LOGIN_SUCCEEDED
} from "../constants/login";

export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginRequestSucceeded = (json) => ({
  type: LOGIN_REQUEST_SUCCEEDED,
  data: json
});

export const loginRequestFailed = (json) => ({
  type: LOGIN_REQUEST_FAILED,
  error: json
});

export const loginWithGoogle = () => ({
  type: LOGIN_WITH_GOOGLE
});

export const loginWithGoogleSucceeded = json => ({
  type: LOGIN_WITH_GOOGLE_SUCCEEDED,
  data: json
});

export const loginWithGoogleFailed = json => ({
  type: LOGIN_WITH_GOOGLE_FAILED,
  error: json
});

export const googleLoginSucceeded = (googleData, data) => ({
  type: GOOGLE_LOGIN_SUCCEEDED,
  googleData: googleData,
  data: data
});

export const googleLoginFailed = message => ({
  type: GOOGLE_LOGIN_FAILED,
  error: message
});

export const linkedInOAuthSucceeded = code => ({
  type: LINKEDIN_OAUTH_SUCCEEDED,
  data: code
});

export const linkedInDataRequest = () => ({
  type: LINKEDIN_DATA_REQUEST
});

export const linkedInDataRequestSucceeded = json => ({
  type: LINKEDIN_DATA_REQUEST_SUCCEEDED,
  data: json
});

export const linkedInDataRequestFailed = json => ({
  type: LINKEDIN_DATA_REQUEST_FAILED,
  error: json
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

export const loginWithMicrosoft = () => ({
  type: LOGIN_WITH_MICROSOFT
});

export const loginWithMicrosoftSucceeded = json => ({
  type: LOGIN_WITH_MICROSOFT_SUCCEEDED,
  data: json
});

export const loginWithMicrosoftFailed = json => ({
  type: LOGIN_WITH_MICROSOFT_FAILED,
  error: json
});

export const microsoftLoginSucceeded = (microsoftData, data) => ({
  type: MICROSOFT_LOGIN_SUCCEEDED,
  microsoftData: microsoftData,
  data: data
});

export const microsoftLoginFailed = message => ({
  type: MICROSOFT_LOGIN_FAILED,
  error: message
});
