import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiStructureRequest = (token, company_id) => {
  return fetch(`${baseUrl}/company/${company_id}/structure`,
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};
