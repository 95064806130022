import React from "react";
import "./Button.scss";
import PropTypes from "prop-types";

const Button = ({
                  children,
                  onClick,
                  bottom,
                  top,
                  type,
                  secondaryColor,
                  className,
                  hoverInfo,
                  cursorNotAllowed,
                  linkStyle
                }) => (
  <button
    className={`Button ${className ? className : ""} ${
      bottom ? "bottom" : ""
    } ${top ? "top" : ""} ${secondaryColor ? "secondary-color" : ""} ${
      cursorNotAllowed ? "cursor-not-allowed" : ""
    } ${linkStyle ? "link-style" : ""}`}
    type={type}
    onClick={onClick}
  >
    {children}
    {hoverInfo && <p>{hoverInfo}</p>}
  </button>
);

Button.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func
};

export default Button;
