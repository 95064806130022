import React from 'react';
import './DeletePopup.scss';
import Button from "../form/Button/Button";
import PopUp from "../PopUp/PopUp";

const DeletePopup = ({name, active, deleteClick, cancelClick}) => {
  return (
    <PopUp active={active}>
      <div className="DeletePopup">
        <p>Tem certeza que deseja remover {name} ?</p>
        <div className="buttons-popup">
          <Button onClick={deleteClick}>Sim</Button>
          <Button onClick={cancelClick}>Cancelar</Button>
        </div>
      </div>
    </PopUp>
  );
};

export default DeletePopup;
