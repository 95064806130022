import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./TextArea.scss";

const TextArea = ({
                    placeholder,
                    value,
                    updateState,
                    displayError,
                    displayErrorMessage,
                    valid,
                    label,
                    detail,
                    onBlur
                  }) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef();

  const handleChange = e => {
    e.preventDefault();
    const value = ref.current?.value || ref.current?.innerHTML;
    updateState(value);
  };

  return (
    <div className="Textarea">
      <label>{label}</label>
      <p className={displayError && !valid ? "visible" : null}>
        {value === "" ? "Campo obrigatório" : displayErrorMessage}
      </p>
      <span
        ref={ref}
        placeholder={placeholder}
        onKeyUp={e => handleChange(e)}
        contentEditable
        suppressContentEditableWarning
        role="textbox"
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
          onBlur && onBlur();
        }}
        data-name={value === "" && !focused ? placeholder : ""}
      />
      {detail && <p className="detail">{detail}</p>}
    </div>
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default TextArea;
