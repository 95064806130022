import React, { useEffect } from 'react';
import "./ModalInvestments.scss";
import TopicSymbol from "../../global/TopicSymbol/TopicSymbol";
import Grade from "../../global/Grade/Grade";
import {localizeDate, shortYearDateFormat} from "../../../helper/dateHelper";
import { useDispatch } from 'react-redux';
import { topicPersonSummary } from '../../../redux/actions/topics';
import { useSelector } from 'react-redux';

const ModalInvestments = ({ investorId }) => {
  const dispatch = useDispatch();
  const myTopicsData = useSelector((state) => state?.topics?.person?.data);

  useEffect(() => {
    dispatch(topicPersonSummary(investorId))
  }, [dispatch, investorId]);

  return (
    <div className="ModalInvestments">
      <div className="investments">
        <div className="investments-count">
          <h1>Investimentos</h1>
          <p>{myTopicsData?.items?.length} competências</p>
        </div>
        <table>
          <thead>
            <tr>
              <th>Código</th>
              <th>Investimento</th>
              <th>Comunidade</th>
              <th>Investidor</th>
              <th>Aportes</th>
              <th>Última atualização</th>
            </tr>
          </thead>
          <tbody>
            {myTopicsData &&
              myTopicsData?.items?.map((stock, key) => (
                <tr key={key}>
                  <td>
                    <TopicSymbol symbol={stock.topic.symbol} />
                  </td>
                  <td>
                    <p>{stock.topic.name}</p>
                  </td>
                  <td>
                    <Grade
                      value={stock.topic?.tracking?.average || 0}
                      variation={stock.topic?.tracking?.variation || 0}
                    />
                  </td>
                  <td>
                    <Grade
                      value={stock.lastValue}
                      variation={stock.lastValue < 7}
                      simplified
                    />
                  </td>
                  <td>
                    <p>{stock?.activeTasksCount}</p>
                  </td>
                  <td>
                    <p>
                      {stock.lastDate &&
                        shortYearDateFormat(localizeDate(stock.lastDate), "/")}
                    </p>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModalInvestments;
