import React, { useState } from 'react';
import "./CommunityRegister.scss";
import { useHistory } from "react-router-dom";
import CommunityInfo from "../CommunityInfo/CommunityInfo";
import AddInvestors from "../AddInvestors/AddInvestors";
import AddManagers from "../AddManagers/AddManagers";
import CommunityRegisterFinished from "../CommunityRegisterFinished/CommunityRegisterFinished";
import { useTextField } from "../../../hooks/inputHooks";
import { postCompany } from "../../../redux/actions/company";
import { addInvestors } from "../../../redux/actions/employees";
import { inviteManager } from "../../../redux/actions/invites";
import Logo from "../../global/Logo/Logo";
import Card from "../../global/Card/Card";
import Loading from "../../global/Loading/Loading";
import { useDispatch } from "react-redux";
import ProgressBar from "../../global/ProgressBar/ProgressBar";

const CommunityRegister = () => {
  const [step, setStep] = useState(0);
  const [communityName, setCommunityInfo] = useTextField("");
  const [logoFile, setLogoFile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [logoFilePath, setLogoFilePath] = useState("");
  const [employeesFile, setEmployeesFile] = useState("");
  const [managers, setManagers] = useState([""]);
  const stepsQty = 4;
  const history = useHistory();
  const dispatch = useDispatch();

  const nextStep = () => {
    if (step === 1) {
      registerCompany();
    }
    if (step === 2) {
      handleAddInvestors();
    }
    if (step === 3) {
      handleAddManagers().then(() => {
        history.push("/selecionar-empresa");
      });
    }
    step < stepsQty ? setStep(step + 1) : history.push("/");
  }

  const previousStep = () => {
    setStep(step - 1);
  }

  const registerCompany = () => {
    setIsLoading(true);
    const payload = {
      name: communityName,
      file: logoFile
    }
    dispatch(postCompany(payload))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  };

  const handleAddInvestors = () => {
    if (employeesFile instanceof File) {
      setIsLoading(true);
      const payload = {
        file: employeesFile
      };
      dispatch(addInvestors(payload))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  };

  const handleAddManagers = async () => {
    setIsLoading(true);
    for await (let manager of managers) {
      let payload = {
        email: manager,
        units: ["*"]
      }
      dispatch(inviteManager(payload))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false))
    }
  };

  const components = [
    <CommunityInfo
      updateState={setCommunityInfo}
      value={communityName}
      file={logoFile}
      setFile={setLogoFile}
      nextStep={nextStep}
      previousStep={previousStep}
      setFilePath={setLogoFilePath}
    />,
    <AddInvestors
      nextStep={nextStep}
      setFile={setEmployeesFile}
      file={employeesFile}
      previousStep={previousStep}
      filePath={logoFilePath}
      communityName={communityName} />,
    <AddManagers
      nextStep={nextStep}
      setManagers={setManagers}
      managers={managers}
      previousStep={previousStep}
      filePath={logoFilePath}
      communityName={communityName} />,
    <CommunityRegisterFinished nextStep={nextStep} />
  ];
  console.log(step)
  return (
    <section className="CommunityRegister">
      <Loading visible={isLoading} />
      <div className="e2w-principles" style={{visibility: step === 3 ? "hidden" : "visible"}}>
        <Logo />
        <div>
          <h2>Conhecimento é valor.</h2>
          <p>
            Não diminui, não acaba. <br /> É o único investimento<br /> que sempre valoriza.
          </p>
        </div>
        <div className="steps-container">
          <ProgressBar value={step === 3 ? step : step + 1} max={stepsQty - 1} min={1} />
          <div>
            <p className={step + 1 === 1 ? "selected" : ""}>comunidade</p>
            <p className={step + 1 === 2 ? "selected" : ""}>investidores</p>
            <p className={step + 1 === 3 || step + 1 === 4 ? "selected" : ""}>administradores</p>
          </div>
        </div>
      </div>
      <Card>
        {components[step]}
      </Card>
    </section>
  );
};

export default CommunityRegister;
