import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import "./EditEmployeeContainer.scss"
import EditEmployee from "../EditEmployee/EditEmployee";
import EmployeeInformation from "../EmployeeInformation/EmployeeInformation";
import {updateEmployee} from "../../../redux/actions/employees";
import Loading from "../../global/Loading/Loading";
import ConfirmPopUp from "../../global/ConfirmPopUp/ConfirmPopUp";

const EditEmployeeContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {id} = useParams();
  const employees = useSelector(state => state.employees);
  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [initialInfo, setInitialInfo] = useState({});

  useEffect(() => {
    if (employees.data?.items) {
      const employee = employees.data.items.filter((employee) => employee.id === Number(id))[0]
      setEmployee(employee);
      setInitialInfo({ role: employee?.role, unit: employee?.unit, isEqual: false })
      setIsLoading(false);
    } else {
      return history.push("/acessos/colaboradores");
    }
  }, [setEmployee, id, employees.data.items, history]);

  const handleEmployeeUpdate = ({ selectedUnit, selectedRole }) => {
    setIsLoading(true);

    if (selectedUnit && selectedRole) {

      if (selectedRole === initialInfo?.role && selectedUnit === initialInfo?.unit) {
        setModalMessage("Você não editou nenhuma informação!");
        setInitialInfo({ role: employee?.role, unit: employee?.unit, isEqual: true });
        setShowModal(true);
        setIsLoading(false);
        return;
      }

      const payload = {
        unit: selectedUnit,
        role: selectedRole
      };

      dispatch(updateEmployee(payload, employee?.id)).then(() => {
        setModalMessage("Colaborador editado com sucesso!");
        setShowModal(true);
        setIsLoading(false);
      }).catch(() => {
        setModalMessage("Ocorreu um erro ao editar o colaborador, tende novamente!");
        setShowModal(true);
        setIsLoading(false);
      });
    };
  };

  return (
    <div className="EditEmployeeContainer">
      <EditEmployee
        employeeEmail={employee && employee?.person?.email}
        employeeName={employee && employee?.person?.name}
      />
      <EmployeeInformation 
        handleSubmit={handleEmployeeUpdate}
        initialDepartment={employee && employee?.unit}
        initialRole={employee && employee?.role}
      />
      <Loading visible={isLoading} />
      <ConfirmPopUp
        buttonAction={
          () => initialInfo?.isEqual ? setShowModal(false) : window.location.reload()
        }
        active={showModal}
        message={modalMessage}
        buttonText="OK"
      />
    </div>
  );
};

export default EditEmployeeContainer;
