import React from "react";
import "./Checkbox.scss";
import PropTypes from "prop-types";

const Checkbox = ({label, value, updateState, checked, className, getValue, disabled = false}) => {
  const handleCheckbox = event => {
    getValue ? updateState(event.target.value) : updateState(event.target.checked);
  };

  return (
    <label className={`Checkbox ${className}`}>
      <input 
        value={value} 
        onChange={handleCheckbox} 
        type="checkbox" 
        checked={checked} 
        disabled={disabled} 
      />
      <span className="checkmark"/>
      {label}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  updateState: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  getValue: PropTypes.any,
};

export default Checkbox;
