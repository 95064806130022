import {
  CREATE_NOTIFY_REQUEST,
  CREATE_NOTIFY_REQUEST_FAILED,
  CREATE_NOTIFY_REQUEST_SUCCEEDED
} from "../constants/notify";

export const notify = (
  state = {
    isFetching: false,
    data: {},
    error: {}
  }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case CREATE_NOTIFY_REQUEST:
      newState.isFetching = true;
      return newState;

    case CREATE_NOTIFY_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case CREATE_NOTIFY_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
