import React, {useEffect} from 'react';
import './InvestorSummary.scss';
import Card from "../../global/Card/Card";
import {useDispatch, useSelector} from "react-redux";
import CommunityMetricsDistribution from "../../global/CommunityMetricsDistribution/CommunityMetricsDistribution";
import { getPersonMetrics, getPersonMetricsExecutions } from "../../../redux/actions/metrics";
import {getTopicsProgressionByMonth} from "../../../redux/actions/topics";

const InvestorSummary = ({ investorId }) => {
  const dispatch = useDispatch();
  const personMetrics = useSelector(state => state?.metrics?.personMetrics);
  const executionMetrics = useSelector(state => state?.metrics?.personExecutionMetrics);

  useEffect(() => {
    dispatch(getPersonMetrics(investorId));
    dispatch(getPersonMetricsExecutions(investorId));
    dispatch(getTopicsProgressionByMonth());
  }, [dispatch, investorId]);

  return (
    <div className="InvestorSummary">
      <div className="left-summary">
        <div className="profile">
          <div className="cards-container">
            <Card title="Investimento com mais aportes" className="active-investors">
              <div className="active-investors-content">
                <p>{executionMetrics?.data?.mostInvestedTopic?.name || "Nenhum dado encontrado"}</p>
              </div>
            </Card>
          </div>
          <Card title="Alocação de Carteira" className="card-summary-wallet">
            <CommunityMetricsDistribution community={personMetrics?.data} skill />
          </Card>
        </div>
      </div>
      <div className="right-summary">
        <div className="profile">
          <div className="cards-container">
            <Card title="Principais tipos de aporte" className="active-investors">
              <div className="active-investors-content">
                {personMetrics?.data?.top3MostUsedContentType ? Object.values(personMetrics?.data?.top3MostUsedContentType).map((item, key) => (
                  <p key={key}>{item}</p>
                )) : (
                  <p>Nenhum dado encontrado</p>
                )}
              </div>
            </Card>
          </div>

          <Card title="Tipos de Aportes" className="card-profile-contributions">
            <div className="chart">
              <CommunityMetricsDistribution community={executionMetrics?.data} />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default InvestorSummary;
