import React from 'react';
import "./CardSelectCompany.scss";
import {saveItemInStorage} from "../../../helper/storage";
import {useHistory} from "react-router-dom";

const CardSelectCompany = ({id, name, logo}) => {
  const history = useHistory();

  const handleCompanySelection = () => {
    saveItemInStorage("COMPANY_ID", id);
    history.push("/");
  }

  return (
    <div className={`card-selectcompany ${!logo ? "no-logo" : ""}`}
         style={{backgroundImage: `url(${logo && logo})`}}
         onClick={handleCompanySelection}>
      <p>{name}</p>
    </div>
  );
};

export default CardSelectCompany;
