import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./InvitesTable.scss";
import Button from "../../global/form/Button/Button";
import { useLocation } from "react-router-dom";
import {
  apiInvitesCollaborators,
  apiInvitesManagers,
} from "../../../redux/api/invitesStatus";
import InviteEmployeesTable from "../InviteEmployeesTable/InviteEmployeesTable";
import InviteManagersTable from "../InviteManagersTable/InviteManagersTable";

const InvitesTable = ({ hideTable }) => {
  const location = useLocation();
  const [dataTable, setDataTable] = useState({
    items: [],
    page: 1,
    prevPage: null,
    nextPage: null,
    totalItems: 0,
    totalPages: 1,
  });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const getCurrentPath = () => {
    const path = location.pathname;
    if (path.includes("colaboradores")) {
      return "colaboradores";
    } else if (path.includes("gestores")) {
      return "gestores";
    } else {
      return;
    }
  };

  const currentPath = getCurrentPath();

  const fetchData = async (page, search) => {
    setLoading(true);
    try {
      if (currentPath === "colaboradores") {
        const data = await apiInvitesCollaborators({ page, search });
        setDataTable(data);
      } else if (currentPath === "gestores") {
        const data = await apiInvitesManagers({ page, search });
        setDataTable(data);
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(dataTable.page, search);
  }, [dataTable.page, search, location]);

  const setPage = (page) => {
    fetchData(page, search);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
  };

  const sortData = (key, direction) => {
    const sortedData = [...dataTable.items].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setDataTable((prev) => ({
      ...prev,
      items: sortedData,
    }));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className={`InvitesTable ${hideTable ? "inactive" : ""}`}>
      <div className="container-input">
        <input
          type="text"
          placeholder="Pesquisar por e-mail"
          value={search}
          onChange={handleSearchChange}
        />
      </div>

      {loading ? (
        <div className="loading-spinner">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="white"
              stroke-linecap="round"
              stroke-width="2"
            >
              <path
                stroke-dasharray="60"
                stroke-dashoffset="60"
                stroke-opacity="0.3"
                d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="1.3s"
                  values="60;0"
                />
              </path>
              <path
                stroke-dasharray="15"
                stroke-dashoffset="15"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </g>
          </svg>
        </div>
      ) : (
        <>
          {currentPath === "colaboradores" && (
            <InviteEmployeesTable
              data={dataTable.items}
              onSort={handleSort}
              sortConfig={sortConfig}
            />
          )}
          {currentPath === "gestores" && (
            <InviteManagersTable
              data={dataTable.items}
              onSort={handleSort}
              sortConfig={sortConfig}
            />
          )}
        </>
      )}
      <div className="table-footer">
        {dataTable.totalPages > 1 && (
          <div className="pagination">
            <Button
              onClick={() => setPage(dataTable.prevPage)}
              text={"anterior"}
            />
            {dataTable.page > 1 && (
              <Button text="1" onClick={() => setPage(1)} />
            )}
            {dataTable.page > 2 && <span>...</span>}
            {dataTable.prevPage > 1 && (
              <Button
                text={dataTable.prevPage}
                onClick={() => setPage(dataTable.prevPage)}
              />
            )}
            <Button
              className="active"
              text={dataTable.page}
              onClick={() => setPage(dataTable.page)}
            />
            {dataTable.nextPage && (
              <Button
                text={dataTable.nextPage}
                onClick={() => setPage(dataTable.nextPage)}
              />
            )}
            {dataTable.totalPages > dataTable.page + 1 && <span>...</span>}
            {dataTable.totalPages > dataTable.page &&
              dataTable.totalPages > 2 && (
                <Button
                  text={dataTable.totalPages}
                  onClick={() => setPage(dataTable.totalPages)}
                />
              )}
            <Button
              onClick={() => setPage(dataTable.nextPage)}
              text={"próximo"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

InvitesTable.propTypes = {
  hideTable: PropTypes.bool.isRequired,
};

export default InvitesTable;
