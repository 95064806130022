import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiMetricsRequest = (token, id) => {
  return fetch(`${baseUrl}/person/${id}/metrics`, {
    method: "GET",
    headers: buildHeaders(token)
  })
    .then(getResponseOrThrow)
    .catch(error => Promise.reject(error));
};

export const apiCommunityMetricsRequest = community_id => {
  return fetch(`${baseUrl}/community/${community_id}/metrics`, {
    method: "GET",
    headers: buildHeaders()
  })
    .then(getResponseOrThrow)
    .catch(error => Promise.reject(error));
};

export const apiCommunityTasksMetricsRequest = community_id => {
  return fetch(`${baseUrl}/company/${community_id}/employees/tasks/metrics`, {
    method: "GET",
    headers: buildHeaders()
  })
    .then(getResponseOrThrow)
    .catch(error => Promise.reject(error));
};

export const apiCommunityContentTypeMetricsRequest = community_id => {
  return fetch(`${baseUrl}/company/${community_id}/metrics/contenttypedistribution`, {
    method: "GET",
    headers: buildHeaders()
  })
    .then(getResponseOrThrow)
    .catch(error => Promise.reject(error));
};

export const apiPersonMetricsRequest = (personId, token) => fetch(
  `${baseUrl}/person/${personId}/metrics`, {
    headers: buildHeaders(token)
  }
).then(getResponseOrThrow).catch(error => Promise.reject(error));

export const apiPersonMetricsExecutionsRequest = (personId, token) => fetch(
  `${baseUrl}/person/${personId}/executions/metrics`, {
    headers: buildHeaders(token)
  }
).then(getResponseOrThrow).catch(error => Promise.reject(error));
