import React, {useState} from 'react';
import "./AddInvestors.scss";
import {ReactComponent as UploadIcon} from "../../../assets/images/upload.svg";
import DragAndDropUpload from "../../global/form/DragAndDropUpload/DragAndDropUpload";
import Button from "../../global/form/Button/Button";
import UploadButton from "../../global/form/UploadButton/UploadButton";
import {validateFile} from "../../../helper/files";
import BackButton from "../../global/BackButton/BackButton";
import { buildAndDownloadCsv } from '../../../helper/csvHelper';

const AddInvestors = ({file, setFile, filePath, communityName, previousStep, nextStep}) => {
  const [error, setError] = useState(false);

  const handleFileUpload = (e) => {
    const file = (e.target && e.target.files[0]) || e;

    if (validateFile(file)) {
      setError(false)
      setFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    } else {
      setError(true);
    }
  };

  return (
    <div className="AddInvestors">
      <div className="top-info">
        <BackButton action={previousStep} />
        <div>
          <img src={filePath} alt={`logo selecionado da comunidade ${communityName}`}/>
          <h3>{communityName}</h3>
        </div>
      </div>
      <h2>Adicionar integrantes</h2>
      <p>Por favor, adicione a base de integrantes do sistema. <br/>
        Eles não terão acesso a nenhuma funcionalidade<br/> administrativa.</p>
      <p>O arquivo CSV deve estar no padrão E2W.</p>
      <span
        onClick={() => buildAndDownloadCsv(
          "nome;email;cargo;departamento\nJoão;joao@exemplo.com;desenvolvedor;TI"
        )}
      >
        Baixar o arquivo modelo.
      </span>
      <p className="error-message">{error && "Ops! Esse não parece ser um arquivo .csv"}</p>
      <label>Arquivo CSV</label>
      <DragAndDropUpload setFile={handleFileUpload}>
        <UploadIcon/>
        {!file.name ?
          <p className='upload-button'>arraste um arquivo csv para a tela ou <UploadButton handleFileUpload={handleFileUpload} accept=".csv"/>
          </p> : <p>{file.name}</p>}
      </DragAndDropUpload>
      <p className="email-warning">Todos nessa lista receberão um convite via e-mail <br/> para participar da comunidade.</p>
      <Button onClick={nextStep} bottom>Ir para administradores <span className="ph--arrow-right-bold"/></Button>
    </div>
  );
};

export default AddInvestors;
