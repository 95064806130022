import React from 'react';
import bloomsTaxonomy from "../../../constants/bloomTaxonomy";
import Accordion from '../../global/Accordion/Accordion';
import './InvestmentClassification.scss';

const InvestmentClassification = () => {
  return (
    <div className='InvestmentClassification'>
      <div className="classification-description">
        <p>
          Cada colaborador seleciona os investimentos que possui ou deseja desenvolver e realiza uma auto-avaliação,
          atribuindo um valor de proficiência naquela competência.
          <br />
          <br />
          <p>Confira as definições de cada classificação.</p>
        </p>
      </div>

      {bloomsTaxonomy.map(bloom => {
        const accordionTitle = (
          <div className="taxonomy">
            <span className="taxonomy-grade">{bloom.minimumGrade}</span>
            <span className="taxonomy-action">{bloom.action}</span>
          </div>
        )
        return (
          <Accordion title={accordionTitle} key={bloom.action}
            open={bloom.action === "lembrar" ? true : undefined}>
            <p className='title-bloom' dangerouslySetInnerHTML={{
              __html: bloom.title || "Conteúdo sem descrição",
            }} />
            <div className="bloom">
              <span className="bloom-explanation">
                {bloom.explanation}
              </span>
              <span className="bloom-synonyms">
                {bloom.synonyms}
              </span>
            </div>
          </Accordion>
        )
      })}
    </div>
  )
}

export default InvestmentClassification
