import React from 'react';
import "./EditEmployee.scss";
import BackButton from "../../global/BackButton/BackButton";

const EditEmployee = ({employeeName, employeeEmail}) => {
  return (
    <div className="EditEmployee">
      <h1>Editar colaborador</h1>
      <BackButton />
      <div>
        <h2 className="title-input">Você está editando</h2>
        <h3>Nome</h3>
        <p>{employeeName}</p>
        <h3>E-mail</h3>
        <p>{employeeEmail}</p>
      </div>
    </div>
  );
}

export default EditEmployee;
