import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import App from './components/App';
import './index.css';
import { accesses } from "./redux/reducers/accesses";
import { activitiesLog } from "./redux/reducers/activitiesLog";
import { company } from "./redux/reducers/company";
import { demo } from "./redux/reducers/demo";
import { employees, employeesMetrics } from "./redux/reducers/employees";
import { engagement } from "./redux/reducers/engagement";
import { evaluations } from "./redux/reducers/evaluations";
import { executions } from "./redux/reducers/executions";
import { globalEvaluations } from "./redux/reducers/globalEvaluations";
import { investments, investmentDetails } from "./redux/reducers/investments";
import { invites } from "./redux/reducers/invites";
import { login } from "./redux/reducers/login";
import { managers } from "./redux/reducers/managers";
import { metrics } from "./redux/reducers/metrics";
import { notify } from "./redux/reducers/notify";
import { personEvaluations } from "./redux/reducers/personEvaluations";
import { roles } from "./redux/reducers/roles";
import { signup } from "./redux/reducers/signup";
import { structure } from "./redux/reducers/structure";
import { tasks, tasksExecutions, tasksFunctionality } from "./redux/reducers/tasks";
import { topics } from "./redux/reducers/topics";
import { trending } from "./redux/reducers/trending";
import { units } from "./redux/reducers/units";
import * as serviceWorker from './serviceWorker';

const reducers = combineReducers({
  login,
  signup,
  topics,
  engagement,
  evaluations,
  activitiesLog,
  employees,
  employeesMetrics,
  managers,
  accesses,
  tasks,
  tasksExecutions,
  tasksFunctionality,
  invites,
  units,
  roles,
  demo,
  trending,
  globalEvaluations,
  personEvaluations,
  metrics,
  company,
  structure,
  executions,
  investments,
  investmentDetails,
  notify
})

const middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const store = createStore(reducers, applyMiddleware(...middleware));

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
