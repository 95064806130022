import React from "react";
import "./ContributionSummary.scss";
import { 
  translateContentTypeEnum,
  translateExecutionFormatEnum,
  translateAcquistionMethodEnum
} from "../../../helper/tasksEnum";

const ContributionSummary = ({ contribution }) => (
  <div className="ContributionSummary">
    <div className="contribution-description">
      <span>{contribution.title}</span>
      <p>{contribution.description}</p>
    </div>
    <div className="contribution-metrics">
      <p>{contribution.metrics.completeExecutions} realizados</p>
      <p>{contribution.metrics.activeExecutions} programados</p>
    </div>
    <div className="contribution-content-format">
      <span className="active">
        {translateContentTypeEnum(contribution.contentType)}
      </span>
      <span>
        {translateExecutionFormatEnum(contribution.taskFormat)}
      </span>
      <span>
        {translateAcquistionMethodEnum(contribution.contentAcquisition)}
      </span>
    </div>
  </div>
);

export default ContributionSummary;
