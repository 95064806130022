import {
  RECOVERY_PASSWORD_REQUEST,
  RECOVERY_PASSWORD_REQUEST_SUCCEEDED,
  RECOVERY_PASSWORD_REQUEST_FAILED,
  UPDATE_PASSWORD_WITH_CODE_REQUEST,
  UPDATE_PASSWORD_WITH_CODE_REQUEST_SUCCEEDED,
  UPDATE_PASSWORD_WITH_CODE_REQUEST_FAILED,
} from "../constants/recoveryPassword";

export const recoveryPasswordRequest = () => {
  return {
    type: RECOVERY_PASSWORD_REQUEST,
  };
};

export const recoveryPasswordRequestSucceeded = (payload) => {
  return {
    type: RECOVERY_PASSWORD_REQUEST_SUCCEEDED,
    data: payload,
  };
};

export const recoveryPasswordRequestFailed = (json) => {
  return {
    type: RECOVERY_PASSWORD_REQUEST_FAILED,
    error: json,
  };
};

export const updatePasswordWithCodeRequest = () => {
  return {
    type: UPDATE_PASSWORD_WITH_CODE_REQUEST,
  };
};

export const updatePasswordWithCodeRequestSucceeded = (payload) => {
  return {
    type: UPDATE_PASSWORD_WITH_CODE_REQUEST_SUCCEEDED,
    data: payload,
  };
};

export const updatePasswordWithCodeRequestFailed = (json) => {
  return {
    type: UPDATE_PASSWORD_WITH_CODE_REQUEST_FAILED,
    error: json,
  };
};
