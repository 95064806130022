import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./Summary.scss";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../global/Loading/Loading";
import Card from "../../global/Card/Card";
import Button from "../../global/form/Button/Button";
import Grade from "../../global/Grade/Grade";
import { useHistory } from "react-router";
import CommunityMetricsDistribution from "../../global/CommunityMetricsDistribution/CommunityMetricsDistribution";
import { getCommunityMetrics } from "../../../redux/actions/metrics";
import { getTrending } from "../../../redux/actions/trending";
import { listInvestments } from "../../../redux/actions/investments";
import { getTopicsProgressionByMonth } from "../../../redux/actions/topics";

const Summary = ({ setSelectedAbility }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const topics = useSelector(state => state.topics);
  const investments = useSelector(state => state.investments);
  const engagement = useSelector(state => state.engagement);
  const evaluations = useSelector(state => state.evaluations);
  const login = useSelector(state => state.login);
  const demo = useSelector(state => state.demo);
  const companyMetricsEngagement = useSelector((state) => state?.metrics);
  const communityMetrics = companyMetricsEngagement?.communityMetrics?.data;

  useEffect(() => {
    if (!demo.isDemoMode) {
      setSelectedAbility(topics.topicsList.data[0]?.topic.id)
    } else {
      setSelectedAbility(demo.data?.topics?.topicsList[0].id)
    }
  }, [topics.topicsList.data, setSelectedAbility, demo.isDemoMode, demo.data.topics]);

  useEffect(() => {
    dispatch(getTrending())
    dispatch(getCommunityMetrics());
    dispatch(listInvestments())
  }, []);

  return (
    <section className="Summary">
      <article className="welcome-text">
        <p className="subtitle">Olá, {login.data.name || "Gestor"}!</p>
        <p>
          Há {demo.isDemoMode ? demo.data.topics.topicsList.length : investments?.data?.totalItems || 0} investimento{(demo.isDemoMode ? demo.data.topics.topicsList.length > 1 : investments?.data?.totalItems > 1) ? "s" : ""} em  desenvolvimento entre os investidores.
        </p>
      </article>
      <div className="container-infos">
        {/* <h3>teste</h3> */}
        <Card className='card-metrics' container title="Alocação de investimentos">
          <CommunityMetricsDistribution community={communityMetrics} skill />
        </Card>
        <div className="container-block">
          <div className="top-block">
            <Card className='card-high' title="Maior valor">
              <Button
                linkStyle
              >
                Ver Detalhes
              </Button>
              <article>
                <p>{communityMetrics?.topicWithHighestValue?.name || "Resolução de Problemas"}</p>
                <p className="grade">
                  {/* {communityMetrics?.topicWithHighestValue?.tracking.average.toFixed(
                  2
                ) || 7.2} */}
                  <Grade value={10} variation={5} />
                </p>
              </article>
            </Card>
            <Card className='card-high' title="Menor valor">
              <Button
                linkStyle
              >
                Ver Detalhes
              </Button>
              <article>
                <p>{communityMetrics?.topicWithHighestValue?.name || "Gestão do tempo"}</p>
                <p className="grade">
                  {/* {communityMetrics?.topicWithHighestValue?.tracking.average.toFixed(
                  2
                ) || 7.2} */}
                  <Grade value={7} variation={-1} />
                </p>
              </article>
            </Card>
          </div>
          <div className="bottom-block">
            <Card className='card-engagement' title="Engajamento">
              <Button
                linkStyle
              >
                Ver Detalhes
              </Button>
              <article>
                <div className="active-investidores">
                  <span>{demo.isDemoMode ? demo.data?.engagement?.count : engagement.data?.count}</span>
                  <p>Investidores <br/> Ativos</p>
                </div>
                <div className="infos-engagement">
                  <p>{demo.isDemoMode ? demo.data?.engagement?.percentage : engagement.data?.percentage}% do total</p>
                  <p>{demo.isDemoMode ? demo.data?.engagement?.percentage : engagement.data.totalCount} investidores <br/> cadastrados</p>
                </div>
              </article>
            </Card>
            <Card className='card-info' title="Precisa de ajuda?">
              <Button
                linkStyle
              >
                Ver
              </Button>
              <article>
                <p>Confira a nossa <br/> seção de suporte.</p>
              </article>
            </Card>
          </div>
        </div>
      </div>
      {/* <div className="data-summary-cards">
        <Card container title="Valor Médio Geral">
          <div>
            <article className="grade">
              <Grade value={demo.isDemoMode ? demo.data.evaluations.daily.average : evaluations?.daily?.data?.average ?? 0} simplified maxGrade={10} />
              <p>Atual</p>
            </article>
            <article className="grade">
              <Grade value={demo.isDemoMode ? demo.data.evaluations.monthly.average : evaluations?.monthly?.data?.average ?? 0} simplified maxGrade={10} />
              <p>Anterior</p>
            </article>
          </div>
        </Card>
        <Card container title="Engajamento">
          <Button linkStyle onClick={() => history.push("/investidores")}>Ver Investidores</Button>
          <article className="engagement">
            <span>{demo.isDemoMode ? demo.data?.engagement?.count : engagement.data?.count} investidores ativos</span>
            <div>
              <p>{demo.isDemoMode ? demo.data?.engagement?.percentage : engagement.data?.percentage}% do total</p>
              <p>{demo.isDemoMode ? demo.data?.engagement?.percentage : engagement.data.totalCount} investidores cadastrados</p>
            </div>
          </article>
        </Card>
        <Card container title="Precisa de ajuda?">
          <Button linkStyle top onClick={() => history.push("/contato")}>Ver Suporte</Button>
          <p>Confira a nossa seção de suporte.</p>
        </Card>
      </div> */}
      <Loading
        visible={
          demo.isDemoMode
            ? demo.isFetching
            : engagement.isFetching ||
            evaluations.monthly.isFetching ||
            evaluations.daily.isFetching
        }
      />
    </section>
  );
}

Summary.propTypes = {
  selectedAbility: PropTypes.number.isRequired,
  setSelectedAbility: PropTypes.func.isRequired
};

export default Summary;
