import React from 'react';
import "./AccessHeader.scss";
import {useHistory, useParams} from "react-router-dom";
import Button from "../../global/form/Button/Button";
import Breadcrumb from "../../global/Breadcrumb/Breadcrumb";

const AccessHeader = ({setUploadVisible, uploadVisible, active, isRootManager}) => {
  const history = useHistory();
  const {type} = useParams();
  const renderButtons = () => {
    if (!active) {
      return (
        <>
          <button
            className={
              type === "gestores" ? "active" : isRootManager?.level !== "MANAGER" ? "" : "disabled"
            }
            onClick={() => isRootManager && history.push("gestores")}
          >
            Gestores
            <div class="tooltip" role="tooltip">
              <span class="tooltiptext">
                Você não é um super-gestor. O que significa isso? Que somente
                super gestores tem acesso a gerenciar outros gestores
              </span>
            </div>
          </button>
          <button
            className={type === "colaboradores" ? "active" : ""}
            onClick={() => history.push("colaboradores")}
          >
            Colaboradores
          </button>
          <button
            className={
              type === "convites-gestores" ? "active" : isRootManager?.level !== "MANAGER" ? "" : "disabled"
            }
            onClick={() => history.push("convites-gestores")}
          >
            Convite (Gestores)
          </button>
          <button
            className={type === "convites-colaboradores" ? "active" : ""}
            onClick={() => history.push("convites-colaboradores")}
          >
            Convite (Colaboradores)
          </button>
        </>
      );
    }
  }

  return (
    <div className="AccessHeader">
      <div className="actions">
        {uploadVisible && (
          <Breadcrumb
            crumbs={["acessos", "colaboradores", "convite por lote"]}
          />
        )}
        {!uploadVisible && (
          <div className="invite-buttons">
            <Button
              onClick={() => {
                history.push(`/${type}/convite`);
              }}
              invertColors
            >
              enviar convite por e-mail
            </Button>
            {type === "colaboradores" && (
              <React.Fragment>
                <Button onClick={() => setUploadVisible(true)} invertColors>
                  enviar convite por lote
                </Button>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <div className="navigation">{renderButtons()}</div>
    </div>
  );
};

export default AccessHeader;
