import React from "react";
import "./InformationCheckbox.scss";
import Checkbox from "../../global/form/Checkbox/Checkbox";

const InformationCheckbox = ({item, currentValue, updateState, key}) => {
  return (
    <div className="InformationCheckbox" key={key}>
      <Checkbox
        value={item.text} 
        updateState={() => updateState(item.text)} 
        checked={item.text === currentValue}
      />
      <p>{item && item.text}</p>
    </div>
  );
};

export default InformationCheckbox;
