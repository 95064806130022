const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const buildAndDownloadCsv = (data) => {
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob(['', data], { type });
  const dataURI = `data:${type};charset=utf-8,${data}`;
  const URL = window.URL || window.webkitURL;
  const csvUrl = typeof URL.createObjectURL === 'undefined'
    ? dataURI
    : URL.createObjectURL(blob);

  return window.open(csvUrl, '_blank');
}
