import React from 'react';
import "./PopUp.scss";

const PopUp = ({children, active}) => {
  return (
    <div className={`PopUp ${active ? "active" : ""}`}>
      <div className="pop-up">
        <div className="container-pop-up">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopUp;
