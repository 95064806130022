import React, {useEffect, useState} from "react";
import "./Dashboard.scss";
import Summary from "../Summary/Summary";
import AbilitiesAnalysis from "../AbilitiesAnalysis/AbilitiesAnalysis";
import EmployeesTasks from "../EmployeesTasks/EmployeesTasks";
import {useHistory} from "react-router-dom";
import {isUserAuthenticated} from "../../../redux/actions/authentication";
import {useDispatch, useSelector} from "react-redux";
import TrendingTopics from "../../global/TrendingTopics/TrendingTopics";
import {getTrending} from "../../../redux/actions/trending";
import {getTopicsProgressionByMonth} from "../../../redux/actions/topics";
import {getEngagement} from "../../../redux/actions/engagement";
import {getEvaluationDailyAverageRequest, getEvaluationMonthlyAverageRequest} from "../../../redux/actions/evaluations";
import {getEmployees} from "../../../redux/actions/employees";
import {getDemo} from "../../../redux/actions/demo";
import { getTasks } from "../../../redux/actions/tasks";
import Loading from "../../global/Loading/Loading";

const Dashboard = () => {
  const [selectedAbility, setSelectedAbility] = useState("");
  const demo = useSelector(state => state.demo);
  const topics = useSelector(state => state.topics);
  const trends = useSelector(state => state.trending);
  const tasks = useSelector(state => state.tasks);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!demo.isDemoMode) {
      !isUserAuthenticated() && history.push("/login");
    }
  }, [history, demo.isDemoMode]);

  useEffect(() => {
   if(!demo.isDemoMode) {
     dispatch(getTrending());
     dispatch(getTopicsProgressionByMonth());
     dispatch(getEngagement());
     dispatch(getEvaluationDailyAverageRequest());
     dispatch(getEvaluationMonthlyAverageRequest());
     dispatch(getEmployees());
     dispatch(getTasks());
   } else{
     dispatch(getDemo());
   }
  }, [dispatch, demo.isDemoMode])

  return (
    <main className="Dashboard">
      <Summary selectedAbility={selectedAbility} setSelectedAbility={setSelectedAbility}/>
      <AbilitiesAnalysis selectedAbility={selectedAbility} setSelectedAbility={setSelectedAbility}/>
      {/* <EmployeesTasks/> */}
      {/* <TrendingTopics/> */}
      <Loading visible={demo.isFetching || topics.isFetching || trends.isFetching || tasks.isFetching}/>
    </main>
  );
};

export default Dashboard;
