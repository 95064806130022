import React from "react";
import "./BrowseFaq.scss";
import definitions from "../mock";
import Button from "../../global/form/Button/Button";
import SearchBar from "../../global/SearchBar/SearchBar";

const BrowseFaq = ({ category, setCategory, setUserSearch }) => {
  const buttons = definitions.reduce((accumulator, item) => {
    return accumulator?.includes(item.category)
      ? accumulator
      : [...accumulator, item.category];
  }, []);

  return (
    <div className="BrowseFaq">
      <h2>Ajuda</h2>
      <div className="search">
        <p>Aqui, você encontra as principais informações sobre a plataforma.</p>
        <SearchBar
          type="text"
          placeholder="pesquisar pergunta"
          updateState={setUserSearch}
        />
      </div>
      <div className="faq-category">
        <Button
          onClick={() => {
            setCategory("");
          }}
          className={category === "" ? "active" : ""}
        >
          todos os temas
        </Button>
        {buttons?.map((buttonCategory, key) => (
          <Button
            onClick={() => {
              setCategory(buttonCategory);
            }}
            key={key}
            className={category === buttonCategory ? "active" : ""}
          >
            {buttonCategory}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default BrowseFaq;
