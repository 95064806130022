import {
  TOPIC_EVOLUTION_FILTERED_REQUEST, TOPIC_EVOLUTION_FILTERED_REQUEST_FAILED, TOPIC_EVOLUTION_FILTERED_REQUEST_SUCCEEDED,
  TOPIC_EVOLUTION_REQUEST, TOPIC_EVOLUTION_REQUEST_FAILED, TOPIC_EVOLUTION_REQUEST_SUCCEEDED,
  TOPICS_PROGRESSION_MONTH_REQUEST,
  TOPICS_PROGRESSION_MONTH_REQUEST_FAILED,
  TOPICS_PROGRESSION_MONTH_REQUEST_SUCCEEDED,
  GET_PERSON_TOPIC_SUMMARY_REQUEST,
  GET_PERSON_TOPIC_SUMMARY_REQUEST_FAILED,
  GET_PERSON_TOPIC_SUMMARY_REQUEST_SUCCEEDED
} from "../constants/topics";

export const topicsProgressionByMonthRequest = () => {
  return {
    type: TOPICS_PROGRESSION_MONTH_REQUEST
  }
};

export const topicsProgressionByMonthRequestSucceeded = (payload) => {
  return {
    type: TOPICS_PROGRESSION_MONTH_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const topicsProgressionByMonthRequestFailed = (json) => {
  return {
    type: TOPICS_PROGRESSION_MONTH_REQUEST_FAILED,
    error: json
  }
};

export const topicEvolutionRequest = () => {
  return {
    type: TOPIC_EVOLUTION_REQUEST
  };
};

export const topicEvolutionRequestSucceeded = (payload) => {
  return {
    type: TOPIC_EVOLUTION_REQUEST_SUCCEEDED,
    data: payload
  };
};

export  const topicEvolutionRequestFailed = (json) => {
  return {
    type: TOPIC_EVOLUTION_REQUEST_FAILED,
    error: json
  };
};

export const topicEvolutionFilteredRequest = () => {
  return {
    type: TOPIC_EVOLUTION_FILTERED_REQUEST
  }
};

export const topicEvolutionFilteredRequestSucceeded = (payload) => {
  return {
    type: TOPIC_EVOLUTION_FILTERED_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const topicEvolutionFilteredRequestFailed = (json) => {
  return {
    type: TOPIC_EVOLUTION_FILTERED_REQUEST_FAILED,
    error: json
  }
};

export const personTopicSummaryRequest = () => {
  return {
    type: GET_PERSON_TOPIC_SUMMARY_REQUEST
  }
}

export const personTopicSummaryRequestSucceeded = (payload) => {
  return {
    type: GET_PERSON_TOPIC_SUMMARY_REQUEST_SUCCEEDED,
    data: payload
  }
}

export const personTopicSummaryRequestFailed = (json) => {
  return {
    type: GET_PERSON_TOPIC_SUMMARY_REQUEST_FAILED,
    error: json
  }
}
