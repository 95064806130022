import React from "react";
import PropTypes from "prop-types";
import "./TopicSymbol.scss";

const TopicSymbol = ({symbol, className}) => {
  return (
    <span className={`TopicSymbol ${className ? className : ""}`}>
      {symbol}
    </span>
  );
};

TopicSymbol.propTypes = {
  symbol: PropTypes.string.isRequired
};

export default TopicSymbol;
