import {
  EVALUATION_DAYLY_AVERAGE_REQUEST,
  EVALUATION_DAYLY_AVERAGE_REQUEST_FAILED,
  EVALUATION_DAYLY_AVERAGE_REQUEST_SUCCEEDED,
  EVALUATION_MONTHLY_AVERAGE_REQUEST,
  EVALUATION_MONTHLY_AVERAGE_REQUEST_SUCCEEDED,
  EVALUATION_MONTHLY_AVERAGE_REQUEST_FAILED
} from "../constants/evaluations";

export const evaluations = (state = {
  daily: {
    data: {},
    isFetching: false
  },
  monthly: {
    data: {},
    isFetching: false
  }
}, action) => {
  let newState = {...state};

  switch (action.type) {
    case EVALUATION_DAYLY_AVERAGE_REQUEST:
      newState.daily.isFetching = true;
      return newState;

    case EVALUATION_DAYLY_AVERAGE_REQUEST_SUCCEEDED:
      newState.daily.isFetching = false;
      newState.daily.data = action.data;
      return newState;

    case EVALUATION_DAYLY_AVERAGE_REQUEST_FAILED:
      newState.daily.isFetching = false;
      newState.daily.data = {};
      return newState;

    case EVALUATION_MONTHLY_AVERAGE_REQUEST:
      newState.monthly.isFetching = true;
      return newState

    case EVALUATION_MONTHLY_AVERAGE_REQUEST_SUCCEEDED:
      newState.monthly.isFetching = false;
      newState.monthly.data = action.data;
      return newState;

    case EVALUATION_MONTHLY_AVERAGE_REQUEST_FAILED:
      newState.monthly.isFetching = false;
      newState.monthly.data = {}
      return newState;

    default:
      return newState;
  }
};
