export const INVITE_EMPLOYEE_REQUEST = "INVITE_EMPLOYEE_REQUEST";
export const INVITE_EMPLOYEE_REQUEST_SUCCEEDED = "INVITE_EMPLOYEE_REQUEST_SUCCEEDED";
export const INVITE_EMPLOYEE_REQUEST_FAILED = "INVITE_EMPLOYEE_REQUEST_FAILED";

export const INVITE_MANAGER_REQUEST = "INVITE_MANAGER_REQUEST";
export const INVITE_MANAGER_REQUEST_SUCCEEDED = "INVITE_MANAGER_REQUEST_SUCCEEDED";
export const INVITE_MANAGER_REQUEST_FAILED = "INVITE_MANAGER_REQUEST_FAILED";

export const GET_MANAGER_INVITE_REQUEST = "GET_MANAGER_INVITE_REQUEST";
export const GET_MANAGER_INVITE_REQUEST_SUCCEEDED = "GET_MANAGER_INVITE_REQUEST_SUCCEEDED";
export const GET_MANAGER_INVITE_REQUEST_FAILED = "GET_MANAGER_INVITE_REQUEST_FAILED";

export const ACCEPT_INVITE_REQUEST = "ACCEPT_INVITE_REQUEST";
export const ACCEPT_INVITE_REQUEST_SUCCEEDED = "ACCEPT_INVITE_REQUEST_SUCCEEDED";
export const ACCEPT_INVITE_REQUEST_FAILED = "ACCEPT_INVITE_REQUEST_FAILED";
