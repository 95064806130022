export const GET_METRICS_REQUEST = "GET_METRICS_REQUEST";
export const GET_METRICS_REQUEST_SUCCEEDED = "GET_METRICS_REQUEST_SUCCEEDED";
export const GET_METRICS_REQUEST_FAILED = "GET_METRICS_REQUEST_FAILED";

export const GET_COMMUNITY_METRICS_REQUEST = "GET_COMMUNITY_METRICS_REQUEST";
export const GET_COMMUNITY_METRICS_REQUEST_SUCCEEDED =
  "GET_COMMUNITY_METRICS_REQUEST_SUCCEEDED";
export const GET_COMMUNITY_METRICS_REQUEST_FAILED =
  "GET_COMMUNITY_METRICS_REQUEST_FAILED";

export const GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST = "GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST";
export const GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_SUCCEEDED =
  "GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_SUCCEEDED";
export const GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_FAILED =
  "GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST_FAILED";

export const GET_COMMUNITY_TASKS_METRICS_REQUEST = "GET_COMMUNITY_TASKS_METRICS_REQUEST"
export const GET_COMMUNITY_TASKS_METRICS_REQUEST_SUCCEEDED = "GET_COMMUNITY_TASKS_METRICS_REQUEST_SUCCEEDED"
export const GET_COMMUNITY_TASKS_METRICS_REQUEST_FAILED = "GET_COMMUNITY_TASKS_METRICS_REQUEST_FAILED"

export const GET_PERSON_METRICS_REQUEST = "GET_PERSON_METRICS_REQUEST"
export const GET_PERSON_METRICS_REQUEST_SUCCEEDED = "GET_PERSON_METRICS_REQUEST_SUCCEEDED"
export const GET_PERSON_METRICS_REQUEST_FAILED = "GET_PERSON_METRICS_REQUEST_FAILED"

export const GET_PERSON_METRICS_EXECUTIONS_REQUEST = "GET_PERSON_METRICS_EXECUTIONS_REQUEST"
export const GET_PERSON_METRICS_EXECUTIONS_REQUEST_SUCCEEDED = "GET_PERSON_METRICS_EXECUTIONS_REQUEST_SUCCEEDED"
export const GET_PERSON_METRICS_EXECUTIONS_REQUEST_FAILED = "GET_PERSON_METRICS_EXECUTIONS_REQUEST_FAILED"
