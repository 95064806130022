import {
  communityContentTypeMetricsRequest,
  communityContentTypeMetricsRequestFailed,
  communityContentTypeMetricsRequestSucceeded,
  communityMetricsRequest,
  communityMetricsRequestFailed,
  communityMetricsRequestSucceeded,
  communityTasksMetricsRequest,
  communityTasksMetricsRequestFailed,
  communityTasksMetricsRequestSucceeded,
  metricsRequest,
  metricsRequestFailed,
  metricsRequestSucceeded,
  personMetricsExecutionsRequest,
  personMetricsExecutionsRequestFailed,
  personMetricsExecutionsRequestSucceeded,
  personMetricsRequest,
  personMetricsRequestFailed,
  personMetricsRequestSucceeded
} from "../action-creators/metrics";
import {
  apiCommunityContentTypeMetricsRequest,
  apiCommunityMetricsRequest, apiCommunityTasksMetricsRequest,
  apiMetricsRequest,
  apiPersonMetricsExecutionsRequest,
  apiPersonMetricsRequest
} from "../api/metrics";
import { getItemFromStorage } from "../../helper/storage";

const createMetricsRequest = (userId) => dispatch => {
  dispatch(metricsRequest());
  const token = getItemFromStorage("TOKEN");
  return apiMetricsRequest(token, userId)
    .then(json => {
      dispatch(metricsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(metricsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getMetrics = (userId) => dispatch => dispatch(createMetricsRequest(userId));

const createCommunityMetricsRequest = () => dispatch => {
  const companyId = getItemFromStorage("COMPANY_ID");
  dispatch(communityMetricsRequest());
  return apiCommunityMetricsRequest(companyId)
    .then(json => {
      dispatch(communityMetricsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(communityMetricsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getCommunityMetrics = () => dispatch =>
  dispatch(createCommunityMetricsRequest());

const createCommunityContentTypeMetricsRequest = () => dispatch => {
  const companyId = getItemFromStorage("COMPANY_ID");
  dispatch(communityContentTypeMetricsRequest());
  return apiCommunityContentTypeMetricsRequest(companyId)
    .then(json => {
      dispatch(communityContentTypeMetricsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(communityContentTypeMetricsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getCommunityContentTypeMetrics = () => dispatch =>
  dispatch(createCommunityContentTypeMetricsRequest());

const createCommunityTasksMetricsRequest = () => dispatch => {
  const companyId = getItemFromStorage("COMPANY_ID");
  dispatch(communityTasksMetricsRequest());
  return apiCommunityTasksMetricsRequest(companyId)
    .then(json => {
      dispatch(communityTasksMetricsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(communityTasksMetricsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getCommunityTasksMetrics = () => dispatch =>
  dispatch(createCommunityTasksMetricsRequest());

const createPersonMetricsRequest = (personId) => (dispatch) => {
  dispatch(personMetricsRequest());
  const token = getItemFromStorage("TOKEN");
  return apiPersonMetricsRequest(personId, token)
    .then((json) => {
      dispatch(personMetricsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((response) => {
      dispatch(personMetricsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getPersonMetrics = (personId) => (dispatch) =>
  dispatch(createPersonMetricsRequest(personId));

const createPersonMetricsExecutionsRequest = (personId) => (dispatch) => {
  dispatch(personMetricsExecutionsRequest());
  const token = getItemFromStorage("TOKEN");
  return apiPersonMetricsExecutionsRequest(personId, token)
    .then((json) => {
      dispatch(personMetricsExecutionsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((response) => {
      dispatch(personMetricsExecutionsRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getPersonMetricsExecutions = (personId) => (dispatch) =>
  dispatch(createPersonMetricsExecutionsRequest(personId));
