import React, { useEffect } from "react";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import Background from "../assets/images/background.png";
import BackgroundNew from "../assets/images/bg-mobile.svg";
import "./App.scss";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import Access from "./access/Access/Access";
import CommunityRegister from "./communityRegister/CommunityRegister/CommunityRegister";
import Contact from "./contact/Contact/Contact";
import Contributions from "./contributions/Contributions/Contributions";
import Dashboard from "./dashboard/Dashboard/Dashboard";
import EditEmployeeContainer from "./editEmployee/EditEmployeeContainer/EditEmployeeContainer";
import EditManagerContainer from "./editManager/EditManagerContainer/EditManagerContainer";
import EntryGenericPage from "./entry/EntryGenericPage/EntryGenericPage";
import ForgotPassword from "./entry/ForgotPassword/ForgotPassword";
import Login from "./entry/Login/Login";
import NewPassword from "./entry/NewPassword/NewPassword";
import RecoveryCode from "./entry/RecoveryCode/RecoveryCode";
import SignUp from "./entry/SignUp/SignUp";
import GenericPage from "./genericPage/GenericPage/GenericPage";
import Investments from "./investments/Investments/Investments";
import Investors from "./investors/Investors/Investors";
import AcceptInvite from "./invitation/AcceptInvite/AcceptInvite";
import EmployeeInvitation from "./invitation/EmployeeInvitation/EmployeeInvitation";
import ManagerInvitation from "./invitation/ManagerInvitation/ManagerInvitation";
import PrivateRoute from "./privateRoute/PrivateRoute/PrivateRoute";
import SelectCompany from "./selectCompany/SelectCompany/SelectCompany";
import Support from "./support/Support/Support";

function App() {
  const location = useLocation();
  
  // CHECK TO CHANGE BACKGROUND COLOR
  const animation = false

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [location]);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>

          <PrivateRoute path="/" exact>
            <GenericPage>
              <Dashboard />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/investimentos" exact>
            <GenericPage>
              <Investments />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/aportes" exact>
            <GenericPage>
              <Contributions />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/contato" exact>
            <GenericPage>
              <Contact />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/suporte" exact>
            <GenericPage>
              <Support />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/investidores" exact>
            <GenericPage>
              <Investors />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/manager/login/linkedin" exact>
            <LinkedInPopUp />
          </PrivateRoute>

          <Route path="/convite" exact>
            <EntryGenericPage>
              <Login />
            </EntryGenericPage>
          </Route>

          <PrivateRoute path="/gestores/convite" exact>
            <GenericPage>
              <ManagerInvitation />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/colaboradores/convite" exact>
            <GenericPage>
              <EmployeeInvitation />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/acessos/:type" exact={false}>
            <GenericPage>
              <Access />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/editar/colaborador/id/:id" exact={false}>
            <GenericPage>
              <EditEmployeeContainer />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/editar/gestor/id/:id" exact={false}>
            <GenericPage>
              <EditManagerContainer />
            </GenericPage>
          </PrivateRoute>

          <PrivateRoute path="/selecionar-empresa" exact>
            <SelectCompany />
          </PrivateRoute>

          <PrivateRoute path="/empresa/cadastro" exact>
            <CommunityRegister />
          </PrivateRoute>

          <PrivateRoute path="/aceitar/convite" exact>
            <AcceptInvite />
          </PrivateRoute>

          <Route
            path="/login"
            exact
            component={() => (
              <EntryGenericPage>
                <Login />
              </EntryGenericPage>
            )}
          />

          <Route
            path="/cadastro"
            exact
            component={() => (
              <EntryGenericPage>
                <SignUp />
              </EntryGenericPage>
            )}
          />

          <Route
            path="/termos-de-uso"
            exact
            component={() => (
              <TermsOfUse />
            )}
          />


          <Route path="/recuperar-senha" exact>
            <EntryGenericPage>
              <ForgotPassword />
            </EntryGenericPage>
          </Route>

          <Route path="/recuperar-senha/codigo" exact>
            <EntryGenericPage>
              <RecoveryCode />
            </EntryGenericPage>
          </Route>

          <Route path="/recuperar-senha/codigo/:code" exact>
            <EntryGenericPage>
              <NewPassword />
            </EntryGenericPage>
          </Route>

          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
      <div
        // src={BackgroundNew}
        className={`app-bg ${animation ? "color-animation" : ""}`}
        // alt="Imagem de fundo da aplicação"
      />
    </div>
  );
}

export default App;
