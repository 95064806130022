import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listInvestments } from '../../../redux/actions/investments';
import { createNotify } from '../../../redux/actions/notify';
import showNotification from '../../global/Notification/showNotification';
import Button from '../../global/form/Button/Button';
import './CreateNotifyTask.scss';
import { getTasks } from '../../../redux/actions/tasks';

const CreateNotifyTask = ({ showNotifyModal, handleShowModal, dataNotify }) => {
  const dispatch = useDispatch();
  const notify = useSelector(state => state.notify)

  const handleNotify = () => {
    const payload = {
      title: `Nova tarefa criada pelo gestor: ${dataNotify.title}`,
      type: "GENERIC",
      units: dataNotify.units,
      roles: dataNotify.roles
    }

    dispatch(createNotify(payload)).then(() => {
      showNotification('Notificação enviada com sucesso!', 5000);
      handleShowModal(false)
      dispatch(listInvestments())
      dispatch(getTasks());
    }).catch(() => showNotification('Houve um erro inesperado, tente mais tarde!', 5000))
  }

  return (
    <div className={`CreateNotifyTask ${showNotifyModal ? "active" : ""}`}>
      <div className="container-create">
        <div className='container-title'>
          <p>Tarefa criada com sucesso!</p>
          <p>Deseja notificar comunidade?</p>
        </div>

        <div className="container-buttons">
          {notify.isFetching ? <p>Carregando</p> :
            <>
              <Button onClick={() => handleNotify()}>SIM</Button>
              <Button onClick={() => {
                handleShowModal()
              }}>NÃO</Button>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default CreateNotifyTask
