import { getItemFromStorage, removeItemFromStorage, saveItemInStorage } from "../../helper/storage";
import {
  googleLoginSucceeded,
  linkedInDataRequest,
  linkedInDataRequestFailed,
  linkedInDataRequestSucceeded,
  linkedInOAuthSucceeded,
  loginRequest,
  loginRequestFailed,
  loginRequestSucceeded,
  loginWithGoogle,
  loginWithGoogleSucceeded,
  loginWithMicrosoft,
  loginWithMicrosoftSucceeded,
  logoutRequest
} from "../action-creators/login";
import { apiGoogleLogin, apiLinkedInLogin, apiLogin, apiMicrosoftLogin, apiRefreshLogin } from "../api/login";

const requestLogin = (payload, keepLogged) => dispatch => {
  dispatch(loginRequest());
  return apiLogin(payload)
    .then(json => {
      dispatch(loginRequestSucceeded(json));
      saveItemInStorage("TOKEN", json.token, keepLogged);
      saveItemInStorage("REFRESH_TOKEN", json.refreshToken, keepLogged);
      saveItemInStorage("MANAGER_ID", json.manager.id, keepLogged);
      saveItemInStorage("LAST_LOGIN", Date.now(), keepLogged);
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(loginRequestFailed(json));
      return Promise.reject()
    }
    );
};
export const login = payload => dispatch => dispatch(requestLogin(payload));

const refreshLogin = () => dispatch => {
  const refreshToken = getItemFromStorage("REFRESH_TOKEN");
  return apiRefreshLogin(refreshToken)
    .then(data => {
      localStorage.setItem("TOKEN", data.token);
      localStorage.setItem("LAST_LOGIN", Date.now().toString());
      return Promise.resolve(data)
    }).catch((error) => Promise.reject(error));
};

export const autoLogin = () => dispatch => dispatch(refreshLogin());

const createLoginWithGoogleRequest = payload => dispatch => {
  dispatch(loginWithGoogle());

  return apiGoogleLogin(payload)
    .then(json => {
      dispatch(loginWithGoogleSucceeded(json));
      saveItemInStorage("TOKEN", json.token, true);
      saveItemInStorage("REFRESH_TOKEN", json.refreshToken, true);
      saveItemInStorage("USER_ID", json.manager.id, true);
      saveItemInStorage("LAST_LOGIN", Date.now(), true);
      return Promise.resolve();
    })
    .catch(response => response);
};

export const googleLogin = payload => dispatch =>
  dispatch(createLoginWithGoogleRequest(payload));

export const registerGoogleLogin = googleData => dispatch => {
  dispatch(googleLoginSucceeded(googleData));
  const payload = { tokenId: googleData.tokenId };

  return dispatch(googleLogin(payload));
};

export const registerGoogleLoginFailed = message => dispatch =>
  Promise.reject(message);

const createLoginWithLinkedInRequest = payload => dispatch => {
  dispatch(linkedInDataRequest());

  return apiLinkedInLogin(payload)
    .then(json => {
      dispatch(linkedInDataRequestSucceeded(json));
      saveItemInStorage("TOKEN", json.token, true);
      saveItemInStorage("REFRESH_TOKEN", json.refreshToken, true);
      saveItemInStorage("USER_ID", json.manager.id, true);
      saveItemInStorage("LAST_LOGIN", Date.now(), true);
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(linkedInDataRequestFailed(response));
      Promise.reject(response);
    });
};

export const linkedInLogin = payload => dispatch =>
  dispatch(createLoginWithLinkedInRequest(payload));

export const registerLinkedInToken = payload => dispatch => {
  dispatch(linkedInOAuthSucceeded(payload.code));

  return dispatch(linkedInLogin(payload));
};

export const logout = () => dispatch => {
  dispatch(logoutRequest());
  removeItemFromStorage("TOKEN");
  removeItemFromStorage("REFRESH_TOKEN");
  removeItemFromStorage("USER_ID");

  return Promise.resolve();
};

export const createLoginWitMicrosoftRequest = payload => async dispatch => {
  dispatch(loginWithMicrosoft());

  try {
    const json = await apiMicrosoftLogin(payload);
    dispatch(loginWithMicrosoftSucceeded(json));
    return await Promise.resolve(json);
  } catch (response) {
    return response;
  }
};
