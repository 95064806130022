import { apiAddInvestorsRequest, apiDeleteEmployee, apiEmployeesMetrics, apiEmployeesRequest, apiUpdateEmployee } from "../api/employees";
import {
  addEmployeesRequest, addEmployeesRequestFailed, addEmployeesRequestSucceeded,
  deleteEmployeeRequest,
  deleteEmployeeRequestFailed,
  deleteEmployeeRequestSucceeded,
  employeesMetrics,
  employeesMetricsFailed,
  employeesMetricsSucceeded,
  employeesRequest,
  employeesRequestFailed,
  employeesRequestSucceeded,
  updateEmployeeRequest,
  updateEmployeeRequestFailed,
  updateEmployeeRequestSucceeded
} from "../action-creators/employees";
import { getItemFromStorage } from "../../helper/storage";

const createEmployeesRequest = filters => dispatch => {
  dispatch( employeesRequest() );
  const token = getItemFromStorage( "TOKEN" );
  const company_id = getItemFromStorage( "COMPANY_ID" );
  return apiEmployeesRequest( token, company_id, filters )
    .then( json => {
      dispatch( employeesRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( employeesRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const getEmployees = filters => dispatch =>
  dispatch( createEmployeesRequest(filters) );

const createDeleteEmployeeRequest = id => dispatch => {
  dispatch( deleteEmployeeRequest() );
  const token = getItemFromStorage( "TOKEN" );
  const company_id = getItemFromStorage( "COMPANY_ID" );
  return apiDeleteEmployee(token, company_id, id)
    .then( json => {
      dispatch( deleteEmployeeRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( deleteEmployeeRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const deleteEmployee = id => dispatch =>
  dispatch( createDeleteEmployeeRequest(id) );

const createUpdateEmployeeRequest = ( payload, employee_id ) => dispatch => {
  dispatch( updateEmployeeRequest() );
  const token = getItemFromStorage( "TOKEN" );
  const company_id = getItemFromStorage( "COMPANY_ID" );
  return apiUpdateEmployee( token, company_id, employee_id, payload )
    .then( json => {
      dispatch( updateEmployeeRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( updateEmployeeRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const updateEmployee = ( payload, employee_id ) => dispatch =>
  dispatch( createUpdateEmployeeRequest( payload, employee_id ) );

const createAddInvestorsRequest = payload => dispatch => {
  dispatch( addEmployeesRequest() );
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  return apiAddInvestorsRequest(payload, token, company_id)
    .then( json => {
      dispatch( addEmployeesRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( addEmployeesRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const addInvestors = payload => dispatch =>
  dispatch( createAddInvestorsRequest(payload) );

export const createEmployeesMetricsRequest = () => dispatch => {
  dispatch(employeesMetrics());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  return apiEmployeesMetrics(token, company_id)
    .then(json => {
      dispatch(employeesMetricsSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(employeesMetricsFailed(response));
      return Promise.reject(response);
    });
} 
export const getEmployeesMetrics = () => dispatch => dispatch(createEmployeesMetricsRequest())