export const EMPLOYEES_REQUEST = "EMPLOYEES_REQUEST";
export const EMPLOYEES_REQUEST_SUCCEEDED = "EMPLOYEES_REQUEST_SUCCEEDED";
export const EMPLOYEES_REQUEST_FAILED = "EMPLOYEES_REQUEST_FAILED";

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";
export const DELETE_EMPLOYEE_REQUEST_SUCCEEDED = "DELETE_EMPLOYEE_REQUEST_SUCCEEDED";
export const DELETE_EMPLOYEE_REQUEST_FAILED = "DELETE_EMPLOYEE_REQUEST_FAILED";

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_REQUEST_SUCCEEDED = "UPDATE_EMPLOYEE_REQUEST_SUCCEEDED";
export const UPDATE_EMPLOYEE_REQUEST_FAILED = "UPDATE_EMPLOYEE_REQUEST_FAILED";

export const ADD_EMPLOYEES_REQUEST = "ADD_EMPLOYEES_REQUEST";
export const ADD_EMPLOYEES_REQUEST_SUCCEEDED = "ADD_EMPLOYEES_REQUEST_SUCCEEDED";
export const ADD_EMPLOYEES_REQUEST_FAILED = "ADD_EMPLOYEES_REQUEST_FAILED";

export const EMPLOYEES_METRICS_REQUEST = "EMPLOYEES_METRICS_REQUEST"
export const EMPLOYEES_METRICS_SUCCEEDED = "EMPLOYEES_METRICS_SUCCEEDED"
export const EMPLOYEES_METRICS_FAILED = "EMPLOYEES_METRICS_FAILED"
