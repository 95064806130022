import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import { isSuperManager } from "../../../helper/company";
import { getItemFromStorage } from "../../../helper/storage";
import { getAccesses } from '../../../redux/actions/accesses';
import { getCompanyStructure } from "../../../redux/actions/structure";
import Button from "../../global/form/Button/Button";
import CheckboxesContainer from "../CheckboxesContainer/CheckboxesContainer";
import "./AccessPermission.scss";

const AccessPermission = ({ handleSubmit, units, setUnits, levelsAccesses, setLevelsAccesses }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const demo = useSelector(state => state.demo);
  const structure = useSelector((state) => state?.structure?.data);
  const accesses = useSelector(state => state.accesses?.data);
  const companyId = getItemFromStorage("COMPANY_ID");
  const isRootManager = isSuperManager(accesses, companyId);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [newDepartment, setNewDepartment] = useState("");
  const [levels] = useState(["Administrador",
    "Coordenador",
    "Gerente",])
  useEffect(() => {
    dispatch(getAccesses());
    dispatch(getCompanyStructure());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(structure) && isRootManager) {
      setUnitsOptions(structure?.map((unit) => unit.name));
      setUnitsOptions((prevState) => [
        ...prevState,
        "Acesso completo",
      ]);
    }
  }, [isRootManager, setUnits, structure]);

  useEffect(() => {
    if (!demo.isDemoMode && !isRootManager) {
      history.push("/acessos/colaboradores");
    } else if (demo.isDemoMode) {
      setUnitsOptions(demo.data.units);
    }
  }, [accesses, demo.data.units, demo.isDemoMode, history, isRootManager]);

  const createDepartment = (e) => {
    e.stopPropagation();

    if (newDepartment === "Acesso completo" || newDepartment === "*") {
      setNewDepartment("")
      return;
    }

    setUnitsOptions((prevState) => [
      ...prevState.slice(0, -1),
      newDepartment,
      "Acesso completo",
    ]);
    setUnits((prevState) => [...prevState, newDepartment]);
    setNewDepartment("");
  }

  return (
    <div className="AccessPermission">
      <h1>Estabeleça permissões de acesso para esse administrador</h1>
      <div className="checkbox-accesses">
        <CheckboxesContainer
          containerLabel="Níveis de gestão"
          updateState={setLevelsAccesses}
          options={levels}
          optionsSelected={levelsAccesses}
          setNewDepartment={setNewDepartment}
          newDepartment={newDepartment}
          off
          createDepartment={createDepartment}
        />
        <CheckboxesContainer
          containerLabel="Departamentos"
          updateState={setUnits}
          options={unitsOptions}
          optionsSelected={units}
          multipleSelection
          setNewDepartment={setNewDepartment}
          newDepartment={newDepartment}
          createDepartment={createDepartment}
        />
      </div>
      <Button onClick={handleSubmit}>Enviar</Button>
    </div>
  );
};

export default AccessPermission;
