import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestmentInvestors, getInvestmentProgression, getInvestmentTasks } from '../../../redux/actions/investments';
import TabButton from '../../global/TabButton/TabButton';
import ContributionsInvestment from '../ContributionsInvestment/ContributionsInvestment';
import InvestmentClassification from '../InvestmentClassification/InvestmentClassification';
import InvestmentResume from '../InvestmentResume/InvestmentResume';
import InvestorsInInvestment from '../InvestorsInInvestment/InvestorsInInvestment';
import './InvestmentDetails.scss';

const InvestmentDetails = ({ investment, handleClose, setShowModal }) => {
  const [activeContent, setActiveContent] = useState("resume");
  const dispatch = useDispatch();
  const investmentDetails = useSelector(state => state.investmentDetails);

  useEffect(() => {
    dispatch(getInvestmentProgression(investment?.id))
    dispatch(getInvestmentInvestors(investment?.id))
    dispatch(getInvestmentTasks({ topicId: investment?.id }))
  }, [investment?.id])


  return (
    <div className="InvestmentDetails">
      <div className="header">
        <div className="investment-container">
          <p>Investimento</p>
          <div className="investment-info">
            <p>
              {investment?.name}
            </p>
          </div>
          <div className="tabs">
            <TabButton
              active={activeContent === "resume"}
              className="first-child"
              onClick={() => setActiveContent("resume")}
            >
              Resumo
            </TabButton>
            <TabButton
              active={activeContent === "investors"}
              onClick={() => setActiveContent("investors")}
            >
              Investidores
            </TabButton>
            <TabButton
              active={activeContent === "contributions"}
              onClick={() => setActiveContent("contributions")}
            >
              Aportes
            </TabButton>
            <TabButton
              active={activeContent === "classification"}
              onClick={() => setActiveContent("classification")}
            >
              Classificações
            </TabButton>
          </div>
        </div>
        <button onClick={() => handleClose()}>Fechar &nbsp; ✖</button>
      </div>
      <section className={`${activeContent === 'investors' || activeContent === 'contributions' ? 'investors' : ""}`}>
        {activeContent === "resume" && (
          <InvestmentResume investment={investment} investmentDetails={investmentDetails} setShowModal={setShowModal} />
        )}
        {activeContent === "classification" && (
          <InvestmentClassification />
        )}
        {activeContent === "investors" && (
          <InvestorsInInvestment investmentDetails={investmentDetails} />
        )}
        {activeContent === "contributions" && (
          <ContributionsInvestment investmentDetails={investmentDetails} />
        )}
      </section>
    </div>
  );
}

export default InvestmentDetails
