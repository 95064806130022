import {
  evaluationDailyAverageRequest,
  evaluationDailyAverageRequestFailed,
  evaluationDailyAverageRequestSucceeded,
  evaluationMonthlyAverageRequest,
  evaluationMonthlyAverageRequestFailed,
  evaluationMonthlyAverageRequestSucceeded
} from "../action-creators/evaluations";
import {apiEvaluationDailyAverage, apiEvaluationMonthlyAverage} from "../api/evaluations";
import { getItemFromStorage } from "../../helper/storage";

const createEvaluationDailyAverageRequest = () => dispatch => {
  dispatch(evaluationDailyAverageRequest());
  const company_id = getItemFromStorage( "COMPANY_ID" );
  const token = getItemFromStorage( "TOKEN" );

  return apiEvaluationDailyAverage(token, company_id)
    .then((json) => {
      dispatch(evaluationDailyAverageRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(evaluationDailyAverageRequestFailed(error));
      return Promise.reject(error);
    });
};

const createEvaluationMonthlyAverageRequest = () => dispatch => {
  dispatch(evaluationMonthlyAverageRequest());
  const company_id = getItemFromStorage( "COMPANY_ID" );
  const token = getItemFromStorage( "TOKEN" );
  
  return apiEvaluationMonthlyAverage(token, company_id)
    .then((json) => {
      dispatch(evaluationMonthlyAverageRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(evaluationMonthlyAverageRequestFailed(error));
      return Promise.reject(error);
    })
}

export const getEvaluationMonthlyAverageRequest = () => dispatch => dispatch(createEvaluationMonthlyAverageRequest());
export const getEvaluationDailyAverageRequest = () => dispatch => dispatch(createEvaluationDailyAverageRequest());
