import React, { useState, useEffect } from "react";
import "./EmployeeInvitation.scss";
import InviteEmployee from "../InviteEmployee/InviteEmployee";
import EmployeeInformation from "../EmployeeInformation/EmployeeInformation";
import Loading from "../../global/Loading/Loading";
import PopUpSuccess from "../../global/PopUpSuccess/PopUpSuccess";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyStructure } from "../../../redux/actions/structure";
import { inviteEmployee } from "../../../redux/actions/invites";
import { useHistory } from "react-router";
import {getItemFromStorage} from "../../../helper/storage";
import {getAccesses} from "../../../redux/actions/accesses";
import {isSuperManager} from "../../../helper/company";

const EmployeeInvitation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [popUpActive, setPopUpActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const companyId = getItemFromStorage("COMPANY_ID");
  const accesses = useSelector(state => state.accesses?.data);
  const isRootManager = isSuperManager(accesses, companyId);

  const structure = useSelector((state) => state?.structure?.data);
  const demo = useSelector((state) => state.demo);

  useEffect(() => {
    dispatch(getCompanyStructure()).then(() => {
      setIsLoading(false);
    });
    dispatch(getAccesses());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUnit && Array.isArray(structure)) {
      setSelectedRole("");
      const roles = structure
        .map((unit) => (unit.name === selectedUnit ? unit.roles : null))
        .filter(Boolean)
        .map((role) => role.map((item) => ({ text: item })))[0];
      setRoles(roles ?? []);
    }
  }, [selectedUnit, structure]);

  useEffect(() => {
    if (!demo.isDemoMode) {
      if (Array.isArray(structure)) {
        setUnits(
          structure?.map((unit) => ({ text: unit.name, checkbox: true }))
        );
      }
    } else {
      setUnits(demo.data.units);
      setRoles(demo.data.roles);
    }
  }, [demo.isDemoMode, demo.data.roles, demo.data.units, structure]);

  const handleInviteEmployee = () => {
    setIsLoading(true);

    const payload = {
      email,
      unit: selectedUnit,
      role: selectedRole,
    };

    dispatch(inviteEmployee(payload)).then(() => {
      setIsLoading(false);
      setPopUpActive(true);
    }).catch(() => setIsLoading(false));
  };

  return (
    <section className="EmployeeInvitation">
      <InviteEmployee
        employeeEmail={email}
        setEmail={setEmail}
        role={selectedRole}
        setRole={setSelectedRole}
        unit={selectedUnit}
        setUnit={setSelectedUnit}
      />
      <EmployeeInformation
        unitsOptions={units}
        rolesOptions={roles}
        setSelectedUnit={setSelectedUnit}
        setSelectedRole={setSelectedRole}
        selectedUnit={selectedUnit}
        selectedRole={selectedRole}
        handleSubmit={handleInviteEmployee}
        showCreate={isRootManager}
        addNewUnit={setUnits}
        addNewRole={setRoles}
      />
      <Loading visible={isLoading} />
      <PopUpSuccess
        active={popUpActive}
        closePopUp={() => history.push("/acessos/colaboradores")}
      >
        <p>Convite enviado ao colaborador!</p>
      </PopUpSuccess>
    </section>
  );
};

export default EmployeeInvitation;
