import { companyRequest, companyRequestFailed, companyRequestSucceeded, metricsEngagementMonthRequest, metricsEngagementMonthRequestFailed, metricsEngagementMonthRequestSucceeded } from "../action-creators/company";
import { apiCompanyMetricsEngagementRequest, apiCompanyRequest } from "../api/company";
import { getItemFromStorage, saveItemInStorage } from "../../helper/storage";

const createCompanyRequest = payload => dispatch => {
  dispatch( companyRequest() );
  const token = getItemFromStorage("TOKEN");
  return apiCompanyRequest(payload, token)
    .then( json => {
      saveItemInStorage("COMPANY_ID", json.id);
      dispatch( companyRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( companyRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const postCompany = payload => dispatch =>
  dispatch( createCompanyRequest(payload) );


const createCompanyMetricsEngagementRequest = () => dispatch => {
  dispatch(metricsEngagementMonthRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  return apiCompanyMetricsEngagementRequest(token, company_id)
    .then((json) => {
      dispatch(metricsEngagementMonthRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((response) => {
      dispatch(metricsEngagementMonthRequestFailed(response));
      return Promise.reject(response);
    });
};

export const getCompanyMetricsEngagement = () => dispatch => dispatch(
  createCompanyMetricsEngagementRequest()
)
