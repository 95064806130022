import React from "react";
import PropTypes from "prop-types";
import "./InviteEmployeesTable.scss";
import { ReactComponent as ArrowUp } from "../../../assets/images/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/arrow-down.svg";

const InviteEmployeesTable = ({ data, onSort, sortConfig }) => {
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <table className="InviteEmployeesTable">
        <thead>
          <tr>
            <th>
              E-mail
              <button onClick={() => onSort('email')}>
                {getClassNamesFor('email') === 'asc' ? <ArrowUp /> : <ArrowDown />}
              </button>
            </th>
            <th>
              Departamento
              <button onClick={() => onSort('unit')}>
                {getClassNamesFor('unit') === 'asc' ? <ArrowUp /> : <ArrowDown />}
              </button>
            </th>
            <th>
              Cargo e função
              <button onClick={() => onSort('role')}>
                {getClassNamesFor('role') === 'asc' ? <ArrowUp /> : <ArrowDown />}
              </button>
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((personData) => {
              return (
                <tr key={personData.id}>
                  <td>{personData?.email}</td>
                  <td>{personData.unit}</td>
                  <td>{personData.role}</td>
                  <td><p>Pendente</p></td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td />
              <td colSpan={8}>
                Você não possui colaboradores cadastrados em sua base - para adicionar clique em enviar convite por email.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

InviteEmployeesTable.propTypes = {
  data: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired
};

export default InviteEmployeesTable;
