import {
  deleteManagerRequestSucceeded,
  deleteManagerRequest,
  managersRequest,
  managersRequestFailed,
  managersRequestSucceeded,
  deleteManagerRequestFailed,
  updateManagerRequest,
  updateManagerRequestSucceeded,
  updateManagerRequestFailed
} from "../action-creators/managers";
import { apiDeleteManagerRequest, apiManagersRequest, apiUpdateManagerRequest } from "../api/managers";
import { getItemFromStorage } from "../../helper/storage";

const createManagersRequest = filters => dispatch => {
  dispatch( managersRequest() );
  const company_id = getItemFromStorage("COMPANY_ID")
  const token = getItemFromStorage("TOKEN");
  return apiManagersRequest(token, company_id, filters)
    .then( json => {
      dispatch( managersRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( managersRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const getManagers = filters => dispatch =>
  dispatch( createManagersRequest(filters) );

const createDeleteManagerRequest = id => dispatch => {
  dispatch( deleteManagerRequest());
  const token = getItemFromStorage("TOKEN");
  const companyId = getItemFromStorage("COMPANY_ID");
  return apiDeleteManagerRequest(token, companyId, id)
    .then( json => {
      dispatch( deleteManagerRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( deleteManagerRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const deleteManager = id => dispatch =>
  dispatch( createDeleteManagerRequest(id) );

const createUpdateManagerRequest = (id, payload) => (dispatch) => {
  dispatch(updateManagerRequest());
  const token = getItemFromStorage("TOKEN");
  const companyId = getItemFromStorage("COMPANY_ID");
  return apiUpdateManagerRequest(token, companyId, id, payload)
    .then((json) => {
      dispatch(updateManagerRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((response) => {
      dispatch(updateManagerRequestFailed(response));
      return Promise.reject(response);
    });
};

export const updateManager = (id, payload) => (dispatch) =>
  dispatch(createUpdateManagerRequest(id, payload));
