import React from "react";
import "./Card.scss";

const Card = ({children, container, className, title}) => {
  return (
    <div
      className={`Card ${container ? "container" : ""} ${
        className ? className : ""
      }`}
    >
      <div className="bg"/>
      <div className="content">
        {title && <p className="title">{title}</p>}
        {children}
      </div>
    </div>
  );
};

export default Card;
