import { getItemFromStorage } from "../../helper/storage";
import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow} from "./common";

export const apiTrendingRequest = () => {
  const token = getItemFromStorage("AUTH_TOKEN");
  const companyId = getItemFromStorage("COMPANY_ID");
  return authorizedFetch(`${baseUrl}/community/${companyId}/topics/trending`, {
    method: "GET",
    headers: buildHeaders(token)
  })
    .then(getResponseOrThrow)
    .catch(() => Promise.reject());
};
