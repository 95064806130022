import {
  GET_TASKS_EXECUTIONS_REQUEST,
  GET_TASKS_EXECUTIONS_REQUEST_FAILED,
  GET_TASKS_EXECUTIONS_REQUEST_SUCCEEDED,
  GET_TASKS_FUNCTIONALITY_REQUEST,
  GET_TASKS_FUNCTIONALITY_REQUEST_FAILED,
  GET_TASKS_FUNCTIONALITY_REQUEST_SUCCEEDED,
  TASKS_REQUEST,
  TASKS_REQUEST_FAILED,
  TASKS_REQUEST_SUCCEEDED,
  TASK_CREATE_REQUEST,
  TASK_CREATE_REQUEST_FAILED,
  TASK_CREATE_REQUEST_SUCCEEDED,
} from "../constants/tasks";

export const tasksRequest = () => {
  return {
    type: TASKS_REQUEST
  }
};

export const tasksRequestSucceeded = (payload) => {
  return {
    type: TASKS_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const tasksRequestFailed = (json) => {
  return {
    type: TASKS_REQUEST_FAILED,
    error: json
  }
};

export const tasksExecutionsRequest = () => {
  return {
    type: GET_TASKS_EXECUTIONS_REQUEST
  }
}

export const tasksExecutionsRequestSucceeded = (payload) => {
  return {
    type: GET_TASKS_EXECUTIONS_REQUEST_SUCCEEDED,
    data: payload
  }
}

export const tasksExecutionsRequestFailed = (json) => {
  return {
    type: GET_TASKS_EXECUTIONS_REQUEST_FAILED,
    error: json
  }
}

export const createTaskRequest = id => ({
  type: TASK_CREATE_REQUEST,
  data: id
});

export const createTaskRequestSucceeded = json => ({
  type: TASK_CREATE_REQUEST_SUCCEEDED,
  data: json
});

export const createTaskRequestFailed = json => ({
  type: TASK_CREATE_REQUEST_FAILED,
  error: json
});

export const tasksFunctionalityRequest = () => {
  return {
    type: GET_TASKS_FUNCTIONALITY_REQUEST
  }
}

export const tasksFunctionalityRequestSucceeded = (payload) => {
  return {
    type: GET_TASKS_FUNCTIONALITY_REQUEST_SUCCEEDED,
    data: payload
  }
}

export const tasksFunctionalityRequestFailed = (json) => {
  return {
    type: GET_TASKS_FUNCTIONALITY_REQUEST_FAILED,
    error: json
  }
}
