import {
  trendingRequest,
  trendingRequestFailed,
  trendingRequestSucceeded
} from "../action-creators/trending";
import { apiTrendingRequest } from "../api/trending";

const createTrendingRequest = () => dispatch => {
  dispatch(trendingRequest());
  return apiTrendingRequest()
    .then(json => {
      dispatch(trendingRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(trendingRequestFailed);
      return Promise.reject(response);
    });
};

export const getTrending = () => dispatch => dispatch(createTrendingRequest());
