import {
  EVALUATION_DAYLY_AVERAGE_REQUEST,
  EVALUATION_DAYLY_AVERAGE_REQUEST_FAILED,
  EVALUATION_DAYLY_AVERAGE_REQUEST_SUCCEEDED,
  EVALUATION_MONTHLY_AVERAGE_REQUEST,
  EVALUATION_MONTHLY_AVERAGE_REQUEST_SUCCEEDED,
  EVALUATION_MONTHLY_AVERAGE_REQUEST_FAILED
} from "../constants/evaluations";

export const evaluationDailyAverageRequest = () => {
  return {
    type: EVALUATION_DAYLY_AVERAGE_REQUEST
  }
};

export const evaluationDailyAverageRequestSucceeded = (json) => {
  return {
    type: EVALUATION_DAYLY_AVERAGE_REQUEST_SUCCEEDED,
    data: json
  }
};

export const evaluationDailyAverageRequestFailed = (error) => {
  return {
    type: EVALUATION_DAYLY_AVERAGE_REQUEST_FAILED,
    error: error
  }
};

export const evaluationMonthlyAverageRequest = () => {
  return {
    type: EVALUATION_MONTHLY_AVERAGE_REQUEST,
  }
}

export const evaluationMonthlyAverageRequestSucceeded = (json) => {
  return {
    type: EVALUATION_MONTHLY_AVERAGE_REQUEST_SUCCEEDED,
    data: json
  }
}

export const evaluationMonthlyAverageRequestFailed = (error) => {
  return {
    type: EVALUATION_MONTHLY_AVERAGE_REQUEST_FAILED,
    error: error
  }
}
