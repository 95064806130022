import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiEvaluationDailyAverage = (token, company_id) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/metrics/evaluations/average/day`,
    {
      method: "GET",
      headers: buildHeaders()
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json);
    })
    .catch((json) => {
      return Promise.reject(json);
    });
};

export  const apiEvaluationMonthlyAverage = (token, company_id) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/metrics/evaluations/average/month`,
    {
      method: "GET",
      headers: buildHeaders(token)
    }).then(getResponseOrThrow)
    .then((json) => Promise.resolve(json))
    .catch((error) => Promise.reject(error));
}
