import PropTypes from "prop-types";
import React from "react";
import "./Select.scss";

const Select = ({
  options,
  updateState,
  value,
  label,
  displayError,
  displayErrorMessage,
  valid,
  disabled,
  children,
  defaultOption,
}) => {
  const handleChange = e => {
    updateState(e.target.value);
  };

  return (
    <div className="Select">
      <p className={displayError && !valid ? "visible" : null}>
        {value === "" ? "Campo obrigatório" : displayErrorMessage}
      </p>
      <div>
        {label ? <label>{label}</label> : null}
        <select
          className={displayError ? "invalid" : disabled ? "disabled" : ""}
          onChange={handleChange}
          value={value}
          disabled={disabled}
        >
          {defaultOption && <option value=" ">{defaultOption}</option>}
          {options?.length > 0 && options.map((option, index) => <option key={index}
            value={option.value || option}>{option.label || option}</option>)}
          {children}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  displayError: PropTypes.bool.isRequired,
  displayErrorMessage: PropTypes.string,
  label: PropTypes.string,
  updateState: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

Select.defaultProps = {
  disabled: false,
  displayErrorMessage: "",
  label: "",
  valid: true
};

export default Select;
