import React, {createRef, useCallback, useEffect} from 'react';
import "./DragAndDropUpload.scss";

const DragAndDropUpload = ({children, setFile}) => {
  const ref = createRef();

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = useCallback((e) => {
    if (e.type === "drop") {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleDrop(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
    }

    if (e[0]) {
      setFile(e[0])
    }
  }, [setFile])

  useEffect(() => {
    let div = ref.current;
    div.addEventListener("dragenter", handleDragEnter);
    div.addEventListener("dragleave", handleDragLeave);
    div.addEventListener("dragover", handleDragOver);
    div.addEventListener("drop", handleDrop);
  }, [handleDrop, ref]);

  return (
    <div className="DragAndDropUpload" ref={ref}>
      {children}
    </div>
  );
};

export default DragAndDropUpload;
