export const TOPICS_PROGRESSION_MONTH_REQUEST = "TOPICS_PROGRESSION_MONTH_REQUEST";
export const TOPICS_PROGRESSION_MONTH_REQUEST_SUCCEEDED = "TOPICS_PROGRESSION_MONTH_REQUEST_SUCCEEDED";
export const TOPICS_PROGRESSION_MONTH_REQUEST_FAILED = "TOPICS_PROGRESSION_MONTH_REQUEST_FAILED";

export const TOPIC_EVOLUTION_REQUEST = "TOPIC_EVOLUTION_REQUEST";
export const TOPIC_EVOLUTION_REQUEST_SUCCEEDED = "TOPIC_EVOLUTION_REQUEST_SUCCEEDED";
export const TOPIC_EVOLUTION_REQUEST_FAILED = "TOPIC_EVOLUTION_REQUEST_FAILED";

export const TOPIC_EVOLUTION_FILTERED_REQUEST = "TOPIC_EVOLUTION_FILTERED_REQUEST";
export const TOPIC_EVOLUTION_FILTERED_REQUEST_SUCCEEDED = "TOPIC_EVOLUTION_FILTERED_REQUEST_SUCCEEDED";
export const TOPIC_EVOLUTION_FILTERED_REQUEST_FAILED = "TOPIC_EVOLUTION_FILTERED_REQUEST_FAILED";

export const GET_PERSON_TOPIC_SUMMARY_REQUEST = "GET_PERSON_TOPIC_SUMMARY_REQUEST";
export const GET_PERSON_TOPIC_SUMMARY_REQUEST_SUCCEEDED = "GET_PERSON_TOPIC_SUMMARY_REQUEST_SUCCEEDED";
export const GET_PERSON_TOPIC_SUMMARY_REQUEST_FAILED = "GET_PERSON_TOPIC_SUMMARY_REQUEST_FAILED";
