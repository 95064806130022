import {
  topicEvolutionFilteredRequest, topicEvolutionFilteredRequestFailed, topicEvolutionFilteredRequestSucceeded,
  topicEvolutionRequest,
  topicEvolutionRequestFailed,
  topicEvolutionRequestSucceeded,
  personTopicSummaryRequest,
  personTopicSummaryRequestFailed,
  personTopicSummaryRequestSucceeded,
  topicsProgressionByMonthRequest,
  topicsProgressionByMonthRequestFailed,
  topicsProgressionByMonthRequestSucceeded
} from "../action-creators/topics";
import { getItemFromStorage } from "../../helper/storage";
import { apiTopicEvolution, apiTopicEvolutionFilteredRequest, apiTopicsProgressionByMonthRequest, apiPersonTopicSummaryRequest } from "../api/topics";

const createTopicsProgressionByMonthRequest = () => dispatch => {
  dispatch(topicsProgressionByMonthRequest());
  const company_id = getItemFromStorage( "COMPANY_ID" );
  const token = getItemFromStorage( "TOKEN" );
  return apiTopicsProgressionByMonthRequest(token, company_id).then(json => {
    dispatch(topicsProgressionByMonthRequestSucceeded(json));
    return Promise.resolve();
  }).catch(response => {
    dispatch(topicsProgressionByMonthRequestFailed());
    return Promise.reject();
  })
};

export const getTopicsProgressionByMonth = () => dispatch => dispatch(createTopicsProgressionByMonthRequest());

const createAbilitiesRequest = topic_id => dispatch => {
  dispatch(topicEvolutionRequest());
  const company_id = getItemFromStorage( "COMPANY_ID" );
  const token = getItemFromStorage( "TOKEN" );

  return apiTopicEvolution(token, company_id, topic_id)
    .then(json => {
      dispatch(topicEvolutionRequestSucceeded(json));
      return Promise.resolve();
  }).catch(response => {
      dispatch(topicEvolutionRequestFailed(response));
      return Promise.reject();
    })
};
export const getTopicEvolution = topic_id => dispatch => dispatch(createAbilitiesRequest(topic_id));

const createAbilitiesFilteredRequest = (topic_id, filters) => dispatch => {
  dispatch(topicEvolutionFilteredRequest());
  const company_id = getItemFromStorage( "COMPANY_ID" );
  const token = getItemFromStorage( "TOKEN" );

  return apiTopicEvolutionFilteredRequest(token, company_id, topic_id, filters)
    .then(json => {
      dispatch(topicEvolutionFilteredRequestSucceeded(json));
      return Promise.resolve();
    }).catch(response => {
      dispatch(topicEvolutionFilteredRequestFailed(response));
      return Promise.reject();
    })
};
export const getTopicFilteredEvolution = (topic_id, filters) => dispatch => dispatch(createAbilitiesFilteredRequest(topic_id, filters));

const createPersonTopicSummaryRequest = (personId) => dispatch => {
  dispatch(personTopicSummaryRequest())
  const token = getItemFromStorage("TOKEN");
  return apiPersonTopicSummaryRequest(token, personId).then(json => {
    dispatch(personTopicSummaryRequestSucceeded(json));
    return Promise.resolve();
  }).catch((error) => {
    dispatch(personTopicSummaryRequestFailed(error))
    return Promise.reject();
  });
}
export const topicPersonSummary = (personId) => 
  dispatch => dispatch(createPersonTopicSummaryRequest(personId));
