import React, { useState } from "react";
import "./PositionsAndFunctions.scss";
import InformationCheckbox from "../InformationCheckbox/InformationCheckbox";
import Input from "../../global/form/Input/Input";
import Button from "../../global/form/Button/Button";

const PositionsAndFunctions = ({
  selectedRole,
  setSelectedRole,
  roles,
  addNewRole,
  hasSelectedUnit,
  showAddNewRole,
}) => {
  const [newRole, setNewRole] = useState("");

  const createRole = (e) => {
    e.stopPropagation();
    const createdRole = { text: newRole };
    addNewRole((prevState) => [...prevState, createdRole]);
    setSelectedRole(newRole);
    setNewRole("");
  };

  return (
    <div className="PositionsAndFunctions">
      <h2>Cargos e funções</h2>
      <div className="container-paf">
        <div className="wrapper-paf">
          {roles &&
            roles?.map((item, key) => (
              <InformationCheckbox
                item={item}
                key={key}
                updateState={setSelectedRole}
                currentValue={selectedRole}
              />
            ))}
        </div>
        {hasSelectedUnit && showAddNewRole && (
          <div className="create-role">
            <Input
              label="Criar cargo/função"
              updateState={setNewRole}
              value={newRole}
              placeholder="Digite o nome do cargo/função"
            />
            {newRole.length > 0 && <Button onClick={createRole}>Salvar</Button>}
          </div>
        )}
      </div>
    </div>
  );
};

export default PositionsAndFunctions;
