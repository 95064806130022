import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiTasksRequest = async (token, company_id, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/company/${company_id}/employee/tasks`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    return getResponseOrThrow(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};

export const apiTasksExecutionsRequest = (token, company_id, task_id, filters) => {
  return fetch(
    urlFilters(
      `${baseUrl}/company/${company_id}/employee/task/${task_id}/executions`,
      filters
    ),
    {
      method: 'GET',
      headers: buildHeaders(token)
    }
  ).then(getResponseOrThrow).catch((error) => Promise.reject(error));
}


export const apiCreateTaskRequest = (token, companyId, payload) => {
  return authorizedFetch(`${baseUrl}/company/${companyId}/task`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(() => Promise.reject());
};

export const apiTasksFunctionalityRequest = async (token, company_id, filter) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/company/${company_id}/tasks` , filter),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch {
    return await Promise.reject();
  }
};
