import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import "./EmployeeInformation.scss"
import Departments from "../Departments/Departments";
import PositionsAndFunctions from "../PositionsAndFunctions/PositionsAndFunctions";
import Button from "../../global/form/Button/Button";
import {getCompanyStructure} from "../../../redux/actions/structure";
import {getAccesses} from "../../../redux/actions/accesses";
import {getItemFromStorage} from "../../../helper/storage";
import {isSuperManager} from "../../../helper/company";

const EmployeeInformation = ({ initialDepartment, initialRole, handleSubmit }) => {
  const dispatch = useDispatch();
  const structure = useSelector((state) => state?.structure?.data);
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(initialDepartment);
  const [selectedRole, setSelectedRole] = useState(initialRole);
  const companyId = getItemFromStorage("COMPANY_ID");
  const accesses = useSelector(state => state.accesses?.data);
  const isRootManager = isSuperManager(accesses, companyId);

  useEffect(() => {
    dispatch(getCompanyStructure());
    dispatch(getAccesses());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUnit && Array.isArray(structure)) {
      setSelectedRole("");
      const roles = structure.map(
        (unit) => unit.name === selectedUnit ? unit.roles : null
      ).filter(Boolean).map(role => role.map(item => ({ text: item })))[0];
      setRoles(roles ?? []);
    }
  }, [selectedUnit, structure]);
  
  useEffect(() => {
    if (Array.isArray(structure)) {
      setUnits(structure?.map((unit) => ({text: unit.name, checkbox: true})));
    }
  }, [structure]);

  return (
    <div className="EmployeeInformation">
      <h1>Permissão de acesso</h1>
      <div className="checkbox-access">
        <Departments
          department={selectedUnit}
          setDepartment={setSelectedUnit}
          units={units}
          addNewUnit={setUnits}
          showAddNewUnit={isRootManager}
        />
        <PositionsAndFunctions
          selectedRole={selectedUnit ? selectedRole : initialRole}
          setSelectedRole={setSelectedRole}
          roles={roles}
          addNewRole={setRoles}
          hasSelectedUnit={Boolean(selectedUnit)}
          showAddNewRole={isRootManager}
        />
      </div>
      <Button onClick={() => handleSubmit({ selectedUnit, selectedRole })}>Salvar</Button>
    </div>
  );
};

export default EmployeeInformation;
