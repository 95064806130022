import React from "react";
import "./CompanyAbilitiesTable.scss";
import PropTypes from "prop-types";
import TopicSymbol from "../../global/TopicSymbol/TopicSymbol";
import Grade from "../../global/Grade/Grade";

const CompanyAbilitiesTable = ({ companyAbilities, selectedAbility, setSelectedAbility }) => {
  return (
    <table className="CompanyAbilitiesTable">
      <thead>
      <tr>
        <th>Código</th>
        <th>Investimento</th>
        <th>Comunidade</th>
      </tr>
      </thead>
      <tbody>
      {companyAbilities && companyAbilities.map((asset, key) => {
        return (
          <tr
            className={selectedAbility === asset.topic.id ? "active" : ""}
            key={key}
            onClick={() => {setSelectedAbility(asset.topic.id)}}
          >
            <td><TopicSymbol symbol={asset.topic.symbol}/></td>
            <td>{asset.topic.name}</td>
            <td>
            </td>
            <td>
              <Grade value={asset.evaluation} variation={asset.variation} />
            </td>
          </tr>
        );
      })}
      </tbody>
    </table>
  );
}

CompanyAbilitiesTable.propTypes = {
  companyAbilities: PropTypes.array.isRequired
};

export default CompanyAbilitiesTable;
