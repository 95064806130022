import React from "react";
import "./TabButton.scss";

const TabButton = ({children, active, onClick, className, amount}) => {
  return (
    <div className={`TabButton ${active ? "active" : ""} ${className}`}>
      <button
        onClick={onClick}
      >
        {children}
      </button>
      {amount !== undefined && (
        <div className="circle-number">
          <p>{amount}</p>
        </div>
      )}
    </div>
  );
};

export default TabButton;
