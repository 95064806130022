import React, {useEffect} from 'react';
import "./Contact.scss";
import {ReactComponent as WhatsappIcon} from "../../../assets/images/contact-whatsapp.svg";
import {ReactComponent as EmailIcon} from "../../../assets/images/contact-email.svg";
import {useHistory} from "react-router-dom";
import {isUserAuthenticated} from "../../../redux/actions/authentication";
import {useSelector} from "react-redux";

const Contact = () => {
  const history = useHistory();
  const demo = useSelector(state => state.demo);

  useEffect(() => {
    !isUserAuthenticated() && !demo.isDemoMode && history.push("/login");
  }, [history, demo.isDemoMode]);

  return (
    <div className="Contact">
      <section>
        <h2>Entre em contato com a representação de sua conta:</h2>
        <h3>Nicholas Zacarias</h3>
        <p>Operacional</p>
        <div>
          <div className="click-contact">
            <EmailIcon/>
            <a href="mailto:suporte@mastertech.com.br">suporte@mastertech.com.br</a>
          </div>
          <div className="click-contact">
            <WhatsappIcon className="whatsapp-icon"/>
            <a href="tel:+551199801-1661">+55 11 99801-1661</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
