export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCEEDED = "LOGIN_REQUEST_SUCCEEDED";
export const LOGIN_REQUEST_FAILED = "LOGIN_REQUEST_FAILED";

export const GOOGLE_LOGIN_SUCCEEDED = "GOOGLE_LOGIN_SUCCEEDED";
export const GOOGLE_LOGIN_FAILED = "GOOGLE_LOGIN_FAILED";
export const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";
export const LOGIN_WITH_GOOGLE_SUCCEEDED = "LOGIN_WITH_GOOGLE_SUCCEEDED";
export const LOGIN_WITH_GOOGLE_FAILED = "LOGIN_WITH_GOOGLE_FAILED";

export const MICROSOFT_LOGIN_SUCCEEDED = "MICROSOFT_LOGIN_SUCCEEDED";
export const MICROSOFT_LOGIN_FAILED = "MICROSOFT_LOGIN_FAILED";
export const LOGIN_WITH_MICROSOFT = "LOGIN_WITH_MICROSOFT";
export const LOGIN_WITH_MICROSOFT_SUCCEEDED = "LOGIN_WITH_MICROSOFT_SUCCEEDED";
export const LOGIN_WITH_MICROSOFT_FAILED = "LOGIN_WITH_MICROSOFT_FAILED";

export const LINKEDIN_OAUTH_SUCCEEDED = "LINKEDIN_OAUTH_SUCCEEDED";
export const LINKEDIN_DATA_REQUEST = "LINKEDIN_DATA_REQUEST";
export const LINKEDIN_DATA_REQUEST_SUCCEEDED = "LINKEDIN_DATA_REQUEST_SUCCEEDED";
export const LINKEDIN_DATA_REQUEST_FAILED = "LINKEDIN_DATA_REQUEST_FAILED";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

