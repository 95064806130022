import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiTopicsProgressionByMonthRequest = (token, company_id) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/metrics/topic/progression/month`,
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiTopicEvolution = (token, company_id, topic_id) => {
  return fetch(`${baseUrl}/company/${company_id}/metrics/topic/${topic_id}/progression`,
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiTopicEvolutionFilteredRequest = (token, company_id, topic_id, filters) => {
  return fetch(urlFilters(`${baseUrl}/company/${company_id}/metrics/topic/${topic_id}/progression`, filters),
    {
      method: "GET",
      headers: buildHeaders(token)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiPersonTopicSummaryRequest = (token, personId) => {
  return fetch(`${baseUrl}/person/${personId}/topics/summary`, {
    headers: buildHeaders(token)
  }).then(getResponseOrThrow).catch(error => Promise.reject(error));
}
