import {generateDefaultState, processGroupedFetchReducer} from "../../helper/reducers";

const reducerNames = {
  'GET_METRICS_REQUEST': 'metrics',
  'GET_COMMUNITY_METRICS_REQUEST': 'communityMetrics',
  'GET_COMMUNITY_CONTENT_TYPE_METRICS_REQUEST': 'communityContentTypeDistribution',
  'GET_COMMUNITY_TASKS_METRICS_REQUEST': 'communityTasksMetrics',
  'GET_PERSON_METRICS_EXECUTIONS_REQUEST': 'personExecutionMetrics',
  'GET_PERSON_METRICS_REQUEST': 'personMetrics'
}

export const metrics = (
  state = generateDefaultState(reducerNames),
  action
) => processGroupedFetchReducer(state, reducerNames, action);
