import React, {useEffect, useRef, useState} from "react";
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {getChartData} from "./chartDatahelper";
import { useSelector } from "react-redux";

const LineChart = ({data}) => {
  //datalabels is registered globally, so it's displayed in all charts, this line unregister it globally
  Chart.plugins.unregister(ChartDataLabels);
  const [chartStyles, setChartStyles] = useState(null);
  const [chartData, setChartData] = useState(null);
  const chartRef = useRef(null);
  let myChart;

  useEffect(() => {
    if (chartRef.current) {
      myChart = new Chart(chartRef.current, chartStyles);
    }
  }, [chartStyles]);

  useEffect(() => {
    setChartData(getChartData(data))
    myChart?.update();
  }, [data])

  useEffect(() => {
    if (chartData) {
      setChartStyles({
        type: "line",
        data: {
          labels: chartData.labels,
          datasets: [{
            label: "média",
            data: chartData.average,
            backgroundColor: "transparent",
            borderColor: "#FFFFFF",
            borderWidth: 2,
            hoverRadius: 2,
            lineTension: 0,
            pointBackgroundColor: "#16489F",
            pointBorderWidth: 3,
            pointHoverBorderWidth: 7,
            pointRadius: 2,
            radius: 5,
            spanGaps: true
          },
            {
              label: "maior nota",
              data: chartData.max,
              backgroundColor: "transparent",
              borderColor: "#6ab63f",
              borderDash: [6],
              borderWidth: 2,
              hoverRadius: 2,
              lineTension: 0,
              pointBackgroundColor: "#16489F",
              pointBorderWidth: 3,
              pointHoverBorderWidth: 7,
              pointRadius: 2,
              radius: 5,
              spanGaps: true
            },
            {
              label: "menor nota",
              data: chartData.min,
              backgroundColor: "transparent",
              borderColor: "#b23b08",
              borderDash: [6],
              borderWidth: 2,
              hoverRadius: 2,
              lineTension: 0,
              pointBackgroundColor: "#16489F",
              pointBorderWidth: 3,
              pointHoverBorderWidth: 7,
              pointRadius: 2,
              radius: 5,
              spanGaps: true
            }
          ]
        },
        options: {
          legend: {
            align: "left",
            display: false,
            labels: {
              fontColor: "#FFFFFF",
              fontFamily: "Roboto",
              fontSize: 14
            },
            position: "bottom"
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "transparent",
                  display: true,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  zeroLineColor: "#FFFFFF",
                },
                ticks: {
                  autoSkip: true,
                  maxRotation: 90,
                  minRotation: 0,
                  padding: 15,
                  fontColor: "#FFFFFF",
                  fontSize: 16,
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  color: "transparent",
                  display: true,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  zeroLineColor: "#FFFFFF",
                },
                ticks: {
                  fontColor: "transparent",
                  fontSize: 14,
                  padding: 5,
                  stepSize: 1,
                  suggestedMax: 11,
                  suggestedMin: 0,
                }
              }
            ]
          },
          maintainAspectRatio: false,
          responsive: true
        }
      });
    }
  }, [chartData]);

  return (
    <canvas ref={chartRef}/>
  );
};

export default LineChart;
