import {
  CREATE_INVESTMENT,
  CREATE_INVESTMENT_FAILED,
  CREATE_INVESTMENT_SUCCEEDED,
  INVESTMENT_DETAILS_INVESTORS,
  INVESTMENT_DETAILS_INVESTORS_FAILED,
  INVESTMENT_DETAILS_INVESTORS_SUCCEEDED,
  INVESTMENT_DETAILS_PROGRESSION,
  INVESTMENT_DETAILS_PROGRESSION_FAILED,
  INVESTMENT_DETAILS_PROGRESSION_SUCCEEDED,
  INVESTMENT_DETAILS_TASKS,
  INVESTMENT_DETAILS_TASKS_FAILED,
  INVESTMENT_DETAILS_TASKS_SUCCEEDED,
  LIST_INVESTMENTS,
  LIST_INVESTMENTS_FAILED,
  LIST_INVESTMENTS_SUCCEEDED,
  UPDATE_INVESTMENT,
  UPDATE_INVESTMENT_FAILED,
  UPDATE_INVESTMENT_SUCCEEDED
} from "../constants/investments";

export const createInvestmentRequest = () => {
  return {
    type: CREATE_INVESTMENT
  }
};

export const createInvestmentRequestSucceeded = (payload) => {
  return {
    type: CREATE_INVESTMENT_SUCCEEDED,
    data: payload
  }
};

export const createInvestmentRequestFailed = (json) => {
  return {
    type: CREATE_INVESTMENT_FAILED,
    error: json
  }
};

export const listInvestmentsRequest = () => {
  return {
    type: LIST_INVESTMENTS
  }
};

export const listInvestmentsRequestSucceeded = (payload) => {
  return {
    type: LIST_INVESTMENTS_SUCCEEDED,
    data: payload
  }
};

export const listInvestmentsRequestFailed = (json) => {
  return {
    type: LIST_INVESTMENTS_FAILED,
    error: json
  }
};

export const updateInvestmentRequest = () => {
  return {
    type: UPDATE_INVESTMENT
  }
};

export const updateInvestmentRequestSucceeded = (payload) => {
  return {
    type: UPDATE_INVESTMENT_SUCCEEDED,
    data: payload
  }
};

export const updateInvestmentRequestFailed = (json) => {
  return {
    type: UPDATE_INVESTMENT_FAILED,
    error: json
  }
};

export const investmentDetailsProgressionRequest = () => {
  return {
    type: INVESTMENT_DETAILS_PROGRESSION
  }
}

export const investmentDetailsProgressionRequestSucceeded = (payload) => {
  return {
    type: INVESTMENT_DETAILS_PROGRESSION_SUCCEEDED,
    data: payload
  }
}

export const investmentDetailsProgressionRequestFailed = (json) => {
  return {
    type: INVESTMENT_DETAILS_PROGRESSION_FAILED,
    error: json
  }
}

export const investmentDetailsInvestorRequest = () => {
  return {
    type: INVESTMENT_DETAILS_INVESTORS
  }
}

export const investmentDetailsInvestorRequestSucceeded = (payload) => {
  return {
    type: INVESTMENT_DETAILS_INVESTORS_SUCCEEDED,
    data: payload
  }
}

export const investmentDetailsInvestorRequestFailed = (json) => {
  return {
    type: INVESTMENT_DETAILS_INVESTORS_FAILED,
    error: json
  }
}

export const investmentDetailsTasksRequest = () => {
  return {
    type: INVESTMENT_DETAILS_TASKS
  }
}

export const investmentDetailsTasksRequestSucceeded = (payload) => {
  return {
    type: INVESTMENT_DETAILS_TASKS_SUCCEEDED,
    data: payload
  }
}

export const investmentDetailsTasksRequestFailed = (json) => {
  return {
    type: INVESTMENT_DETAILS_TASKS_FAILED,
    error: json
  }
}
