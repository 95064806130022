export const RECOVERY_PASSWORD_REQUEST = "RECOVERY_PASSWORD_REQUEST";
export const RECOVERY_PASSWORD_REQUEST_SUCCEEDED =
  "RECOVERY_PASSWORD_REQUEST_SUCCEEDED";
export const RECOVERY_PASSWORD_REQUEST_FAILED =
  "RECOVERY_PASSWORD_REQUEST_FAILED";

export const UPDATE_PASSWORD_WITH_CODE_REQUEST =
  "UPDATE_PASSWORD_WITH_CODE_REQUEST";
export const UPDATE_PASSWORD_WITH_CODE_REQUEST_SUCCEEDED =
  "UPDATE_PASSWORD_WITH_CODE_REQUEST_SUCCEEDED";
export const UPDATE_PASSWORD_WITH_CODE_REQUEST_FAILED =
  "UPDATE_PASSWORD_WITH_CODE_REQUEST_FAILED";
