import {ENGAGEMENT_REQUEST, ENGAGEMENT_REQUEST_FAILED, ENGAGEMENT_REQUEST_SUCCEEDED} from "../constants/engagement";

export const engagement = (state = {
  isFetching: false,
  data: {}
}, action) => {
  let newState = {...state};

  switch (action.type) {
    case ENGAGEMENT_REQUEST:
      newState.isFetching = true;
      return newState;

    case ENGAGEMENT_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      return newState;

    case ENGAGEMENT_REQUEST_FAILED:
      newState.isFetching = false;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
