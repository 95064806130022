import React from "react";
import "./EntryGenericPage.scss";
import Logo from "../../global/Logo/Logo";
import Card from "../../global/Card/Card";

const EntryGenericPage = ({children}) => {
  return (
    <section className="EntryGenericPage">
      <div className="e2w-principles">
        <Logo/>
        <h2>Conhecimento é riqueza. </h2>
        <p>
          É o único investimento que mesmo desvalorizando, você não perde.
          Construir esse patrimônio depende apenas de você.
        </p>
      </div>
      <Card>{children}</Card>
    </section>
  );
};

export default EntryGenericPage;
