import React from "react";
import PropTypes from "prop-types";
import "./SearchBar.scss";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchbar-input.svg";

const SearchBar = ({ updateState, value, placeholder }) => {
  const handleChange = event => {
    updateState(event.target.value);
  };

  return (
    <div className="SearchBar">
      <SearchIcon />
      <input
        type="text"
        value={value}
        onInput={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

SearchBar.propType = {
  placeholder: PropTypes.string,
  updateState: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

SearchBar.defaultProps = {
  placeholder: "Pesquisar"
};

export default SearchBar;
