import {
  SET_DEMO_DATA_REQUEST,
  SET_DEMO_DATA_REQUEST_SUCCEEDED,
  SET_DEMO_MODE
} from "../constants/demo";

export const demo = (
  state = {
    isDemoMode: false,
    isFetching: false,
    data: [],
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case SET_DEMO_MODE:
      newState.isDemoMode = true;
      return newState;

    case SET_DEMO_DATA_REQUEST:
      newState.isFetching = true;
      return newState;

    case SET_DEMO_DATA_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      return newState;

    default:
      return newState;
  }
};
